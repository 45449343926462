import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import Icon from "../../ui/Icon";
import classes from "./style/modal.module.scss";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { setPopupClose } from "../../actions/modalActions";

const Modal = () => {
  const modalData = useSelector((state) => state.modal)
  const dispatch = useDispatch()

  const popupTypeNoRender = useRef()

  const closePopupCallback = () => {
    if(!modalData.hideClose){
      dispatch(setPopupClose())
      if (modalData.props && modalData.props.onClose) modalData.props.onClose()
    }
  }

  let popupType = modalData.popupType || null
  popupTypeNoRender.current = popupType

  const CurrentPopup = useMemo(() => {
    if (!modalData.popupType || !modalData.isOpen) {
      return null
    }
    return React.lazy(() => import(`./components/${popupType}`).catch(() => null))
  }, [popupType])

  if (!CurrentPopup) {
    return null
  }

  return (
    <section className={classNames(classes['modal-popup'], classes[popupType], 'popup-modal-wrapper')}>
      <div className={classNames(classes['modal-overlay'])} onClick={closePopupCallback}></div>
      <div
        className={classNames(
          classes["modal"],
          modalData.props.className && classes[modalData.props.className]
        )}
      >
        {
          !modalData.hideClose && (
            <button className={classes["close-button"]} onClick={closePopupCallback}>
              <Icon id="icon-close" />
            </button>
          )
        }
        <Suspense fallback={<div className={classNames('loading gray', classes['popup-loading'])}></div>}>
          <CurrentPopup {...modalData.props} />
        </Suspense>
      </div>
    </section>
  )
};

export default Modal;
