import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Select from '../../../ui/Select';
import MultiSelect from '../../../ui/Select/MultiSelect';
import Input from '../../../ui/Input';
import Button from '../../../ui/Button';
import Textarea from '../../../ui/Textarea';

import classes from '../style/donorRegistrationPage.module.scss'

const levelOfEnglishOptions = [
  {value: "Beginner", name: "Beginner"},
  {value: "Intermediate", name: "Intermediate"},
  {value: "Advanced", name: "Advanced"},
  {value: "Native Speaker", name: "Native Speaker"},
]

const otherLangsOptions = [
  {
      value: "Bengali", name: "Bengali"
  },
  {
      value: "Bhojpuri", name: "Bhojpuri"
  },
  {
      value: "Burmese", name: "Burmese"
  },
  {
      value: "Egyptian Arabic", name: "Egyptian Arabic"
  },
  {
      value: "English", name: "English"
  },
  {
      value: "Filipino", name: "Filipino"
  },
  {
      value: "French", name: "French"
  },
  {
      value: "German", name: "German"
  },
  {
      value: "Gujarati", name: "Gujarati"
  },
  {
      value: "Hakka", name: "Hakka"
  },
  {
      value: "Hausa", name: "Hausa"
  },
  {
      value: "Hindi", name: "Hindi"
  },
  {
      value: "Indonesian", name: "Indonesian"
  },
  {
      value: "Italian", name: "Italian"
  },
  {
      value: "Japanese", name: "Japanese"
  },
  {
      value: "Javanese", name: "Javanese"
  },
  {
      value: "Kannada", name: "Kannada"
  },
  {
      value: "Korean", name: "Korean"
  },
  {
      value: "Maithili", name: "Maithili"
  },
  {
      value: "Malayalam", name: "Malayalam"
  },
  {
      value: "Mandarin Chinese", name: "Mandarin Chinese"
  },
  {
      value: "Marathi", name: "Marathi"
  },
  {
      value: "Odia", name: "Odia"
  },
  {
      value: "Oromo", name: "Oromo"
  },
  {
      value: "Pashto", name: "Pashto"
  },
  {
      value: "Persian", name: "Persian"
  },
  {
      value: "Polish", name: "Polish"
  },
  {
      value: "Portuguese", name: "Portuguese"
  },
  {
      value: "Sindhi", name: "Sindhi"
  },
  {
      value: "Southern Min", name: "Southern Min"
  },
  {
      value: "Spanish", name: "Spanish"
  },
  {
      value: "Standard Arabic", name: "Standard Arabic"
  },
  {
      value: "Standard German", name: "Standard German"
  },
  {
      value: "Sudanese Arabic", name: "Sudanese Arabic"
  },
  {
      value: "Swahili", name: "Swahili"
  },
  {
      value: "Tagalog", name: "Tagalog"
  },
  {
      value: "Tamil", name: "Tamil"
  },
  {
      value: "Telugu", name: "Telugu"
  },
  {
      value: "Thai", name: "Thai"
  },
  {
      value: "Turkish", name: "Turkish"
  },
  {
      value: "Ukrainian", name: "Ukrainian"
  },
  {
      value: "Urdu", name: "Urdu"
  },
  {
      value: "Uzbek", name: "Uzbek"
  },
  {
      value: "Vietnamese", name: "Vietnamese"
  },
  {
      value: "Western Punjabi", name: "Western Punjabi"
  },
  {
      value: "Wu Chinese", name: "Wu Chinese"
  },
  {
      value: "Xiang Chinese", name: "Xiang Chinese"
  },
  {
      value: "Yoruba", name: "Yoruba"
  },
  {
      value: "Yue Chinese", name: "Yue Chinese"
  }
]

const moviesOptions = [
  { value: 'Action', name: 'Action' },
  { value: 'Comedy', name: 'Comedy' },
  { value: 'Drama', name: 'Drama' },
  { value: 'thriller', name: 'Thriller' },
  { value: 'Thriller', name: 'Horror' },
  { value: 'Romance', name: 'Romance' },
  { value: 'Science Fiction', name: 'Science Fiction' },
  { value: 'Animation', name: 'Animation' },
  { value: 'Documentary', name: 'Documentary' },
  { value: 'Other', name: 'Other' }
]

const musicOptions = [
  { value: 'Rock', name: 'Rock' },
  { value: 'Pop', name: 'Pop' },
  { value: 'Hip Hop', name: 'Hip Hop' },
  { value: 'Electronic', name: 'Electronic' },
  { value: 'Jazz', name: 'Jazz' },
  { value: 'Classical', name: 'Classical' },
  { value: 'Country', name: 'Country' },
  { value: 'R&B', name: 'R&B' },
  { value: 'Metal', name: 'Metal' },
  { value: 'Indie', name: 'Indie' },
  { value: 'Folk', name: 'Folk' },
  { value: 'Other', name: 'Other' }
]

const sportOptions = [
  { value: 'Football', name: 'Football' },
  { value: 'Basketball', name: 'Basketball' },
  { value: 'Tennis', name: 'Tennis' },
  { value: 'Swimming', name: 'Swimming' },
  { value: 'Running', name: 'Running' },
  { value: 'Cycling', name: 'Cycling' },
  { value: 'Gymnastics', name: 'Gymnastics' },
  { value: 'Yoga', name: 'Yoga' },
  { value: 'Martial Arts', name: 'Martial Arts' },
  { value: 'Other', name: 'Other' }
];

const flowerOptions = [
  { value: 'Rose', name: 'Rose' },
  { value: 'Tulip', name: 'Tulip' },
  { value: 'Sunflower', name: 'Sunflower' },
  { value: 'Lily', name: 'Lily' },
  { value: 'Daisy', name: 'Daisy' },
  { value: 'Orchid', name: 'Orchid' },
  { value: 'Carnation', name: 'Carnation' },
  { value: 'Daffodil', name: 'Daffodil' },
  { value: 'Other', name: 'Other' }
];

const seasonOptions = [
  { value: 'Spring', name: 'Spring' },
  { value: 'Summer', name: 'Summer' },
  { value: 'Autumn', name: 'Autumn' },
  { value: 'Winter', name: 'Winter' }
];

const foodOptions = [
  { value: 'Italian', name: 'Italian' },
  { value: 'Chinese', name: 'Chinese' },
  { value: 'Mexican', name: 'Mexican' },
  { value: 'Indian', name: 'Indian' },
  { value: 'Japanese', name: 'Japanese' },
  { value: 'Thai', name: 'Thai' },
  { value: 'American', name: 'American' },
  { value: 'French', name: 'French' },
  { value: 'Mediterranean', name: 'Mediterranean' }
];

export default function ThirdStep({ formData, updateFormData, handlePrev, handleNext }) {
  const schema = yup.object().shape({
    aboutLetter: yup.string().required('About Letter is required'),
    levelOfEnglish: yup.string().required('Level of English is required'),
    otherLanguages: yup.string().required('Other Languages are required'),
    additionalInfo: yup.object().shape({
      music: yup.string(),
      movies: yup.string(),
      sport: yup.string(),
      flower: yup.string(),
      freeTime: yup.string(),
      likeToVisit: yup.string(),
      season: yup.string(),
      food: yup.string(),
      dislike: yup.string(),
    }),
  });

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    updateFormData(data);
  };

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Textarea 
          name="aboutLetter" 
          id="aboutLetter" 
          staticPlaceHolder="About You" 
          placeHolder='About You' 
          cols="30" 
          rows="10" 
          withError={errors.aboutLetter} 
          useFormEl={register('aboutLetter')} 
          errorText="Please, enter information About You"
          />
        <Select
          values={levelOfEnglishOptions}
          name="levelOfEnglish"
          useFormEl={register('levelOfEnglish')}
          placeholderInline="Select Level of English"
          withError={errors.levelOfEnglish}
          errorText="Please, select Level of English"
          placeholder="Level of English"
        />
        <MultiSelect
          values={otherLangsOptions}
          name="otherLanguages"
          useFormEl={register('otherLanguages')}
          placeholderInline="Other Languages"
          withError={errors.otherLanguages}
          errorText="Please, select Other Languages"
          placeholder="Select Other Languages"
          defaultValue={''}
          useFormSetValue={setValue}
        />
        <MultiSelect
          values={musicOptions}
          name="additionalInfo.music"
          useFormEl={register('additionalInfo.music')}
          placeholderInline="Select Music"
          withError={errors.additionalInfo?.music}
          errorText="Please, select Music"
          placeholder="Music"
          defaultValue={''}
          useFormSetValue={setValue}
        />
        <MultiSelect
          values={moviesOptions}
          name="additionalInfo.movies"
          useFormEl={register('additionalInfo.movies')}
          placeholderInline="Select Movies"
          withError={errors.additionalInfo?.movies}
          errorText="Please, select Movies"
          placeholder="Movies"
          defaultValue={''}
          useFormSetValue={setValue}
        />
        <MultiSelect
          values={sportOptions}
          name="additionalInfo.sport"
          useFormEl={register('additionalInfo.sport')}
          placeholderInline="Select Sport"
          withError={errors.additionalInfo?.sport}
          errorText="Please, select Sport"
          placeholder="Sport"
          defaultValue={''}
          useFormSetValue={setValue}
        />
        <MultiSelect
          values={flowerOptions}
          name="additionalInfo.flower"
          useFormEl={register('additionalInfo.flower')}
          placeholderInline="Select Flower"
          withError={errors.additionalInfo?.flower}
          errorText="Please, select Flower"
          placeholder="Flower"
          defaultValue={''}
          useFormSetValue={setValue}
        />
        <Input
          type="text"
          name="additionalInfo.freeTime"
          useFormEl={register('additionalInfo.freeTime')}
          placeholderNormal="Free Time"
          placeHolder="Free Time"
        />
        <Input
          type="text"
          name="additionalInfo.likeToVisit"
          useFormEl={register('additionalInfo.likeToVisit')}
          placeholderNormal="Like to Visit"
          placeHolder="Like to Visit"
        />
        <Select
          values={seasonOptions}
          name="additionalInfo.season"
          useFormEl={register('additionalInfo.season')}
          placeholderInline="Select Favorite season"
          withError={errors.additionalInfo?.season}
          errorText="Please, select Favorite season"
          placeholder="Favorite season"
        />
        <MultiSelect
          values={foodOptions}
          name="additionalInfo.food"
          useFormEl={register('additionalInfo.food')}
          placeholderInline="Select Favorite food"
          withError={errors.additionalInfo?.food}
          errorText="Please, select Favorite food"
          placeholder="Favorite food"
          defaultValue={''}
          useFormSetValue={setValue}
        />
        <Input
          type="text"
          name="additionalInfo.dislike"
          useFormEl={register('additionalInfo.dislike')}
          placeholderNormal="I dislike"
          placeHolder="I dislike"
        />
        <div className={classes['buttons-block']}>
          <Button onClick={() => handlePrev('/full-registration/step2')} addClasses={["outline-rounded"]} >
            Back
          </Button>
          <Button type="submit" addClasses={["button"]} onClick={handleSubmit(onSubmit)}>
            Continue
          </Button>
        </div>
      </form>
  );
}
