import React, { useEffect, useState } from 'react'

import classes from "../style/videoChatRoom.module.scss";
import { useSelector } from 'react-redux';

export default function VideochatTime({handleTimeOff}) {
    const availableSeconds = useSelector((state) => state.videoChat.availableSeconds);
    const [timeLeft, setTimeLeft] = useState();

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        if (hours === 0) {
            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        } else {
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
    };
    useEffect(() => {
        if(availableSeconds) setTimeLeft(availableSeconds)
        const countdown = () => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 0) {
                    return 0;
                }
                return prevTime - 1;
            });
        };
        
        const intervalId = setInterval(countdown, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [availableSeconds]);

    useEffect(() => {
        if (timeLeft === 0) {
            handleTimeOff();
        }
    }, [timeLeft]);

    return (
        <div className={classes["left-chat-time"]}>
            Time left {formatTime(timeLeft)}
        </div>
    )
}
