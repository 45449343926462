import React from 'react';
import PageWrap from '../../../../app/components/PageWrap';

import classes from '../../style/staticPages.module.scss'

const PrivacyPolicyPage = () => {
    return (
        <PageWrap>
            <div className='container'>
                <section className={classes['static-pages-contaniner']}>
                    <h1>Privacy Policy</h1>
                    <p>The website https://surrogacy-platform.com is owned by the Surrogacy Platform, which is the controller for your personal data.</p>
                    <p>We use this Privacy Policy, which sets out how we treat information collected by surrogacy-platform.com and the reasons why we collect certain personal information about you. Therefore, you should read this Privacy Policy before using surrogacy-platform.com.</p>
                    <p>We care about your personal data and guarantee its confidentiality and security.</p>
                    <h2>Personal information we collect:</h2>
                    <p>When you visit surrogacy-platform.com, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, etc. and some of the cookies installed on your device. In addition, as you browse the Site, we collect information about the individual pages or products you view, what sites or search terms referred you to the Site, and how you interact with the Site. We refer to this automatically collected information as “Device Information.” In addition, we may collect personal information that you provide to us (including first name, last name, address, payment information, etc.) during registration in order to be able to fulfill the agreement.</p>
                    <h2>Why do we process your data?</h2>
                    <p>Our top priority is customer safety, which is why we must process the minimum amount of user data: only as much as is necessary to maintain the site. Information collected automatically is used only to identify potential violations and obtain statistical information about use of the Site. This statistical information is not collected in a manner that could identify a specific user of the system.</p>
                    <p>You can visit the Site without telling us who you are or revealing any information by which anyone could identify you as a specific individual. However, if you want to use some functions of the site, want to receive our newsletter or other information by filling out the form, you can provide us with personal information, such as your email address, first name, last name, place of residence, organization, telephone. You may choose not to provide us with your personal information, but if you do, you may not be able to take advantage of certain features of the Site. For example, you may not be able to receive our newsletter or contact us directly through the Site. Users who are unsure what information is required can contact us through <a href='mailto: surrogacyplatformcom@gmail.com'>surrogacyplatformcom@gmail.com</a>.</p>
                    <p>Surrogacy Platform surrogacy-platform.com follows a standard procedure of using log files. These files record visitors when they visit websites. All hosting companies do this and are part of hosting services' analytics. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. This data is not linked to any personally identifiable information. This information is used to analyze trends, administer the site, track users' movement around the site, and gather demographic information.</p>
                    <h2>Cookies and Web Beacons</h2>
                    <p>Like any other website, Surrogacy Platform surrogacy-platform.com uses " cookies ". These cookies are used to store information including visitors' preferences, and the pages on the site that the visitor accessed or visited. This information is used to optimize the users' experience by tailoring the content of our web pages based on visitors' browser type and/or other information.</p>
                    <h2>Privacy Policy</h2>
                    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Surrogacy Platform surrogacy-platform.com.</p>
                    <p>Third-party ad servers or ad networks use technologies like cookies , JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on the Surrogacy Platform surrogacy-platform.com, which are sent directly to users' browser. In doing so, they automatically receive your IP address. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content you see on sites you visit.</p>
                    <p>Please note that Surrogacy Platform surrogacy-platform.com has no access to or control over these cookies used by third party advertisers.</p>
                    <h2>Third Party Privacy Policy</h2>
                    <p>Surrogacy Platform surrogacy-platform.com Privacy Policy does not apply to other advertisers or websites. Therefore, we advise you to review the privacy policies of these third-party ad servers for more detailed information. It may contain information about their practices and instructions on how to opt-out of certain options.</p>
                    <p>You can disable cookies in your browser settings. More detailed information about managing cookies with specific web browsers can be found at the browsers' respective websites. What are cookies ?</p>
                    <h2>Information for children</h2>
                    <p>Another priority is to ensure that children are protected when using the Internet. We encourage parents and guardians to observe, participate in their children's lives and/or monitor and guide their online activities.</p>
                    <p>Surrogacy Platform surrogacy-platform.com does not knowingly collect personally identifiable information from children under 18 years of age. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
                    <h2>Online privacy policy only</h2>
                    <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Surrogacy Platform surrogacy-platform.com. This policy is not applicable to any information collected offline or through channels other than this website.</p>
                    <h2>Your rights:</h2>
                    <p>Access right.</p>
                    <h2>Right to information.</h2>
                    <p>Right to Correction.</p>
                    <p>Right to data destruction.</p>
                    <p>The right to restrict processing.</p>
                    <p>Right to data portability.</p>
                    <p>Right to restoration.</p>
                    <p>Rights regarding automated decision making and profiling.</p>
                    <p>If you would like to exercise this right, please contact us using the contact information below.</p>
                    <p>In addition, if you are a European resident, we note that we process your information to fulfill contracts we may have with you (for example, if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above . Additionally, please note that your information may be transferred outside of Europe, including Canada and the United States.</p>
                    <h2>Information Security:</h2>
                    <p>We secure the information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. We take reasonable administrative, technical and physical security measures to protect against unauthorized access, use, modification and disclosure of personal information under its control and custody. However, data transmission over the Internet or wireless network cannot be guaranteed.</p>
                    <h2>Legal Disclosure:</h2>
                    <p>We will disclose any information we collect, use or receive if required or permitted by law, such as in response to a subpoena or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights , your safety or the safety of others, investigating fraud, or responding to a government request.</p>
                    <h2>Contact Information:</h2>
                    <p>If you would like to contact us to learn more about this Policy or would like to contact us regarding any matter regarding individual rights and your Personal Information, you may send an email to <a href='mailto: surrogacyplatformcom@gmail.com'>surrogacyplatformcom@gmail.com</a>.</p>
                    <h2>Agreement</h2>
                    <p>By using our site, you hereby agree to our Privacy Policy and accept our Terms and Conditions.</p>
                </section>
            </div>
        </PageWrap>
    );
}

export default PrivacyPolicyPage;
