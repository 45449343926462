import React from 'react';
import { useContext } from 'react';
import PageWrap from '../../app/components/PageWrap';
import FaqComponent from '../../components/Faq/FaqComponent';
import Ladies from './components/Ladies';
import { ReactReduxContext } from 'react-redux'

const DonorsPage = () => {

    return (
        <PageWrap>
            <Ladies />
            <FaqComponent />
        </PageWrap>
    );
}

export default DonorsPage;
