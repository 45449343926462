import React from 'react';
import PageWrap from '../../../../app/components/PageWrap';

import classes from '../../style/staticPages.module.scss'

const SurrogateTripsPage = () => {
    return (
        <PageWrap>
            <div className='container'>
                <section className={classes['static-pages-contaniner']}>
                    <h1>Journey of a Surrogate Mother to the Clinic</h1>
                    <h2>How the Process Works</h2>
                    <p>We strive to make the surrogacy process as comfortable and safe as possible for all participants. Here’s how the surrogate mother’s journey to the clinic unfolds and what procedures she can expect.</p>

                    <h2>Step 1: Preparing for the Trip</h2>
                    <p>Our specialists work closely with the surrogate mother to coordinate all travel and procedure details with the egg donor. We arrange ticket purchases, transportation, and accommodations so the surrogate mother doesn’t have to worry about logistics.</p>

                    <h2>Step 2: Arrival at the Clinic</h2>
                    <p>Upon arrival at the clinic, the surrogate mother is greeted by our coordinator, who will accompany her throughout the process. On the first day, the surrogate mother undergoes:</p>

                    <h3>Consultation with a Doctor</h3>
                    <p>The doctor will conduct a thorough medical examination and discuss all the details of the upcoming procedure.</p>

                    <h3>Tests and Examinations</h3>
                    <p>Necessary blood tests and an ultrasound will be performed to confirm the surrogate mother’s health.</p>

                    <h3>Consultation with a Psychologist</h3>
                    <p>We offer a consultation with a psychologist to ensure the surrogate mother feels confident and at ease.</p>

                    <h2>Step 3: Preparing for the Procedure</h2>
                    <p>The surrogate mother begins preparation for the embryo transfer procedure. The doctor prescribes the necessary medications and conducts regular check-ups to monitor the surrogate mother’s health.</p>

                    <h2>Step 4: Embryo Transfer</h2>
                    <p>When the donor’s eggs are fertilized and the embryos are ready for transfer, a date for the procedure is set. We take care of the surrogate mother at every step:</p>

                    <h3>Anesthesia (if necessary)</h3>
                    <p>The embryo transfer can be performed without anesthesia, but mild anesthesia may be used if necessary for comfort.</p>

                    <h3>Embryo Transfer Procedure</h3>
                    <p>The procedure takes about 10-15 minutes and is performed in sterile conditions under the supervision of experienced specialists.</p>

                    <h2>Step 5: Recovery and Return Home</h2>
                    <p>After the procedure, the surrogate mother rests and recovers in comfortable conditions. Our specialists provide the necessary medical care and support until complete recovery. At 7 weeks, we confirm pregnancy and only then organize a transfer home. If pregnancy is not confirmed, we continue the procedures depending on the service package you have chosen. Then we organize a return trip home so that the surrogate mother can return without worries and troubles.</p>

                    <h2>Support at Every Stage</h2>
                    <p>We understand the importance of the surrogate mother’s role and provide comprehensive support at every stage of the process. Our team is always ready to answer questions and provide the necessary assistance to ensure the surrogate mother feels confident and comfortable.</p>


                </section>
            </div>
        </PageWrap>
    );
}

export default SurrogateTripsPage;
