import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Select from '../../../ui/Select';
import Input from '../../../ui/Input';
import Button from '../../../ui/Button';

import classes from '../style/donorRegistrationPage.module.scss'

const schema = yup.object().shape({
    eyeColor: yup.string().required('Eye Color is required'),
    hairColor: yup.string().required('Hair Color is required'),
    height: yup.string().required('Height is required'),
    weight: yup.string().required('Weight is required'),
    bodyType: yup.string().required('Body Type is required'),
    drinking: yup.string().required('Drinking status is required'),
    smoking: yup.string().required('Smoking status is required'),
    rhFactors: yup.string().required('RH Factors are required'),
    bloodTypes: yup.string().required('Blood Types are required'),
    ethnicity: yup.string().required('Ethnicity is required')
});

const ethnicityOptions = [
    { name: 'Asian', value: 'asian' },
    { name: 'Caucasian', value: 'caucasian' },
    { name: 'East Indian', value: 'east_indian' },
    { name: 'European', value: 'european' },
    { name: 'Slavic', value: 'slavic' },
];

const eyeColorOptions = [
    { name: 'Black', value: 'Black' },
    { name: 'Brown', value: 'Brown' },
    { name: 'Grey', value: 'Grey' },
    { name: 'Dark green', value: 'Dark green' },
    { name: 'Green', value: 'Green' },
    { name: 'Blue', value: 'Blue' },
];

const hairColorOptions = [
    { name: 'Black', value: 'Black' },
    { name: 'Brown', value: 'Brown' },
    { name: 'Dark blonde', value: 'Dark blonde' },
    { name: 'Blonde', value: 'Blonde' },
    { name: 'Red', value: 'Red' },
    // { name: 'Darkred', value: 'Darkred' },
];

const bloodTypeOptions = [
    { value: 'blood_a', name: 'A' },
    { value: 'blood_b', name: 'B' },
    { value: 'blood_ab', name: 'AB' },
    { value: 'blood_0', name: '0' },
];

const rhFactorOptions = [
    { value: 'rh-', name: 'Rh-' },
    {  value: 'rh+', name: 'Rh+' },
];

const bodyTypeOptions = [
    { name: 'Slim (slender)', value: 'Slim'},
    { name: 'Average', value: 'Average'},
    { name: 'Petite', value: 'Petite'},
    { name: 'Curvy', value: 'Curvy'}
]

const drinkingOptions = [
    { name: "Don't drink", value: "Don't drink"},
    { name: 'Drink socially', value: 'Drink socially'},
    { name: 'Drink all the time', value: 'Drink all the time'}
]

const smokingOptions = [
    { name: "Don't smoke", value: "Don't smoke"},
    { name: 'Smoke', value: 'Smoke'},
]

export default function SecondStep({ formData, updateFormData, handleNext, handlePrev }) {
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: formData,
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        updateFormData(data);
        handleNext('/full-registration/step3');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Select
                values={eyeColorOptions}
                name="eyeColor"
                useFormEl={register('eyeColor')}
                placeholderInline="Select Eye Color"
                withError={errors.eyeColor}
                errorText="Please, select Eye Color"
                placeholder="Eye Color"
            />
            <Select
                values={hairColorOptions}
                name="hairColor"
                useFormEl={register('hairColor')}
                placeholderInline="Select Hair Color"
                withError={errors.hairColor}
                errorText="Please, select Hair Color"
                placeholder="Hair Color"
            />
            <Input
                type="number"
                name="height"
                useFormEl={register('height')}
                placeholderNormal="Height"
                withError={errors.height && true}
                errorText={errors?.height?.type == 'height' ? 'Invalid Height format' : 'Please, enter your Height'}
                placeHolder="Height"
                addProps={{
                    min: 0
                }}
            />
            <Input
                type="number"
                name="weight"
                useFormEl={register('weight')}
                placeholderNormal="Weight"
                withError={errors.weight && true}
                errorText={errors?.weight?.type == 'weight' ? 'Invalid Weight format' : 'Please, enter your Weight'}
                placeHolder="Weight"
                addProps={{
                    min: 0
                }}
            />
            <Select
                values={bodyTypeOptions}
                name="bodyType"
                useFormEl={register('bodyType')}
                placeholderInline="Select Body Type"
                withError={errors.bodyType}
                errorText="Please, select Body Type"
                placeholder="Body Type"
            />
            <Select
                values={drinkingOptions}
                name="drinking"
                useFormEl={register('drinking')}
                placeholderInline="Select Drinking"
                withError={errors.drinking}
                errorText="Please, select Drinking"
                placeholder="Drinking"
            />
            <Select
                values={smokingOptions}
                name="smoking"
                useFormEl={register('smoking')}
                placeholderInline="Select Smoking"
                withError={errors.smoking}
                errorText="Please, select Smoking"
                placeholder="Smoking"
            />
            <Select
                values={rhFactorOptions}
                name="rhFactors"
                useFormEl={register('rhFactors')}
                placeholderInline="Select RH Factors"
                withError={errors.rhFactors}
                errorText="Please, select RH Factors"
                placeholder="RH Factors"
            />
            <Select
                values={bloodTypeOptions}
                name="bloodTypes"
                useFormEl={register('bloodTypes')}
                placeholderInline="Select Blood Types"
                withError={errors.bloodTypes}
                errorText="Please, select Blood Types"
                placeholder="Blood Types"
            />
            <Select
                values={ethnicityOptions}
                name="ethnicity"
                useFormEl={register('ethnicity')}
                placeholderInline="Select Ethnicity"
                withError={errors.ethnicity}
                errorText="Please, select Ethnicity"
                placeholder="Ethnicity"
            />
            <div className={classes['buttons-block']}>
                <Button onClick={() => handlePrev('/full-registration/step1')} addClasses={["outline-rounded"]} >
                    Back
                </Button>
                <Button type="submit" addClasses={["button"]} onClick={handleSubmit(onSubmit)}>
                    next step
                </Button>
            </div>
        </form>
    );
}
