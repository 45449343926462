import React from 'react';

import classesMain from '../style/aboutUsMainStyles.module.scss'
import classes from '../style/joinUsSectionStyle.module.scss'
import classNames from 'classnames';
import ButtonLink from '../../../../../ui/Button/LinkButton';
import { useSelector } from 'react-redux';

const JoinUsSection = () => {
    const user = useSelector((state) => state.user.user);
    return (
        <section className={classes["join-us-section"]}>
            <div className={classNames(classesMain['about-us-container'], 'container')}>
                {
                    user && user.isLoggedIn ? (
                        <div className={classes['join-us-block']}>
                            <h2>Welcome back to Surrogacy Platform!</h2>
                            <p>Ready to take the next step in your journey towards creating the family you've always dreamed of?</p>
                            <ButtonLink to="/donors">Choose donor</ButtonLink>
                        </div>
                    ) : (
                        <div className={classes['join-us-block']}>
                            <h2>Join Surrogacy Platform</h2>
                            <p>Your first step towards creating the family you've always dreamed of!</p>
                            <ButtonLink to="/login">Sign Up</ButtonLink>
                        </div>
                    )
                }
            </div>
        </section>
    );
}

export default JoinUsSection;
