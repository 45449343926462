import BlogPage from "../pages/BlogPage";
import ClientProfilePage from "../pages/ClientProfilePage";
import ComparedDonorsPage from "../pages/ComparedDonorsPage";
import DonorProgramsPage from "../pages/DonorProgramsPage";
import DonorRegistrationPage from "../pages/DonorRegistrationPage";
import DonorRequestPage from "../pages/DonorRequestPage";
import DonorsGalery from "../pages/DonorsGalery";
import LikedDonorsPage from "../pages/LikedDonorsPage";
import Mailbox from "../pages/Mailbox";
import MainPage from "../pages/MainPage";
import NotFoundPage from "../pages/NotFoundPage";
import PackagesPage from "../pages/PackagesPage";
import Register from "../pages/Register";
import SavedDonorsPage from "../pages/SavedDonorsPage";
import SettingsPage from "../pages/SettingsPage";
import Post1 from "../pages/SingleBlogPage/components/Post-1";
import Post2 from "../pages/SingleBlogPage/components/Post-2";
import Post3 from "../pages/SingleBlogPage/components/Post-3";
import SingleDonorPage from "../pages/SingleDonorPage";
import SingleProgramPage from "../pages/SingleProgramPage";
import AboutUsPage from "../pages/StaticPages/components/AboutUsPage";
import ContactUsPage from "../pages/StaticPages/components/ContactUsPage";
import EggDonorsTripsPage from "../pages/StaticPages/components/EggDonorsTripsPage";
import LegislationPage from "../pages/StaticPages/components/LegislationPage/LegislationPage";
import PrivacyPolicyPage from "../pages/StaticPages/components/PrivacyPolicyPage";
import ProgramStagesPage from "../pages/StaticPages/components/ProgramStagesPage";
import QuickStart from "../pages/StaticPages/components/QuickStart";
import SurrogateTripsPage from "../pages/StaticPages/components/SurrogateTripsPage";
import TermsAndConditions from "../pages/StaticPages/components/TermsAndConditions";
import TripToChildbirthPage from "../pages/StaticPages/components/TripToChildbirthPage/TripToChildbirthPage";
import TripsToClinicPage from "../pages/StaticPages/components/TripsToClinicPage";
import Textchat from "../pages/Textchat";
import VideoChat from "../pages/VideoChat";

export const routes = [
    {
        path: '/',
        component: MainPage,
        seo: {
            title: 'Home',
            description: 'Welcome to the main page.',
        },
    },
    {
        path: '/login',
        component: Register,
        seo: {
            title: 'Login',
            description: 'Log in to your account.',
        },
    },
    {
        path: '/videochat',
        component: VideoChat,
        seo: {
            title: 'Video Chat',
            description: 'Start a video chat.',
        },
    },
    {
        path: '/videochat/:chatId',
        component: VideoChat,
        seo: {
            title: 'Video Chat Room',
            description: 'Join a specific video chat room.',
        },
    },
    {
        path: '/videochat/:chatId/:roomId',
        component: VideoChat,
        seo: {
            title: 'Video Chat Room',
            description: 'Join a video chat room with a specific ID.',
        },
    },
    {
        path: '/donors',
        component: DonorsGalery,
        seo: {
            title: 'Donor Gallery',
            description: 'Browse the donor gallery.',
        },
    },
    {
        path: '/donors/:donorsType',
        component: DonorsGalery,
        seo: {
            title: 'Filtered Donors',
            description: 'Browse filtered donors by type.',
        },
    },
    {
        path: '/donors/page/:pageNumber',
        component: DonorsGalery,
        seo: {
            title: 'Donor Gallery Page',
            description: 'Browse the donor gallery page.',
        },
    },
    {
        path: '/donor/:donorParams',
        component: SingleDonorPage,
        seo: {
            title: 'Donor Details',
            description: 'View details of a specific donor.',
        },
    },
    {
        path: '/textchat',
        component: Textchat,
        seo: {
            title: 'Text Chat',
            description: 'Join a text chat.',
        },
    },
    {
        path: '/textchat/:chatId',
        component: Textchat,
        seo: {
            title: 'Text Chat Room',
            description: 'Join a specific text chat room.',
        },
    },
    {
        path: '/mailbox',
        component: Mailbox,
        seo: {
            title: 'Mailbox',
            description: 'Access your mailbox.',
        },
    },
    {
        path: '/mailbox/:box',
        component: Mailbox,
        seo: {
            title: 'Mailbox Folder',
            description: 'View your mailbox folder.',
        },
    },
    {
        path: '/mailbox/readletter/:mailId',
        component: Mailbox,
        seo: {
            title: 'Read Mail',
            description: 'Read a specific email.',
        },
    },
    {
        path: '/saved-donors',
        component: SavedDonorsPage,
        seo: {
            title: 'Saved Donors',
            description: 'View your saved donors.',
        },
    },
    {
        path: '/favourite-donors',
        component: LikedDonorsPage,
        seo: {
            title: 'Favourite Donors',
            description: 'View your favourite donors.',
        },
    },
    {
        path: '/compared-donors',
        component: ComparedDonorsPage,
        seo: {
            title: 'Compared Donors',
            description: 'Compare donors side by side.',
        },
    },
    {
        path: '/settings',
        component: SettingsPage,
        seo: {
            title: 'Settings',
            description: 'Change your settings.',
        },
    },
    {
        path: '/settings/:path',
        component: SettingsPage,
        seo: {
            title: 'Settings',
            description: 'Change specific settings.',
        },
    },
    {
        path: '/profile',
        component: ClientProfilePage,
        seo: {
            title: 'Profile',
            description: 'View your profile.',
        },
    },
    {
        path: '/coins',
        component: PackagesPage,
        seo: {
            title: 'Coin Packages',
            description: 'View available coin packages.',
        },
    },
    {
        path: '/packages',
        component: DonorProgramsPage,
        seo: {
            title: 'Programs',
            description: 'View donor programs.',
        },
    },
    {
        path: '/packages/:program',
        component: SingleProgramPage,
        seo: {
            title: 'Program Details',
            description: 'View details of a specific program.',
        },
    },
    {
        path: '/blog',
        component: BlogPage,
        seo: {
            title: 'Blog',
            description: 'Read our latest blog posts.',
        },
    },
    {
        path: '/blog/page/:pageNumber',
        component: BlogPage,
        seo: {
            title: 'Blog Page',
            description: 'Browse blog posts by page.',
        },
    },
    {
        path: '/blog/are-genes-passed-on-from-the-surrogate-mother-to-the-child',
        component: Post1,
        seo: {
            title: 'Blog Post 1',
            description: 'Read about genes and surrogacy.',
        },
    },
    {
        path: '/blog/what-will-the-baby-look-like-when-using-donor-oocytes',
        component: Post2,
        seo: {
            title: 'Blog Post 2',
            description: 'Learn about donor oocytes and baby appearance.',
        },
    },
    {
        path: '/blog/surrogacy-and-egg-donation',
        component: Post3,
        seo: {
            title: 'Blog Post 3',
            description: 'Explore surrogacy and egg donation.',
        },
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicyPage,
        seo: {
            title: 'Privacy Policy',
            description: 'Read our privacy policy.',
        },
    },
    {
        path: '/terms-and-conditions',
        component: TermsAndConditions,
        seo: {
            title: 'Terms and Conditions',
            description: 'Read the terms and conditions.',
        },
    },
    {
        path: '/trips-to-clinic',
        component: TripsToClinicPage,
        seo: {
            title: 'Clinic Trips',
            description: 'Information about trips to the clinic.',
        },
    },
    {
        path: '/egg-donor-trips',
        component: EggDonorsTripsPage,
        seo: {
            title: 'Egg Donor Trips',
            description: 'View information about egg donor trips.',
        },
    },
    {
        path: '/surrogate-trips',
        component: SurrogateTripsPage,
        seo: {
            title: 'Surrogate Mother Trips',
            description: 'Information about trips for surrogate mothers.',
        },
    },
    {
        path: '/trip-to-childbirth',
        component: TripToChildbirthPage,
        seo: {
            title: 'Trip to Childbirth',
            description: 'Information about trips to childbirth.',
        },
    },
    {
        path: '/about-us',
        component: AboutUsPage,
        seo: {
            title: 'About Us',
            description: 'Learn more about us.',
        },
    },
    {
        path: '/contact-us',
        component: ContactUsPage,
        seo: {
            title: 'Contact Us',
            description: 'Get in touch with us.',
        },
    },
    {
        path: '/quick-start',
        component: QuickStart,
        seo: {
            title: 'Quick Start',
            description: 'Get started quickly with our service.',
        },
    },
    {
        path: '/program-stages',
        component: ProgramStagesPage,
        seo: {
            title: 'Program Stages',
            description: 'Learn about different stages of our programs.',
        },
    },
    {
        path: '/sperm-donor-request',
        component: (props) => <DonorRequestPage type="sperm" {...props} />,
        seo: {
            title: 'Sperm Donor Request',
            description: 'Request sperm donation.',
        },
    },
    {
        path: '/egg-donor-request',
        component: (props) => <DonorRequestPage type="eggs" {...props} />,
        seo: {
            title: 'Egg Donor Request',
            description: 'Request egg donation.',
        },
    },
    {
        path: '/surrogate-mother-request',
        component: (props) => <DonorRequestPage type="surrogacy" {...props} />,
        seo: {
            title: 'Surrogate Mother Request',
            description: 'Request a surrogate mother.',
        },
    },
    {
        path: '/full-registration/step1',
        component: DonorRegistrationPage,
        seo: {
            title: 'Registration Step 1',
            description: 'Complete the first step of registration.',
        },
    },
    {
        path: '/full-registration/step2',
        component: DonorRegistrationPage,
        seo: {
            title: 'Registration Step 2',
            description: 'Complete the second step of registration.',
        },
    },
    {
        path: '/full-registration/step3',
        component: DonorRegistrationPage,
        seo: {
            title: 'Registration Step 3',
            description: 'Complete the third step of registration.',
        },
    },
    {
        path: '/full-registration/step4',
        component: DonorRegistrationPage,
        seo: {
            title: 'Registration Step 4',
            description: 'Complete the fourth step of registration.',
        },
    },
    {
        path: '/legislation',
        component: LegislationPage,
        seo: {
            title: 'Legislation',
            description: 'Learn about legislation related to surrogacy and egg donation.',
        },
    },
    {
        path: '*',
        component: NotFoundPage,
        seo: {
            title: '404 - Page Not Found',
            description: 'The page you are looking for does not exist.',
        },
    },
];

