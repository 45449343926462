import React, { useState } from 'react';
import Icon from '../../ui/Icon';
import classes from "./style/faqComponent.module.scss";
import classNames from "classnames";

const FaqComponent = () => {

  const [selectedTab, setSelectedTab] = useState(0);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleTabClick = (index) => {
    setSelectedTab(index);
    if (showMobileMenu) {
      setShowMobileMenu(false)
    }
  };

  const termsArray = [
    {
      title: "About surrogacy agencies",
      content: (
        <div>
          <h3>About surrogacy agencies</h3>
          <p>
            A surrogacy agency is a chance to become parents for people who, for certain reasons, cannot conceive, carry or give birth on their own. If you are unable to conceive and carry a child, surrogacy is one option worth considering. Although surrogacy is a complicated procedure that requires careful candidate screening, medical examinations, and professional supervision, it can be easy for you. We've tried our best to answer the most important questions most people care about and remove any major barriers in your path. We have written more about surrogacy, its peculiarities and subtleties in the following blocks.
          </p>
        </div>
      ),
    },
    {
      title: "What is Surrogacy Definition",
      content: (
        <div>
          <h3>What is Surrogacy Definition</h3>
          <p>Surrogacy is a reproductive technology that involves 3 people in the conception of a child. three people are involved in the conception of a child:</p>
          <ul>
            <li>- Genetic father who provides his sperm for fertilization;</li>
            <li>- Genetic mother, who provides her egg for fertilization;</li>
            <li>- Surrogate mother who agrees to carry and give birth to their child.</li>
          </ul>
          <p>A surrogate mother agrees to carry and bear a child, free of charge or for a fee, from genetic parents.</p>
          <p>This method is used in most cases when a woman is not capable of carrying a child on her own. Today surrogacy is the greatest achievement of mankind. After all, thanks to the latest medical technologies, people who are doomed to childlessness and have lost hope of giving birth to a child, can still defy nature and give birth.</p>
        </div>
      ),
    },
    {
      title: "Who uses surrogacy services?",
      content: (
        <div>
          <h3>Who uses surrogacy services?</h3>
          <p>
          Surrogacy services are mainly used by couples in which the woman cannot bear a child on her own. The category of patients of reproductive clinics who need surrogacy services is mainly women:
          <ul>
            <li>- Having a defect of the uterus or its complete absence;</li>
            <li>- Contraindications to pregnancy;</li>
            <li>- Who have had several unsuccessful attempts at artificial insemination;</li>
            <li>- Have a risk of premature termination of pregnancy and others.</li>
          </ul>
          </p>
          <p>But Surrogacy is not only used in these cases. Single men and members of the LGBT community who also want to become parents also use our surrogacy services.</p>
        </div>
      ),
    },
    {
      title: "Surrogacy Pros and Cons",
      content: (
        <div>
          <h3>Surrogacy Pros and Cons</h3>
          <h4>Surrogacy Pros:</h4>
          <ol>
            <li>Opportunity for childless couples to have a child of their own</li>
            <li>A woman who is expecting a child from a surrogate mother can not limit herself in anything: sports, traveling and other</li>
            <li>A genetic mother doesn't need to go on maternity leave and risk her career</li>
            <li>Any pregnancy spoils your health and figure. A genetic mother avoids these risks. They are voluntarily assumed by the surrogate mother for financial compensation.</li>
            <li>The surrogate mother is given the opportunity to solve her financial problems</li>
          </ol>
          <h4>Surrogacy Cons:</h4>
          <ol>
            <li>It is not known how the Surrogate Mother will behave when it is time to part with the baby. She may want to keep the baby. <br />Such cases are common when a surrogate mother becomes your acquaintance or close friend or you have found a surrogate mother on your own. Turning to a surrogacy agency you take such risks off yourself</li>
            <li>There is a risk that the father-to-be may develop warm feelings for the woman who is carrying his baby. <br />Fortunately, this does not happen if the surrogate mother is far away and does not live with the biological parents of the child from day one</li>
            <li>Also, the surrogate's husband is legally entitled to claim the child. Since his wife (surrogate mother) will give birth to a child, although not biologically theirs, in the marriage.<br />This is why our surrogate mothers are not married</li>
          </ol>
        </div>
      ),
    },
    {
      title: "What are the types of surrogacy?",
      content: (
        <div>
          <h3>What are the types of surrogacy?</h3>
          <p>
          There are two types of surrogacy: gestational surrogacy, where another woman's fertilized egg is placed in the surrogate's uterus, and traditional surrogacy, where the surrogate's egg is fertilized.
          </p>
        </div>
      ),
    },
    {
      title: "How surrogate fertilization takes place",
      content: (
        <div>
          <h3>How surrogate fertilization takes place</h3>
          <p>
          The embryo, derived from the genetic material of the future parents, is transferred into the uterine cavity of the surrogate mother for subsequent gestation and birth. 
          </p>
          <p>For this, the genetic parents will have to undergo a medical examination and give genetic material.</p>
        </div>
      ),
    },
    {
      title: "Egg donation and surrogacy",
      content: (
        <div>
          <h3>Egg donation and surrogacy</h3>
          <p>
          There are also cases when a woman not only has contraindications to pregnancy or cannot bear a child on her own, but also has infertility problems. Fortunately, this is no longer a problem either. In addition to a surrogate mother, it is also possible to use the services of an egg donor. With the level of modern medicine, nothing can stop you on your way to parenthood.
          </p>
        </div>
      ),
    },
    {
      title: "How can I find an egg donor?",
      content: (
        <div>
          <h3>How can I find an egg donor?</h3>
          <p>
          In the past, to find an egg donor, you could ask someone you know or trust to help you or go to a fertility treatment center. But both of these methods have disadvantages.
          </p>
          <p>It is difficult to find so many acquaintances who will easily agree to help you and who are suitable for your health, eye color, hair color, etc. Of course, the clinic allows you to choose a donor for specific requests, but how to know how he looks or what his voice is, if the database of clinics is closed from clients, and access to it often costs a lot of money?</p>
          <p>It's simple! Surrogacy Platform is an egg donor search and matching service with the world's first open database of donors worldwide. Use our service, find the perfect egg donor and let nothing stop you from becoming a parent.</p>
        </div>
      ),
    },
    {
      title: "Is it possible to choose the sex of the baby?",
      content: (
        <div>
          <h3>Is it possible to choose the sex of the baby?</h3>
          <p>
            When planning a child, many parents would like to be able to choose the sex of their child. If conception takes place with the help of IVF, this possibility exists. It is also possible to determine the sex of the child immediately after fertilization of the egg. At the stage when the embryo is examined for genetic diseases, it is already known whether it is a boy or a girl.
          </p>
          <p>
            Selecting the gender of the child is an option that is paid separately in the amount of 1000 euros
          </p>
        </div>
      ),
    },
  ];

  return (
    <section className={classes["terms"]}>
      <div className="container">
        <h2>
          Terms and definitions
        </h2>
        <div className={classes["terms_mobile_select"]} onClick={() => { setShowMobileMenu(!showMobileMenu) }}>
          <span>
            {termsArray[selectedTab].title}
          </span>
          <div className={classNames(classes["image"], showMobileMenu ? classes['open'] : '')}>
            <Icon id="icon-chevron-down" className="icon-chevron-down" />
          </div>
        </div>
        <div className={classes["terms_description"]}>
          <div className={classNames(classes['terms_menu'], showMobileMenu ? '' : classes['terms_mobile_display_none'])}>
            {termsArray.map((tab, index) => (
              <p
                key={index}
                className={classNames(classes['terms-tab'], selectedTab === index ? classes['active'] : '')}
                onClick={() => handleTabClick(index)}
              >
                {tab.title}
              </p>
            ))}
          </div>
          <div className={classes["terms_content"]}>
            {termsArray[selectedTab].content}
          </div>
        </div>
      </div>
    </section>
  );
}

export default FaqComponent;
