import { useState } from 'react';
import FormAuth from '../../components/FormAuth';

import classes from "./style/register.module.scss";
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PageWrap from '../../app/components/PageWrap';
import Button from '../../ui/Button';

export default function Register() {

	const [showVerificationBlock, setShowVerificationBlock] = useState(false);
	const [formType, setFormType] = useState('signup');

	const onShowVerificationBlock = (verification) => {
        setShowVerificationBlock(verification)
    }

	const onShowLogin = () => {
		setShowVerificationBlock(false)
		setFormType('login')
	}

	return (
		<PageWrap fullWide={true}>
			<div className={classes.auth_main}>
				<section className={classes.auth}>
					<div className={classNames(classes.auth_container, showVerificationBlock && classes.verification)}>
						<Link to="/" className={classes.auth_logo}>
							<img src="/cdn/images/logo-big.svg" alt="logo" />
						</Link>
						{
							showVerificationBlock ? (
								<div className={classes['verification-block']}>
									<h3>Thank you for signing up!</h3>
									<p>Verification letter has been sent to your <br />email address</p>
									<Button
										addClasses={["button"]}
										onClick={onShowLogin}
									>
										log in
									</Button>
								</div>
							) : (
								<>
									<FormAuth type={formType} onShowVerificationBlock={onShowVerificationBlock} />
									<div className={classes.auth_separator} />
									<span className={classes.auth_agreement}>
										By signing in, you agree
									</span>
									<div className={classes.auth_terms_block}>
										<Link target="_blank" to="/terms-and-conditions">Terms and Conditions</Link>
										{/* <span>and</span>
										<Link href="#">Privacy Policy</Link> */}
									</div>
								</>
							)
						}
					</div>
				</section>
			</div>
		</PageWrap>
	);
}
