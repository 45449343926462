// context/SEOContext.js
import { createContext, useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';

// Создаем контекст для SEO
const SEOContext = createContext();

export function SEOProvider({ children }) {
    const [seoData, setSeoData] = useState({
        title: 'My App',
        description: 'Default description for the app',
    });

    const setSEO = (newData) => {
        setSeoData((prevData) => ({
            ...prevData,
            ...newData,
        }));
    };

    return (
        <SEOContext.Provider value={{ seoData, setSEO }}>
            <Helmet>
                <title>{seoData?.title || 'Default Title'}</title>
                <meta name="description" content={seoData?.description || 'Default Description'} />
            </Helmet>
            {children}
        </SEOContext.Provider>
    );
}

// Hook для использования SEO контекста
export function useSEO() {
    return useContext(SEOContext);
}
