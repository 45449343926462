import React, { useState } from 'react'
import PageWrap from '../../app/components/PageWrap'
import DonorRequestForm from '../../components/DonorRequestForm'

import classes from './style/donorRequestPage.module.scss'

export default function DonorRequestPage({ type }) {
  const [fromSucces, setFromSucces] = useState();

  const title = () => {
    switch (type) {
      case "eggs":
          return (
            <h1>Become a Donor</h1>
          );
      case "sperm":
          return (
            <h1>Become a Donor</h1>
          );
      case "surrogacy":
          return (
              <h1>Become a Surrogate Mother</h1>
          );
      default:
          return null;
    }
  }

  const content = () => {
    switch (type) {
      case 'eggs':
        return (
          <div className={classes['content']}>
            <h2>Apply to Be an Egg Donor</h2>
            <h3>Empower Others with the Gift of Life</h3>
            <p>Becoming an egg donor is a generous and impactful way to support individuals and couples on their journey to parenthood. Your donation can bring joy and fulfillment to those who need it most. Join our egg donor program today and make a lasting impact.</p>
          </div>
        );
      case 'sperm':
        return (
          <div className={classes['content']}>
            <h2>Apply to Be a Sperm Donor</h2>
            <h3>Make a Life-Changing Contribution</h3>
            <p>As a sperm donor, you have the unique opportunity to help create new lives and support families who are struggling with infertility. We offer a confidential and supportive environment for our donors. Apply now to become a part of our donor community.</p>
          </div>
        );
      case 'surrogacy':
        return (
          <div className={classes['content']}>
            <h2>Apply to Be a Surrogate Mother</h2>
            <h3>Carry Hope and Joy for a Family in Need</h3>
            <p>Surrogacy is a beautiful and selfless act that offers hope to those who cannot carry a pregnancy on their own. As a surrogate mother, you will provide a priceless gift to families yearning for a child. If you are compassionate, healthy, and ready for this incredible journey, we welcome you to apply.</p>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <PageWrap>
      <div className='container'>
        <div className={classes['request-page']}>
          {title()}
          <h2>Join Our Mission to Create Families</h2>
          <p>We invite you to become a vital part of our mission to help individuals and couples achieve their dreams of starting a family. Whether you are considering this opportunity, your contribution can make a significant difference in someone's life. Learn more about how you can get involved and fill out an application to join our program.</p>
          {content()}
          <DonorRequestForm type={type} />
        </div>
      </div>
    </PageWrap>
  )
}
