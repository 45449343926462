import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { resetPasswordRequest } from '../../../actions/authActions';
import Input from '../../../ui/Input';
import Button from '../../../ui/Button';

import classes from '../style/formAuth.module.scss'

const ForgotPass = ({showFormType}) => {
    const dispatch = useDispatch();
    const [resetSuccess, setResetSuccess] = useState(false);
    const [email, setEmail] = useState('');

    const handleClick = (type) => {
        showFormType(type)
    }

    const onSuccessReset = () => {
        setResetSuccess(true)
    }

    const {
		register,
		handleSubmit,
		formState: { errors }
    } = useForm();
    
    const onSubmit = async (data) => {
        try {
            await dispatch(resetPasswordRequest({
                ...data,
                onSuccess: () => onSuccessReset(),
            }));
        } catch (error){
            console.log('error', error);
        }
    };
    
    return (
        <div>
            {
                resetSuccess ? (
                    <p>Your new password has been sent to <b>{email}</b>.<br />You can change it in your settings after logging in.</p>
                ) : (
                    <p>Please enter the email address you would like your password reset information sent to</p>
                )
            }
            {
                !resetSuccess ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            type="email"
                            name="email"
                            useFormEl={register('email')}
                            placeholderNormal="E-mail"
                            withError={errors.email && true}
                            errorText={errors?.email?.type == 'email' ? 'Invalid Email format' : 'Please, enter your Email'}
                            placeHolder="Your Email"
                            onChange={(value) => setEmail(value)}
                        />
                        <Button type="submit" addClasses={["button"]} onClick={handleSubmit(onSubmit)}>
                            Request reset link
                        </Button>
                        <div className={classes['back']} onClick={() => handleClick('login')}>Back to login</div>
                    </form>
                ) : (
                    <Button type="submit" addClasses={["button"]} onClick={() => handleClick('login')}>
                        log in
                    </Button>
                )
            }

        </div>
    );
}

export default ForgotPass;
