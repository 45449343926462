import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import PageWrap from "../../app/components/PageWrap";

import StandartProgram from "./components/StandartProgram";
import ComfortProgram from "./components/ComfortProgram";
import VipProgram from "./components/VipProgram";

import classes from "./style/singleProgramPage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getDonorPackagesRequest } from "../../actions/globalActions";

const SingleProgramPage = () => {
    const { program } = useParams();
    const dispatch = useDispatch();

    const donorPacks = useSelector((state) => state.globalState.donorPacks);

    useEffect(() => {
        if(!donorPacks){
            dispatch(getDonorPackagesRequest())
        }
    }, [donorPacks]);

    useEffect(() => {
        document.querySelector("body").style.overflow = "initial";
    }, []);

    function getPackageByName(name) {
        if(donorPacks){
            return donorPacks.find(pkg => pkg.name === name);
        }
    }

    function getProgramInfoPage(type) {
        switch (type) {
            case "standart":
                return <StandartProgram pack={getPackageByName('standart')} />;
            case "comfort":
                return <ComfortProgram pack={getPackageByName('comfort')} />;
            case "vip":
                return <VipProgram pack={getPackageByName('vip')} />;
            default:
                return null;
        }
    }

    return (
        <PageWrap>
            <section className={classes["single-program"]}>
                <div className="container">
                    {
                        donorPacks && (
                            program && getProgramInfoPage(program)
                        )
                    }
                </div>
            </section>
        </PageWrap>
    );
};

export default SingleProgramPage;
