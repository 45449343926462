import React from "react";
import Icon from "../../ui/Icon";
import Tabs from "../Tabs";

import classes from "./style/galleryFilterHeader.module.scss";

const GalleryFilterHeader = ({ tabsList, showMobileFilter, activeTab, onChangeTab, type}) => {

    const onChangeSortType = () => {
        console.log('onChangeSortType');
    }

    return (
        <div className={classes["search_main_header"]}>
            { tabsList && (
                <Tabs tabsList={tabsList} activeTab={activeTab} onChangeTab={onChangeTab} />
            )}
            <div className={classes["sort"]}>
                {showMobileFilter && (
                    <div className={classes["filter"]} onClick={showMobileFilter}>
                        <div className={classes["image"]}>
                            <Icon id="icon-filter" className="icon-filter" />
                        </div>
                    </div>
                )}
                {/* {
                    type !== 'main' && (
                        <div className={classes["sort_by"]}>
                            <select name="sort" id="sort" className={classes["type"]} value="Top" onChange={onChangeSortType}>
                                <option value="Top" selected="Top">Top</option>
                                <option value="New">New</option>
                            </select>
                            <div className={classes["image"]}>
                                <Icon id="icon-arrow-down" />

                            </div>
                        </div>
                    )
                } */}
            </div>
        </div>
    );
};

export default GalleryFilterHeader;
