import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
    const [selectedChat, setSelectedChat] = useState('');
    const [user, setUser] = useState();
    const [notification, setNotification] = useState(null);
    const [chats, setChats] = useState([]);
    const [donor, setDonor] = useState(null);
    const [donors, setDonors] = useState([]);
    const [reload, setReload] = useState(false);
    const [videoRooms, setVideoRooms] = useState(null);
    const [currentTextChat, setCurrentTextChat] = useState(null);
    const [isLowBalance,setIsLowBalance]=useState(false)
    const [donorPhotos, setDonorPhotos] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        setUser(userInfo);

        // if (!userInfo) navigate("/");
    }, []);

    return (
        <ChatContext.Provider
            value={{
                selectedChat,
                setSelectedChat,
                user,
                setUser,
                notification,
                setNotification,
                chats,
                setChats,
                donor,
                setDonor,
                reload,
                setReload,
                videoRooms,
                setVideoRooms,
                donors,
                setDonors,
                currentTextChat,
                setCurrentTextChat,
                isLowBalance,
                setIsLowBalance,
                donorPhotos,
                setDonorPhotos,
                isModalOpen,
                setIsModalOpen
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};

export const ChatState = () => {
    return useContext(ChatContext);
};

export default ChatProvider;
