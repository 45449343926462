import React from 'react'

import classes from './style/quickStart.module.scss'
import PageWrap from '../../../../app/components/PageWrap'

export default function QuickStart() {
    return (
        <PageWrap>
            <div className='container'>
                <section className={classes['quick-start-page']}>
                    <div className={classes['welcome-block']}>
                        <div className={classes['welcome-text']}>
                            <h1>Quick Start</h1>
                            <h2>Here you will learn how best to start getting acquainted with our service.</h2>
                            <p>Surrogacy Platform is Egg Donor search and matching service and implementation of full Surrogacy Programms in Cyprus</p>
                        </div>
                        <div className={classes['welcome-banner']}>
                            <img src="/cdn/images/quick-start/quick-banner.png"  />
                        </div>
                    </div>
                    <div className={classes['how-it-works-block']}>
                        <h3>How does it work?</h3>
                        <div className={classes['grid']}>
                            <div className={classes['item']}>
                                <img src="/cdn/images/quick-start/surrogacy-eye.png"  />
                                <h4>Surrogacy Platform is the World's First Totally Free Database of Egg Donors</h4>
                                <p>From the start you have access to a catalogue of egg donors. You can browse profiles, see photos of candidates, get acquainted with their characteristics and use filters to easily find the right egg donor.</p>
                            </div>
                            <div className={classes['item']}>
                                <img src="/cdn/images/quick-start/registration.png"  />
                                <h4>Registration</h4>
                                <p>In order to open detailed profiles and all photos of egg donors, you need to register on our service.<br />We value and respect your anonymity, so we have made it possible for you to register anonymously. Just use your email and create a nickname for yourself.</p>
                            </div>
                            <div className={classes['item']}>
                                <img src="/cdn/images/quick-start/communicating.png"  />
                                <h4>Communicating with egg donors</h4>
                                <p>Our service also allows you to communicate directly with egg donors. You will have access to 3 communication formats: Email, Online Chat, Video Chat.</p>
                            </div>
                            <div className={classes['item']}>
                                <img src="/cdn/images/quick-start/email.png"  />
                                <h4>Emails</h4>
                                <p>This is a format for those who prefer a more business-like correspondence and don't want to be tied to time. Ask your question or a list of questions and then browse other candidates' profiles or do other things. The egg donor will be notified of your email and will reply as soon as she logs on to the service.</p>
                            </div>
                            <div className={classes['item']}>
                                <img src="/cdn/images/quick-start/video.png"  />
                                <h4>Video Chat</h4>
                                <p>Video Chat gives you the feeling of being fully present and allows you to see the egg donor or surrogate mother "Live". You don't have to switch on your camera. Keep chatting in the text window and get to know the prospective candidate better.</p>
                            </div>
                            <div className={classes['item']}>
                                <img src="/cdn/images/quick-start/egg-donation.png"  />
                                <h4>Online Chat</h4>
                                <p>For those who like a more live chat and want an answer here and now.</p>
                            </div>
                            <div className={classes['item']}>
                                <img src="/cdn/images/quick-start/timezone.png"  />
                                <h4>Language and time zone</h4>
                                <p>Surrogacy Platform - is an international service with a list of egg donors from all over the world.<br />No need to worry about language barriers, Surrogacy Platform has hired professional interpreters who will always help you comfortably communicate with your prospective egg donor. Good communication is the key to success in any business.Time zone differences are also not a problem. You can always book a convenient time for you to have a particular candidate online and available for a Chat or Video Chat or just write an Email to avoid being tied to time.</p>
                            </div>
                            <div className={classes['item']}>
                                <img src="/cdn/images/quick-start/start-communication.png"  />
                                <h4>How to start communication with an egg donor?</h4>
                                <p>You will need to top up your Credits (Coins) balance to communicate with egg donors.</p>
                            </div>
                            <div className={classes['item']}>
                                <img src="/cdn/images/quick-start/tariffs.png"  />
                                <h4>You can read about the tariffs on the tab "Packages"</h4>
                                <p>Once you have selected your ideal candidate, to proceed to select a service rate, click on the "Order donor" button. You can read more about the stages of the program in the section "Program Stages"</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={classes['thnx-section']}>
                    <div className={classes['thnx-block']}>
                        <img src="/cdn/images/quick-start/heearts.png" />
                        <div>
                            <h2>Thank you for reading our Tutorial!</h2>
                            <p>Surrogacy Platform wishes you a pleasant use of our service.</p>
                        </div>
                    </div>
                </section>
            </div>
        </PageWrap>
    )
}
