import React from 'react';
import PageWrap from '../../../../app/components/PageWrap';

import classes from '../../style/staticPages.module.scss'

const TripsToClinicPage = () => {
    return (
        <PageWrap>
            <div className='container'>
                <section className={classes['static-pages-contaniner']}>
                <h1>Your first trip to our clinic in Cyprus</h1>

                <p>To start working, we need to conclude an agreement with you and sign it bilaterally. After signing the agreement, the selected egg donor and also the surrogate mother already begin their participation process, we begin to select air tickets and transfer.</p>
                <p>The surrogacy platform will help you with the selection of tickets and transfer from your country to our clinic in Cyprus with a transfer in Istanbul, Turkey. Also, if necessary, we will help with the choice of housing.</p>
                <p>Our manager will meet you and take you to the clinic, and after signing the agreement you will have time to relax and enjoy the unusual beauty of this country.</p>


                </section>
            </div>
        </PageWrap>
    );
}

export default TripsToClinicPage;
