import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import classes from './styles/select.module.scss'
import Icon from '../Icon';

function Select({ values, placeholder, onChange, className, useFormEl, name, defaultValue, withError, errorText, placeholderInline, withNewStyle  }) {
    const [value, setValue] = useState("");

    let id = Math.floor(Math.random() * 900000)
    const selectValue = useRef(null)

    const ucFirst = (str) => {
        if (!str || typeof str === 'number') return str
        return str[0].toUpperCase() + str.slice(1)
    }

    const onChangeSelect = e => {
        setValue(e.target.value)

        if(onChange) {
            onChange(e.target.value)
        }else {
            useFormEl.onChange(e)
        }
    }

    const setRef = (e) => {
        if (useFormEl) {
            useFormEl.ref(e)
        }
        selectValue.current = e
    }

    return (
        <div className={classNames(
            classes['select-row'],
            className ? className : null,
            withError ? classes['error'] : null,
            !value && !defaultValue ? classes['select-row--placeholder'] : null,
        )}>
            {placeholder ? (
                <label htmlFor={name + '_' + id} suppressHydrationWarning={true}>
                    <span>{placeholder}</span>
                </label>
            ) : null}
            <select
                {...useFormEl}
                defaultValue={defaultValue ?? ""}
                name={name}
                id={name + '_' + id}
                onChange={onChangeSelect}
                ref={setRef}
                suppressHydrationWarning={true}
            >
                {placeholderInline ? <option value="" disabled>{placeholderInline}</option> : null}
                {
                    values
                    ?
                        values.map((item) => (
                            <option value={item.value ? item.value : item.name} key={item.value ? item.value : item.name}>
                                {ucFirst(item.name)}
                            </option>
                        ))
                    :
                        null
                }
            </select>
            {
                withError ?
                    <span className={classes["error-label"]}>{errorText ? errorText : null}</span>
                : null
            }
            <div className={classes["image"]}>
                <Icon id="icon-arrow-down" className="icon-arrow-down" />
            </div>
        </div>
    )
}

export default Select
