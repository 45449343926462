import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";

import Icon from "../../../ui/Icon";
import Button from "../../../ui/Button";

import classes from "../style/changePassword.module.scss";
import classNames from "classnames";

const ChangePassword = () => {
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [errorPassword, setErrorPassword] = useState('');
    const [successChanges, setSuccessChanges] = useState('');

    let user = JSON.parse(localStorage.getItem("userInfo"));

    const handleToggleOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleToggleNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const {
		register,
		handleSubmit,
        reset,
		formState: { errors }
    } = useForm();
    
    const onSubmit = async (data) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
                },
            };
            if (data.oldPassword === data.newPassword) {
                setErrorPassword('Old and new passwords should not match.');
                return;
            }
            const passwordData = await axios.post(
                `https://dev-api.surrogacy-platform.com/api/auth/change-password`,
                data,
                config
            );
            setErrorPassword('')
            reset()
            setSuccessChanges(passwordData.data.message)
        } catch (error) {
            console.error('An error occurred:', error);
            setErrorPassword(error.response?.data?.message || 'An error occurred.');


        }
    };

    return (
        <div className={classes["settings-change-password"]}>
            <h4>Change Password</h4>
            {
                successChanges ? (
                    <p className={classes["success"]}>{successChanges}</p>
                ) : (
                    <div className={classes["form-block"]}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={classes["input-row"]}>
                                <label htmlFor="oldPassword">Old Password</label>
                                <div className={classNames(classes["password-input"], (errorPassword || errors.oldPassword) && classes['error'])}>
                                    <input
                                        type={showOldPassword ? "text" : "password"}
                                        id="oldPassword"
                                        {...register("oldPassword", { required: 'Please enter old password' })}
                                        placeholder="Enter old password"
                                        autoComplete="off"
                                    />
                                    <div className={classes['icon']} onClick={handleToggleOldPassword}>
                                        {
                                            showOldPassword ? (
                                                <Icon id="icon-eye" />
                                                ) : (
                                                <Icon id="icon-eye-off" />
                                            )
                                        }
                                    </div>
                                </div>
                                {errors.oldPassword && <p className={classes['error-text']}>{errors.oldPassword.message}</p>}
                            </div>
                            <div className={classes["input-row"]}>
                                <label htmlFor="newPassword">New Password</label>
                                <div className={classNames(classes["password-input"], (errorPassword || errors.newPassword) && classes['error'])}>
                                    <input
                                        type={showNewPassword ? "text" : "password"}
                                        id="newPassword"
                                        {...register("newPassword", { required: 'Please enter new password' })}
                                        placeholder="Enter new password"
                                        autoComplete="off"
                                    />
                                    <div className={classes["icon"]} onClick={handleToggleNewPassword}>
                                        {
                                            showNewPassword ? (
                                                <Icon id="icon-eye" />
                                                ) : (
                                                <Icon id="icon-eye-off" />
                                            )
                                        }
                                    </div>
                                </div>
                                {errors.newPassword && <p className={classes['error-text']}>{errors.newPassword.message}</p>}
                            </div>
                            {
                                errorPassword && (
                                    <p className={classes['error-text']}>{errorPassword}</p>
                                )
                            }
                            <Button addClasses={['capitalize', 'default']} onClick={handleSubmit(onSubmit)}>Save Changes</Button>
                        </form>
                    </div>
                )
            }
        </div>
    );
};

export default ChangePassword;
