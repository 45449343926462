import timezones from 'timezones-list';

export function convertDatetime(inputDatetime, id) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = new Date(inputDatetime);

    // if(id) {
    //     const timeZone = timezones.find(zone => zone.tzCode === id);

    //     const [hours, minutes] = timeZone.utc.split(":").map(Number);
    //     const totalMinutes = hours * 60 + minutes;
        
    //     if (totalMinutes !== undefined && totalMinutes !== null) {
    //         const utcTimestamp = date.getTime() + (date.getTimezoneOffset() * 60000);
    //         const clientTimestamp = utcTimestamp + (totalMinutes * 60000);
    //         date.setTime(clientTimestamp);
    //     }
    // }
    
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = (date.getHours() < 10 ? '0' : '') + date.getHours();
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    
    return `${day} ${month} ${year}, ${hours}:${minutes}`;
}
