import React from 'react';
import classes from "../style/profileSection.module.scss";
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const ProfileSection = ({donor}) => {

    const additionalInfo = [
        { label: "My favorite music is", value: donor.additionalInfo.music },
        { label: "My favorite movies are", value: donor.additionalInfo.movies },
        { label: "My favorite sport", value: donor.additionalInfo.sport },
        { label: "My favorite flower is", value: donor.additionalInfo.flower },
        { label: "In my spare time, I like", value: donor.additionalInfo.freeTime },
        { label: "Of all the places I would like to visit, I would most like to visit", value: donor.additionalInfo.likeToVisit },
        { label: "My favorite season is", value: donor.additionalInfo.season },
        { label: "My favorite food is", value: donor.additionalInfo.food },
        { label: "I dislike", value: donor.additionalInfo.dislike },
    ];

    return (
        <section className={classes.profile}>
            <div className={classes.description}>
                <h3>Profile</h3>
                <div className={classNames(classes.data)}>
                    {
                        typeof donor.additionalInfo === 'object' && Object.keys(donor.additionalInfo).length > 0 && (
                            <ul className={classes.left_block}>
                                {additionalInfo.map((item, index) => (
                                    <li key={index}>
                                        <span className={classes.title}>{item.label}</span>
                                        <span className={classes.text}>{item.value}</span>
                                    </li>
                                ))}
                            </ul>
                        )
                    }
                    {
                        donor.aboutLetter && (
                            <p className={classes.right_block}>
                                {donor.aboutLetter}
                            </p>
                        )
                    }
                </div>
            </div>
        </section>
    );
}

export default ProfileSection;
