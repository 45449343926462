import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import PageWrap from '../../app/components/PageWrap'
import FirstStep from './components/FirstStep';
import SecondStep from './components/SecondStep';
import ThirdStep from './components/ThirdStep';

import classes from './style/donorRegistrationPage.module.scss'
import UploadPhoto from './components/UploadPhoto';
import { donorFullRegistrationRequest } from '../../actions/donorActions';
import { useDispatch } from 'react-redux';

export default function DonorRegistrationPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
  
    const firstName = queryParams.get('firstName');
    const lastName = queryParams.get('lastName');
    const email = queryParams.get('email');
    const donorType = queryParams.get('donorType');

    const [donorId, setDonorId] = useState(0);

    const [formData, setFormData] = useState({
        firstName: firstName,
        lastName: lastName,
        email: email,
        country: "",
        dateOfBirth: "",
        maritalStatus: "",
        children: "",
        education: "",
        occupation: "",
        eyeColor: "",
        hairColor: "",
        height: "",
        weight: "",
        bodyType: "",
        drinking: "",
        smoking: "",
        rhFactors: "",
        bloodTypes: "",
        ethnicity: "",
        aboutLetter: "",
        levelOfEnglish: "",
        otherLanguages: "",
        donorType: donorType,
        label: 'new',
        additionalInfo: {
          music: "",
          movies: "",
          sport: "",
          flower: "",
          freeTime: "",
          likeToVisit: "",
          season: "",
          food: "",
          dislike: ""
        }
      });

    const steps = {
        "/full-registration/step1/": FirstStep,
        "/full-registration/step1": FirstStep,
        "/full-registration/step2": SecondStep,
        "/full-registration/step3": ThirdStep,
        "/full-registration/step4": UploadPhoto
    };

    const currentStep = location.pathname;
    const StepComponent = steps[currentStep];

    const updateFormData = (newData) => {
      setFormData((prevData) => ({
        ...prevData,
        ...newData,
      }));
      if(currentStep == "/full-registration/step3"){
        dispatch(donorFullRegistrationRequest(formData, (response, error) => {
          if (response) {
            setDonorId(response.data.donor.id)
            localStorage.setItem('donorId', response.data.donor.id);
            handleNext('/full-registration/step4');
          } else {
              console.error("Failed donor request:", error);
          }
        }))
      }
    };
  
    const handleNext = (nextStep) => {
      navigate(nextStep);
    };
  
    const handlePrev = (prevStep) => {
      navigate(prevStep);
    };

    return (
        <PageWrap fullWide={true} >
            <div className={classes["full-registration"]}>
                <h2>Profile registration</h2>
                <p>By filling out this form, you give yourself time to process and submit your personal data.</p>
                <div className={classes["steps-container"]}>
                {StepComponent && (
                    <StepComponent
                        formData={formData}
                        updateFormData={updateFormData}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                        donorId={donorId}
                    />
                )}
                </div>
            </div>
        </PageWrap>
    )
}
