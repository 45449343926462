import React from 'react'

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { countries } from "countries-list";

import Input from '../../../ui/Input';
import Button from '../../../ui/Button';
import Select from "../../../ui/Select";

const schema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Invalid email format').required('Email is required'),
    country: yup.string().required('Country is required'),
    dateOfBirth: yup.date().required('Date of Birth is required'),
    maritalStatus: yup.string().required('Marital Status is required'),
    children: yup.string().required('Children status is required'),
    education: yup.string().required('Education is required'),
    occupation: yup.string().required('Occupation is required')
});

const countriesData = Object.values(countries);

const maritalStatusArr = [
    { value: 'Married', name: 'Married' },
    { value: 'Unmarried', name: 'Unmarried' },
]

const levelOfEducationArr = [
    { value: 'High School', name: 'High School' },
    { value: 'College', name: 'College' },
    { value: 'Student', name: 'Student' },
    { value: 'University', name: 'University' },
    { value: 'Postgraduate', name: 'Postgraduate' }
]

const occupationArr = [
    { value: 'IT and Technology', name: 'IT and Technology' },
    { value: 'Healthcare', name: 'Healthcare' },
    { value: 'Education and Science', name: 'Education and Science' },
    { value: 'Business and Finance', name: 'Business and Finance' },
    { value: 'Marketing and Advertising', name: 'Marketing and Advertising' },
    { value: 'Construction and Engineering', name: 'Construction and Engineering' },
    { value: 'Manufacturing and Industry', name: 'Manufacturing and Industry' },
    { value: 'Hospitality and Restaurant', name: 'Hospitality and Restaurant' },
    { value: 'Arts and Entertainment', name: 'Arts and Entertainment' },
    { value: 'Transport and Logistics', name: 'Transport and Logistics' },
    { value: 'Agriculture and Ecology', name: 'Agriculture and Ecology' },
    { value: 'Legal', name: 'Legal' },
    { value: 'Public Service', name: 'Public Service' },
    { value: 'Retail', name: 'Retail' },
    { value: 'Charity and Nonprofit', name: 'Charity and Nonprofit' },
    { value: 'Freelance and Self-employed', name: 'Freelance and Self-employed' },
    { value: 'Unemployed', name: 'Unemployed' },
];

export default function FirstStep({ formData, updateFormData, handleNext }) {

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: formData,
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data) => {
        try {
            updateFormData(data);
            handleNext('/full-registration/step2');
        } catch (error){
            console.log('error', error);
        }
    };

    const inputProps = {
        style: {textTransform: 'capitalize'}
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Input
                type="text"
                name="firstName"
                useFormEl={register('firstName')}
                placeholderNormal="First Name"
                withError={errors.firstName && true}
                errorText={errors?.firstName?.type == 'firstName' ? 'Invalid First Name format' : 'Please, enter your First Name'}
                placeHolder="First Name"
                addProps={inputProps}
            />
            <Input
                type="text"
                name="lastName"
                useFormEl={register('lastName')}
                placeholderNormal="Last Name"
                withError={errors.lastName && true}
                errorText={errors?.lastName?.type == 'lastName' ? 'Invalid Last Name format' : 'Please, enter your Last Name'}
                placeHolder="Last Name"
                addProps={inputProps}
            />
            <Input
                type="email"
                name="email"
                useFormEl={register('email')}
                placeholderNormal="E-mail"
                withError={errors.email && true}
                errorText={errors?.email?.type == 'email' ? 'Invalid Email format' : 'Please, enter your Email'}
                placeHolder="Email"
            />
            <Select
                values={countriesData}
                name="country"
                useFormEl={register('country')}
                placeholderInline="Select Country"
                withError={errors.country}
                errorText="Please, select Country"
                placeholder="Country"
            />
            <Input
                type="date"
                name="dateOfBirth"
                useFormEl={register('dateOfBirth')}
                placeholderNormal="Date of Birth"
                withError={errors.dateOfBirth && true}
                errorText={errors?.dateOfBirth?.type == 'dateOfBirth' ? 'Invalid Date of Birth format' : 'Please, enter your Date of Birth'}
                placeHolder="Date of Birth"
            />
            <Select
                values={maritalStatusArr}
                name="maritalStatus"
                useFormEl={register('maritalStatus')}
                placeholderInline="Select Marital Status"
                withError={errors.maritalStatus}
                errorText="Please, select Marital Status"
                placeholder="Marital Status"
            />
            <Input
                type="text"
                name="children"
                useFormEl={register('children')}
                placeholderNormal="Children"
                withError={errors.children && true}
                errorText={errors?.children?.type == 'children' ? 'Invalid Children format' : 'Please, enter your Children'}
                placeHolder="Do you have any children? How many?"
                addProps={inputProps}
            />
            <Select
                values={levelOfEducationArr}
                name="education"
                useFormEl={register('education')}
                placeholderInline="Select Level of Education"
                withError={errors.education}
                errorText="Please, select Level of Education"
                placeholder="Level of Education"
            />
            <Select
                values={occupationArr}
                name="occupation"
                useFormEl={register('occupation')}
                placeholderInline="Select Occupation"
                withError={errors.occupation}
                errorText="Please, select Occupation"
                placeholder="Occupation"
            />
            <Button type="submit" addClasses={["button"]}>
                next step
            </Button>
        </form>
    )
}