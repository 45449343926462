import React from "react";

import classes from '../style/mainChars.module.scss'

const MainChars = ({ mainCharsData1, mainCharsData2 }) => {
    return (
        <div className={classes["compare_2_main_characteristics"]}>
            <h2>
                Main Characteristics
            </h2>
            <div className={classes["compare_2_main_characteristics_blocks"]}>
                <ul className={classes["main_info"]}>
                {mainCharsData1.map((item, index) => (
                        <li key={index}>
                            <div>
                                <img src={`/cdn/images/donor-profile/${item.icon}.png`} alt="" />
                                <span>{item.label}</span>
                            </div>
                            <span>{item.value}</span>
                        </li>
                ))}
                </ul>
                <ul className={classes["main_info"]}>
                    {mainCharsData2.map((item, index) => (
                        <li key={index}>
                            
                            <div className="tablet-display-none">
                                <img src={`/cdn/images/donor-profile/${item.icon}.png`} alt="" />
                                <span>{item.label}</span>
                            </div>
                            <span>{item.value}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default MainChars;
