import { useEffect, useState } from 'react';

import { Link, useLocation } from "react-router-dom";

import Icon from "../../ui/Icon";

import classes from './style/asideMenu.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

export default function AsideMenu({inChat}) {
    const location = useLocation();
	const [hideMenu, setHideMenu] = useState(false)
	const [subMenuShow, setSubMenuShow] = useState(null)

    const userInfo = useSelector((state) => state.user.user);
    const unreaded = useSelector((state) => state.user.unreaded);

	let timeout;

    const menuItems = [
        { path: '/donors', label: 'Donors', icon: 'icon-menu-donors' },
        { path: !userInfo?.isLoggedIn ? '/login' : '/textchat', label: 'Chat', icon: 'icon-menu-chat', count: unreaded?.textChats, subMenu: [
            {
                avatar: "/cdn/images/avatar.png", name:"Olya", count: 0, link: "/textchat/99"
            },
            {
                avatar: "/cdn/images/avatar.png", name:"Karina", count: 8, link: "/textchat/100"
            },
            {
                avatar: "/cdn/images/avatar.png", name:"Kristina", count: 5, link: "/textchat/101"
            },
            {
                avatar: "/cdn/images/avatar.png", name:"Sveta", count: 4, link: "/textchat/102"
            },
        ] },
        { path: !userInfo?.isLoggedIn ? '/login' : '/videochat', label: 'Videochat', icon: 'icon-menu-video'},
        { path: !userInfo?.isLoggedIn ? '/login' : '/mailbox', label: 'Email', icon: 'icon-email', count: unreaded?.emails, subMenu: [
            {
                avatar: "/cdn/images/avatar.png", name:"Karina", count: 8, link: "/mailbox/readletter/1"
            },
            {
                avatar: "/cdn/images/avatar.png", name:"Sveta", count: 4, link: "/mailbox/readletter/2"
            },
            {
                avatar: "/cdn/images/avatar.png", name:"Olya", count: 0, link: "/mailbox/readletter/3"
            },
            {
                avatar: "/cdn/images/avatar.png", name:"Kristina", count: 5, link: "/mailbox/readletter/5"
            },
        ]  },
        { path: !userInfo?.isLoggedIn ? '/login' : '/saved-donors', label: 'Saved', icon: 'icon-saved' },
        { path: !userInfo?.isLoggedIn ? '/login' : '/favourite-donors', label: 'Liked', icon: 'icon-liked' },
        { path: !userInfo?.isLoggedIn ? '/login' : '/compared-donors', label: 'Compare', icon: 'icon-scales' },
        { path: !userInfo?.isLoggedIn ? '/login' : '/settings', label: 'Settings', icon: 'icon-settings' },
    ];

    const handleSideBarArrowClick = () => {
        setHideMenu(!hideMenu);
        
    };

    const openMenu = () => {
		if (timeout) {
		  	clearTimeout(timeout);
		}
	
		timeout = setTimeout(() => {
		  	if (hideMenu) {
				setHideMenu(false);
		  	}
			}, 500);
	};
	
	const cancelOpenMenu = () => {
		if (timeout) {
		  	clearTimeout(timeout);
		}

        if(subMenuShow) {
            setSubMenuShow(null);
        }
	};

	const openSubMenu = (index) => {
		if (timeout) {
			clearTimeout(timeout);
	  	}
  
	  timeout = setTimeout(() => {
			if (!subMenuShow) {
				setSubMenuShow(index);
			}
		}, 500);
	}

	const cancelOpenSubMenu = () => {
		if (timeout) {
		  	clearTimeout(timeout);
		}
	};

    useEffect(() => {
        let container = document.querySelector(".container")
        if(container){
            let containers = document.querySelectorAll(".container");
            if(hideMenu){
                containers.forEach(container => {
                    container.classList.add('full-wide');
                });
            }else {
                containers.forEach(container => {
                    container.classList.remove('full-wide');
                });
            }
        }
    }, [hideMenu]);

    return (
		<aside 
			className={classNames(classes["side_bar"], hideMenu ? classes['side_bar_close'] : '', inChat && classes['disables'])} 
			onMouseEnter={openMenu}
			onMouseLeave={cancelOpenMenu}
		>
            <div className={classes["side_bar_logo_block"]}>
            {
                !hideMenu ?
                    <Link to="/" className={classes["side_bar_logo"]}>
                        <img src="/cdn/images/logo-big.svg" alt="logo" />
                    </Link>
                    :
                    <Link to="/" className={classNames(classes["side_bar_logo_small"], )}>
                        <img src="/cdn/images/logo-sm.svg" alt="logo" />
                    </Link>
            }

            {
                !hideMenu ?
                    <div className={classes["side_bar_arrow"]} onClick={handleSideBarArrowClick}>
                        <div className={classes["image"]}>
                            <Icon className="icon-arrow-left" id="icon-arrow-left" />
                        </div>
                    </div>
                :
                    null
            }
            </div>
            <div className={classes["side_bar_menu_block"]}>
                <h3 className={classes["text_menu"]}>menu</h3>
                <ul>
                    {menuItems.map((item, index) => (
                        <li key={index} className={(location.pathname.includes(item.path) || (item.subMenu && item.subMenu.some(subMenu => location.pathname === subMenu.link))) ? classes['active'] : ''}>
                            <Link to={item.path} onMouseEnter={item.subMenu ? () => openSubMenu(index) : undefined} onMouseLeave={item.subMenu ? cancelOpenSubMenu : undefined}>
                                <div className={classes['image']}>
                                    <Icon className={item.icon} id={item.icon} />
                                </div>
                                {!hideMenu && <span className={classes['open-close']}>{item.label}</span>}
                                {
                                    item.count > 0 && (
                                        <div className={classes["badge"]}>
                                            {item.count}
                                        </div>
                                    )
                                }
                            </Link>
                        {/* {
                            item.subMenu && subMenuShow === index ? (
                                <div className={classNames(classes["chats"], hideMenu ? 'display-none' : '', subMenuShow ? classes['sub-menu'] : '')}>
                                    {
                                        item.subMenu.map((subMenu, index) => (
                                            <div key={index} className={classes["sub_menu"]}>
                                                <Link to={subMenu.link}>
                                                    <div className={classNames("avatar", classes["avatar"])}>
                                                        <img src={subMenu.avatar} alt="" />
                                                    </div>
                                                    <span className={classes["name"]}>
                                                        {subMenu.name}
                                                    </span>
                                                    {
                                                        subMenu.count ? (
                                                            <div className={classes["badge"]}>
                                                                {subMenu.count}
                                                            </div>
                                                        ) : null
                                                    }
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </div>
                            ) : null
                        } */}
                        </li>
                    ))}
                </ul>
            </div>
        </aside>
    )
}
