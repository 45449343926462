import React, { useState } from 'react';

import LinkButton from '../../../ui/Button/LinkButton';
import Icon from '../../../ui/Icon';

import classes from '../style/singleProgramPage.module.scss'
import classNames from 'classnames';
import Childbirth from './Childbirth';

const StandartProgram = ({pack}) => {
    const [accordionBlock, setAccordionBlock] = useState([]);

    const showAccordionBlock = (type) => {
        if (accordionBlock.includes(type)) {
            setAccordionBlock(accordionBlock.filter(item => item !== type));
        } else {
            setAccordionBlock([...accordionBlock, type]);
        }
    }

    return (
        <div className={classes['single-program-container']}>
            <div className={classes['info-card']}>
                <div className={classes['col']}>
                    <div className={classes['icon']}>
                        <img src="/cdn/images/programs/standart.png" alt="" />
                    </div>
                    <div className={classes['info']}>
                        <p className={classes['name']}>{pack?.name}</p>
                        <p className={classes['desc']}>{pack?.shortDescription}</p>
                        <p className={classes['location']}><Icon id="icon-map-pin" /> Cyprus</p>
                    </div>
                </div>
                <div className={classes['col']}>
                    <div className={classes['pays']}>
                        <div className={classes['pays-info']}>
                            <p className={classes['payment']}>1 payment</p>
                            <p className={classes['price']}>€{pack?.price}</p>
                        </div>
                        <div className={classes['pays-info']}>
                            <p className={classes['payment']}>2 payment</p>
                            <p className={classes['price']}>€4700</p>
                        </div>
                        <div className={classes['pays-info']}>
                            <p className={classes['payment']}>3 payment</p>
                            <p className={classes['price']}>€13000</p>
                        </div>
                    </div>
                    <LinkButton to="/donors" className={classes['choose-button']} addClasses={['capitalize']}>Choose donor</LinkButton>
                </div>
            </div>
            <div className={classes['full-information']}>
                <p><b>Package “Standard” - surrogacy program with IVF (1 protocol of embryos transfer) in the Turkish Republic of Northern Cyprus (TRNC)<br />
                    (supervision up to 24-26 weeks of pregnancy)</b></p>
                <p className={classes['small-title']}>List of services</p>
                <ul>
                    <li>1 protocol of embryo  transfer into the uterus of the surrogate mother</li>
                    <li>The package is available for married and unmarried couples, single parents, LGBT (medical indications for surrogacy are not compulsory)</li>
                </ul>
                <div className={classes['accordion-block']}>
                    <div className={classNames(classes['title'], accordionBlock.includes('stage-1') && classes['open'])} onClick={() => showAccordionBlock('stage-1')}>
                        <p>Stage 1 - Document preparation and surrogate mother preparation (after signing a contract for informational and juridical support)</p>
                        <Icon id='icon-chevron-down' />
                    </div>
                    {
                        accordionBlock.includes('stage-1') && (
                            <div className={classes['info']}>
                            
                                <ul>
                                    <li>Information services, support and coordination during the program</li>
                                    <li>Preparation and translation of documents for participation in the program</li>
                                    <li>Execution of the necessary documents, including visa costs, for the surrogate mother to ensure her residence in the territory of the TRNC</li>
                                    <li>Interpreter services during clinic visits</li>
                                    <li>Selection and provision of a surrogate mother candidate, complete medical examination and preparation of the surrogate mother (SM) before the IVF program</li>
                                    <li>Providing a transfer for the surrogate mother to (from) the airport, to the clinic for consultations and manipulations</li>
                                    <li>The medical part of the IVF program is carried out by the Reproductive Medicine Clinic in the TRNC</li>
                                    <li>The first part of payments to the surrogate mother</li>
                                    <li>Selection and provision of a candidate for an oocyte donor, complete medical examination and preparation of the oocyte donor for ovulation stimulation</li>
                                    <li>Medical services IVF + ICSI with the service of a surrogate mother, donor's oocytes and the father's own sperm (medication support for the donor's ovulation stimulation, donor compensation and oocyte puncture, ICSI, embryo cultivation and verification, preparation of the SM endometrium, medical support of the surrogate mother, 1 embryo transfer)</li>
                                    <li>Cryostorage of biological material (embryos) for one year</li>
                                </ul>
                            </div>
                        )
                    }
                    <div className={classNames(classes['title'], accordionBlock.includes('stage-2') && classes['open'])} onClick={() => showAccordionBlock('stage-2')}>
                        <p>Stage 2 - Surrogate mother supervision (after surrogate mother arrival to TRNC)</p>
                        <Icon id='icon-chevron-down' />
                    </div>
                    {
                        accordionBlock.includes('stage-2') && (
                            <div className={classes['info']}>
                                <ul>
                                    <li>Providing a personal coordinator for the surrogate mother in the territory of the TRNC from the moment she enters the program (support at all stages of the program, consultation on general issues: supermarket, bank, transport)</li>
                                    <li>Providing the surrogate mother with a furnished apartment with a private bathroom from the beginning of the program during her stay in TRNC</li>
                                    <li>Accompanying support of the SM before the confirmation of the pregnancy (first pregnancy test (urine and blood analysis) 2 weeks after embryo transfer to the surrogate mother's uterus. Second pregnancy test (blood test and ultrasound to confirm the implantation of embryos in the uterus). Conducted 3 weeks after embryo transfer</li>
                                </ul>
                            </div>
                        )
                    }
                    <div className={classNames(classes['title'], accordionBlock.includes('stage-3') && classes['open'])} onClick={() => showAccordionBlock('stage-3')}>
                        <p>Stage 3 - Accompanying pregnancy before leaving for childbirth (until 24 - 26 weeks of pregnancy)</p>
                        <Icon id='icon-chevron-down' />
                    </div>
                    {
                        accordionBlock.includes('stage-3') && (
                            <div className={classes['info']}>
                                <p className={classes['bold']}>Paid on the 7th week of pregnancy</p>
                                <ul>
                                    <li>Comprehensive medical control with medication support for the surrogate mother during gestation</li>
                                    <li>Registration and pregnancy care (all necessary medical examinations and medicines, observation by doctors, pregnancy screenings and documentary support, including expenses for force majeure situations such as: premature birth, hospitalization of the surrogate mother if it is necessary to maintain pregnancy (on an outpatient basis or in a hospital setting) and any other medical unforeseen situations with the health of the surrogate mother are undertaken by the clinic</li>
                                    <li>Providing the surrogate mother with an apartment and a personal coordinator until 24 - 26 weeks of pregnancy</li>
                                    <li>Accompanying the surrogate mother during the program, monthly payments to SM</li>
                                    <li>The second part of SM compensation for carrying pregnancy and childbirth</li>
                                    <li>Organization of departure of the surrogate mother for childbirth at 24-26 weeks of pregnancy</li>
                                </ul>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className={classes['important-info']}>
                <p className={classes['title']}>This package does not include the following services:</p>
                <ul>
                    <li>сryopreservation of embryos + 1000 Euro</li>
                    <li>this package provides an additional option for a second attempt to transfer your cryo-embryos (cryoprotocol) and additional accommodation and coordination of surrogate mother for second attempt 4600 Euro</li>
                    <li><b>PGD</b> (13, 18, 21, X, Y chromosomes) preimplantation genetic diagnosis of embryos with <b>gender selection</b> + 1000 Euro</li>
                    <li><b>twins:</b></li>
                    <ul>
                        <li>+ additional financial compensation for surrogate mother + 2000 Euro</li>
                        <li>+ additional medical support for surrogate mother +1500 Euro</li>
                        <li>+ legal support for second child + 1750 Euro</li>
                    </ul>
                    <li>cost of a ticket for the surrogate mother to childbirth country and back home</li>
                    <li>visa costs for SM</li>
                    <li>cost of your visit to the clinic (tickets, transfer and accommodation)</li>
                    <li>monitoring of pregnancy from the 26th week to the birth</li>
                </ul>
            </div>
            <Childbirth />
        </div>
    );
}

export default StandartProgram;
