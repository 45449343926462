import React, { memo} from 'react';
import classNames from 'classnames';
import classes from './style/button.module.scss';


function Button({ loading = false, children, onClick, type = 'button', className = '', addClasses = [], disabled }) {
  let buttonClasses = addClasses.map((item) => {
    return classes[`${item}`];
  });
  buttonClasses.push(classes.button);
  if (loading) buttonClasses.push(classes['button-loading']);

  const onClickHandle = (event) => {
    onClick && onClick(event);
  }

  return (
    <button type={type} onClick={onClickHandle} className={classNames(buttonClasses, className, disabled ? classes['disabled'] : '')}>
      {loading ? <div className='loading'><span className="loader-1 small"> </span></div> : null}
      {children ? <>{children}</> : null}
    </button>
  );
}

export default memo(Button);
