import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchDonorsRequest } from "../../../actions/donorActions";

import Icon from "../../../ui/Icon";
import Filter from "../../../components/Filter";
import GalleryFilterHeader from "../../../components/GalleryFilterHeader/GalleryFilterHeader";
import LadyCard from "../../../components/LadyCard/LadyCard";

import classes from "../style/ladies.module.scss";
import classNames from "classnames";
import Pagination from "../../../components/Pagination";
import { isMobile } from "react-device-detect";
import PageWrap from "../../../app/components/PageWrap";

const DonorsGalery = () => {
    const dispatch = useDispatch();
    const rootRef = useRef(null);
    const [mobFilterShowing, setMobFilterShowing] = useState(false);
    const [showEmptyState, setShowEmptyState] = useState(false);
    const [donorsCount, setDonorsCount] = useState(0);
    const [donors, setDonors] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [comparedDonors, setComparedDonors] = useState([]);
    const eggsList = useSelector((state) => state.donors.eggsList);
    const filteredData = useSelector((state) => state.donors.filterResponse);
    const surrogacyList = useSelector((state) => state.donors.surrogacyList);
    const spermList = useSelector((state) => state.donors.spermList);

    const { donorsType, pageNumber } = useParams();

    const [activeTab, setActiveTab] = useState({
        title: "Egg donors",
        label: "eggs",
        show: true
    });

    useEffect(() => {
        if(filteredData && filteredData.count == 0){
            setShowEmptyState(true)
        }else{
            setShowEmptyState(false)
        }
    }, [filteredData]);

    useEffect(() => {
        if(spermList){
            setDonors(spermList);
            setDonorsCount(spermList.count)
        }
        if(eggsList){
            setDonors(eggsList)
            setDonorsCount(eggsList.count)
        }
        if(surrogacyList){
            setDonors(surrogacyList)
            setDonorsCount(surrogacyList.count)
        }
        if(filteredData){
            setDonors(filteredData)
            setDonorsCount(filteredData.count)
        }
    }, [eggsList, surrogacyList, spermList, filteredData]);

    useEffect(() => {
        if (!localStorage.getItem("compared")) {
            localStorage.setItem("compared", JSON.stringify([]));
            setComparedDonors(JSON.parse(localStorage.getItem("compared")));
        } else {
            setComparedDonors(JSON.parse(localStorage.getItem("compared")));
        }
        dispatch(fetchDonorsRequest(1, 9, "DESC", activeTab.label));
    }, []);

    useEffect(() => {
        if (pageNumber) {
            dispatch(fetchDonorsRequest(pageNumber, 9, "DESC", activeTab.label));
        }
    }, [pageNumber]);

    const mainPageTabs = [
        {
            title: "Egg donors",
            label: "eggs",
            show: true
        },
        {
            title: "Surrogate mothers",
            label: "surrogacy",
            show: true
        },
        {
            title: "Sperm donors",
            label: "sperm",
            show: true
        },
    ];

    const showMobileFilter = () => {
        setMobFilterShowing(!mobFilterShowing);
    };

    useEffect(() => {
        if (searchString) {
            dispatch(fetchDonorsRequest(1, 9, "DESC", `search=${searchString}`));
        }
        setSearchString(null);
    }, [searchString]);

    const onPageChange = (page) => {
        dispatch(fetchDonorsRequest(page, 9, "DESC", activeTab?.label));
    };

    const onCompareClick = (event) => {
        const saved = JSON.parse(localStorage.getItem("compared"));
        var index = saved.indexOf(event);
        if (index !== -1) {
            saved.splice(index, 1);
            localStorage.setItem("compared", JSON.stringify(saved));
        } else {
            localStorage.setItem("compared", JSON.stringify([saved[1], event]));
        }
        setComparedDonors(Object.values([]));
        console.log("compared", comparedDonors);
    };

    const onLadiesSearch = (searchString) => {
        dispatch(fetchDonorsRequest(1, 9, "DESC", activeTab.label, searchString,));
        if(isMobile && mobFilterShowing){
            setMobFilterShowing(false);
        }
    };

    const onClearSearch = () => {
        dispatch(fetchDonorsRequest(1, 9, "DESC", activeTab.label));
        if(isMobile && mobFilterShowing){
            setMobFilterShowing(false);
        }
    };
    
    const onChangeTab = (tab) => {
        setActiveTab(tab);
        dispatch(fetchDonorsRequest(1, 9, "DESC", tab.label));
    };

    return (
            <section className={classes["search"]} ref={rootRef}>
                <div className={classNames("container", "row")}>
                    <div
                        className={classNames(
                            classes["search_filters"],
                            mobFilterShowing ? "" : classes["filter-display-none"]
                        )}
                    >
                        <div className={classes["search_filter_header"]}>
                            <span>
                                Filters
                                {/* <i>16285 results</i> */}
                            </span>
                            {/* <Button addClasses={['link-button']} className={classes["search_filter_clear_button"]} onClick={clearFilter}>Clear</Button> */}
                            <div className={classes["close-filter"]} onClick={showMobileFilter}>
                                <Icon
                                    id="icon-x"
                                    className={classNames(classes["close_filters"], "icon-x")}
                                />
                            </div>
                        </div>
                        <Filter onLadiesSearch={onLadiesSearch} onClearSearch={onClearSearch} />
                    </div>
                    <div className={classes["search_main"]}>
                        <GalleryFilterHeader
                            tabsList={mainPageTabs}
                            activeTab={activeTab}
                            onChangeTab={onChangeTab}
                            showMobileFilter={showMobileFilter}
                        />
                        {/* <LadiesSearch onSearchButtonClick={handleSearchButtonClick} /> */}
                        {
                            showEmptyState ? (
                                <div className={classes["empty-donors"]}>
                                    <img src="/cdn/images/error.svg" alt="" />
                                    <h2>No Results Found</h2>
                                    <p>Sorry, we couldn't find any donors that match your criteria. <br />Please try adjusting your filters and try again.</p>
                                </div>
                            ) : (
                                <div className={classes["search_main_cards"]}>
                                    {donors && donors.donors?.length
                                        ? donors.donors.map((item) => {
                                            return (
                                                <LadyCard
                                                    key={item.id}
                                                    lady={item}
                                                    compare={onCompareClick}
                                                    isCompared={comparedDonors}
                                                />
                                            );
                                        })
                                        : (
                                            <div className={classes["coming-soon"]}>
                                                <img src="/cdn/images/coming-soon.svg" alt="" />
                                                <h2>Coming Soon</h2>
                                            </div>
                                        )}
                                </div>
                            )
                        }
                        <Pagination
                            curPage={pageNumber}
                            onPageChange={onPageChange}
                            total={donorsCount ? donorsCount : null}
                            pageLink="/donors"
                        />
                    </div>
                </div>
                {
                    mobFilterShowing ? (
                        <div className="overlay" onClick={showMobileFilter} />
                    ) : null
                }
            </section>
    );
};

export default DonorsGalery;
