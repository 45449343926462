import React, { useEffect } from 'react';
import PageWrap from '../../app/components/PageWrap';

import LadyCard from '../../components/LadyCard/LadyCard';
// import Pagination from '../../Components/Pagination';

import classNames from 'classnames';
import classes from './style/likedDonorsPage.module.scss'
import NewLadiesSection from './components/NewLadiesSection';
import { Link, useNavigate } from 'react-router-dom';
import { fetchLikedDonorsRequest } from '../../actions/donorActions';
import { useDispatch, useSelector } from 'react-redux';

const LikedDonorsPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const likedDonorsList =useSelector(state => state.donors.likedDonorsList);

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const user = useSelector((state) => state.user.user);

    useEffect(() => {
        if(!userInfo || (user && !user?.isLoggedIn)){
            navigate('/login')
        }
    }, [user, userInfo]);

    useEffect(() => {
        dispatch(fetchLikedDonorsRequest(1, 9, 'DESC'));
    }, []);

    return (
        <PageWrap>
            <section className={classes["search"]}>
                <div className={classNames("container", "row")}>
                    {
                        !likedDonorsList || !likedDonorsList.length ? (
                            <div className={classes["empty"]}>
                                <p>This favourite list is currently empty.</p>
                                <p>Add a donor to favourite list by clicking <Link to="/donors">here</Link>.</p>
                            </div>
                        ) : (
                            <div className={classNames(classes["search_main"], classes['saved'])}>
                                <div className={classes["search_main_cards"]}>
                                    {likedDonorsList && likedDonorsList.map(item => (
                                        <LadyCard key={item.id} lady={item} className="card_saved" />
                                    ))}
                                </div>
                                {/* <Pagination curPage={pageNumber} onPageChange={onPageChange} total={donorsCount ? donorsCount : null} /> */}
                            </div>
                        )
                    }
                </div>
            </section>
            {/* <NewLadiesSection className="new_ladies_saved_compare" /> */}
        </PageWrap>
    );
}

export default LikedDonorsPage;
