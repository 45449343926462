import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import {
    deleteConversationRequest,
    fetchEmailsRequest,
    fetchMessagesForEmailRequest,
    fetchStarRequest,
    updateEmailNotificationRequest,
} from "../../../actions/emailActions";
import { convertDatetime } from "../../../utils/commonutils";
import { SocketContext } from "../../../context/socket";

import Icon from "../../../ui/Icon";
import MailboxReply from "./MailboxReply";

import classes from "../style/mailboxReadLetter.module.scss";
import classNames from "classnames";
import { hideMenu, showMenu } from "../../../actions/globalActions";

const MailboxReadLetter = ({ mailId, currentPage }) => {
    const user = useSelector((state) => state.user.user);
    const emails = useSelector((state) => state.emails.emails);
    const reduxMessages = useSelector((state) => state.emails.messages);
    const deletedMessages = useSelector((state) => state.emails.emailsDeleted);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lettersList = useRef();
    const socket = useContext(SocketContext);
    const [showReply, setShowReply] = useState(false);
    const [latestMessage, setLatestMessage] = useState(null);
    const [senderLetterInfo, setSenderLetterInfo] = useState(null);
    const [isDeleted, setIsDeleted] = useState(false);
    const [starredEmails, setStarredEmails] = useState([]);

    useEffect(() => {
        if(deletedMessages && deletedMessages.find(item => item.mailId == mailId)){
            setIsDeleted(true)
        }
    }, [deletedMessages]);

    useEffect(() => {
        dispatch(hideMenu());
        return () => {
            dispatch(showMenu());
        };
      }, []);

    const handleMessage = useCallback((mes) => {
        console.log("MailboxReadLetter mes", mes);
    }, []);

    useEffect(() => {
        if(user){
            socket.emit("join chat", mailId);
    
            socket.on("new message", (mes) => handleMessage(mes));
        }

        return () => {
            socket.off("new message");
        };
    }, [socket, dispatch, user]);

    const handleNotification = useCallback(() => {
        dispatch(fetchMessagesForEmailRequest(1, 10, mailId));
    }, []);

    useEffect(() => {
        if(user){
            socket.on("notification", (mes) => handleNotification(mes));
        }

        return () => {
            socket.off("notification");
        };
    }, [socket, dispatch, user]);

    useEffect(() => {
        if(mailId){
            dispatch(fetchMessagesForEmailRequest(1, 10, mailId));
            dispatch(updateEmailNotificationRequest([parseInt(mailId)]));
        }
    }, [mailId]);

    useEffect(() => {
        if(emails){
            const emailObject = emails.find(email => email.mailId === parseInt(mailId));
            const senderName = emailObject ? emailObject.sender.name : null;
            setSenderLetterInfo({
                id: mailId,
                name: senderName
            })
        }
    }, [emails]);

    useEffect(() => {
        if(reduxMessages){
            setLatestMessage(reduxMessages.messages[0])
        }
    }, [reduxMessages]);

    useEffect(() => {
        if (lettersList) {
            lettersList.current.scrollTop = lettersList.current.scrollHeight;
        }
    }, []);

    const onShowReply = () => {
        setShowReply(!showReply);
    };

    const backToList = () => {
        navigate("/mailbox");
        dispatch(fetchEmailsRequest({page: currentPage, limit:10}));
    };

    const handleMessageInFav = async (mailId) => {
        dispatch(
            fetchStarRequest(mailId, (response, error) => {
                if (response) {
                    setStarredEmails((prevFavs) => {
                        const isAlreadyFav = prevFavs.includes(mailId);
                        if (isAlreadyFav) {
                            return prevFavs.filter((id) => id !== mailId);
                        } else {
                            return [...prevFavs, mailId];
                            
                        }
                    });
                    dispatch(fetchMessagesForEmailRequest(1, 10, mailId));
                } else {
                    console.error("Failed to send email:", error);
                }
            })
        );
    };

    const deleteMessage = async () => {
        try {
          await dispatch(deleteConversationRequest([mailId], (response, error) => {
            if (response) {
                navigate('/mailbox');
            }else {
                console.error("Failed to delete email:", error);
            }
          }));
        } catch (error) {
          console.log('Delete message error', error);
        }
    };

    return (
        <div className={classes["email_letters_list"]}>
            <div className={classes["email_letters_list_header"]}>
                <div className={classes["email_letters_list_header_left_block"]}>
                    <div
                        className={classNames(classes["image"], classes["close_email"])}
                        onClick={backToList}
                    >
                        <Icon id="icon-arrow-left" className="icon-arrow-left" />
                    </div>
                    <span>{reduxMessages?.subject}</span>
                </div>
                <div className={classes["email_letters_list_header_right_block"]}>
                    <div className={classes["image"]} onClick={() => handleMessageInFav(mailId)}>
                        {reduxMessages?.isStarred || starredEmails.includes(mailId) ? (
                            <Icon
                                className="icon-star-checked"
                                id="icon-star-checked"
                            />
                        ) : (
                            <Icon
                                className="icon-starred"
                                id="icon-starred"
                            />
                        )}
                    </div>
                    <div className={classes["image"]} onClick={deleteMessage}>
                        <Icon id="icon-trash" className="icon-trash" />
                    </div>
                </div>
            </div>
            {!showReply ? (
                <>
                    <div className={classes["email_letters_list_area"]} ref={lettersList}>
                        {reduxMessages &&
                            reduxMessages.messages.map((message, index) => {
                                return (
                                    <div key={index} className={classes["email_letters_list_letter"]}>
                                        <div
                                            className={classes["email_letters_list_letter_header"]}
                                        >
                                            <div
                                                className={
                                                    classes["email_letters_list_letter_header_personal"]
                                                }
                                            >
                                                {message.sender.photo ? (
                                                    <div className={classNames(classes["avatar"], "avatar")}>
                                                        <img src={message.sender.photo} alt="" />
                                                    </div>
                                                ) : (
                                                    <div className={classNames(classes["avatar"], "avatar")}>
                                                        {`${message.sender.name.charAt(0)}`}
                                                    </div>
                                                )}
                                                <div className={classes["name_block"]}>
                                                    <span className={classes["name"]}>
                                                        {message.sender.name}
                                                    </span>
                                                    {isMobile && (
                                                        <span className={classes["date"]}>
                                                            {convertDatetime(message.date)}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className={classes["email_letters_list_letter_date"]}
                                            >
                                                {!isMobile && (
                                                    <span>{convertDatetime(message.date)}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className={classes["email_letters_list_letter_body"]}>
                                            <p>{message.text}</p>
                                        </div>
                                        {
                                            message.withAttachment && (
                                                <div className={classes["emails_letter_attachments"]}>
                                                    <div className={classes["image"]}>
                                                        <Icon id="icon-paperclip" className="icon-paperclip" />
                                                    </div>
                                                    <span>2 Attachments</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                );
                            })}
                    </div>
                    {
                        !isDeleted && (
                            <div className={classes["email_letters_list_footer"]}>
                                <span>Click here to</span>
                                <p className={classes["reply-button"]} onClick={onShowReply}>
                                    Reply
                                </p>
                            </div>
                        )
                    }
                </>
            ) : (
                <MailboxReply onShowReply={onShowReply} mailId={mailId} latestMessage={latestMessage} senderLetterInfo={senderLetterInfo} />
            )}
        </div>
    );
};

export default MailboxReadLetter;
