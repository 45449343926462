import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_COINS_PACKAGES_REQUEST,
  GET_DONOR_PACKAGES_REQUEST,
  HIDE_MENU,
  SEND_CONVERSATION_REQUEST,
  SEND_ORDER_DONOR_REQUEST,
  SEND_PAYMENTS_REQUEST,
  SHOW_MENU,
} from "../constants/actionTypes";
import instance from "../api/api";
import {
  getCoinsPackagesFailure,
  getCoinsPackagesSuccess,
  getDonorPackagesFailure,
  getDonorPackagesSuccess,
  handleConversationError,
  receiveConversationResponse,
  sendOrderDonorFailure,
  sendOrderDonorSuccess,
  sendPaymentsFailure,
  sendPaymentsSuccess,
} from "../actions/globalActions";

function* watchHideMenu() {
  yield takeLatest(HIDE_MENU, hideMenuSaga);
}

function* watchShowMenu() {
  yield takeLatest(SHOW_MENU, showMenuSaga);
}

function* handleConversationRequest(action) {
  try {
    const response = yield call(() =>
      instance.post("conversation", action.payload)
    );
    yield put(receiveConversationResponse(response.data));
    if (action.callback) {
      action.callback(response.data);
    }
  } catch (error) {
    yield put(handleConversationError(error));
    // console.log(error);
    if (action.callback) {
      action.callback(error);
    }
  }
}

function* getCoinsPackages() {
  try {
    const response = yield call(instance.get, `payments/get-all-user-packages`);
    yield put(getCoinsPackagesSuccess(response.data));
  } catch (error) {
    yield put(getCoinsPackagesFailure(error));
  }
}

function* sendPayments(action) {
  try {
    const response = yield call(() =>
      instance.post("payments/create", action.payload)
    );
    yield put(sendPaymentsSuccess(response.data));
    if (action.callback) {
      action.callback(response.data);
    }
  } catch (error) {
    yield put(sendPaymentsFailure(error));
    if (action.callback) {
      action.callback(null, error);
    }
  }
}

function* getDonorPackages() {
  try {
    const response = yield call(instance.get, `payments/get-all-user-donor-packages`);
    yield put(getDonorPackagesSuccess(response.data));
  } catch (error) {
    yield put(getDonorPackagesFailure(error));
  }
}

function* sendOrderDonor(action) {
  try {
    const response = yield call(() =>
      instance.post("payments/orderDonorPackage", action.payload)
    );
    yield put(sendOrderDonorSuccess(response.data));
    if (action.callback) {
      action.callback(response.data);
    }
  } catch (error) {
    yield put(sendOrderDonorFailure(error));
    if (action.callback) {
      action.callback(null, error);
    }
  }
}

export default function* globalSaga() {
  yield [watchHideMenu(), watchShowMenu()];
  yield takeLatest(SEND_CONVERSATION_REQUEST, handleConversationRequest);
  yield takeLatest(GET_COINS_PACKAGES_REQUEST, getCoinsPackages);
  yield takeLatest(GET_DONOR_PACKAGES_REQUEST, getDonorPackages);
  yield takeLatest(SEND_PAYMENTS_REQUEST, sendPayments);
  yield takeLatest(SEND_ORDER_DONOR_REQUEST, sendOrderDonor);
}
