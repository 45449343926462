import React from 'react'

import classes from './style/programStagesPage.module.scss'
import PageWrap from '../../../../app/components/PageWrap'
import classNames from 'classnames'

export default function ProgramStagesPage() {
    return (
        <PageWrap>
            <div className='container'>
                <section className={classes['quick-start-page']}>
                    <div className={classes['welcome-block']}>
                        <div className={classes['welcome-text']}>
                            <h1>Program Stages</h1>
                            <h2>How does the surrogacy process work? Response from Surrogacy Platform</h2>
                            <p>A step-by-step breakdown of the Surrogacy Program. All about Preparation of documents, Medical examination, stages of Payment and childbirth will be explained by Surrogacy Platform</p>
                        </div>
                        <div className={classes['welcome-banner']}>
                            <img src="/cdn/images/program-stages.png"  />
                        </div>
                    </div>
                    <div className={classes['stages-block']}>
                        <h3>How does it work?</h3>
                        <div className={classes['grid']}>
                            <div className={classes['column']}>
                                <div className={classes['item']}>
                                    <div className={classes['number']}>1</div>
                                    <p>Once you have selected a suitable egg donor, click on the "Order donor" button.</p>
                                </div>
                                <div className={classes['item']}>
                                    <div className={classes['number']}>2</div>
                                    <p>Select the Service Package that best suits you.</p>
                                </div>
                                <div className={classes['item']}>
                                    <div className={classes['number']}>3</div>
                                    <p>Click on the "Order donor" button and make the first payment (optional or after signing the contract).</p>
                                </div>
                                <div className={classes['item']}>
                                    <div className={classes['number']}>4</div>
                                    <p>The next step is a consultation with our doctor and a medical examination before starting the program</p>
                                </div>
                                <div className={classes['item']}>
                                    <div className={classes['number']}>5</div>
                                    <p>Sign the contract with our medical center in Cyprus and make the second payment.</p>
                                </div>
                            </div>
                            <div className={classes['column']}>
                                <div className={classes['item']}>
                                    <div className={classes['number']}>6</div>
                                    <p>Next We will provide you with a professional surrogate and start preparing the egg donor and surrogate for the fertilization program.</p>
                                </div>
                                <div className={classes['item']}>
                                    <div className={classes['number']}>7</div>
                                    <p>Between 7-8 weeks of pregnancy, a First Trimester Screening takes place</p>
                                </div>
                                <div className={classes['item']}>
                                    <div className={classes['number']}>8</div>
                                    <p>Medical and documentary support for the surrogate mother. All necessary medical examinations and medications, doctor's supervision, pregnancy examinations, pregnancy medical records and documentary support.</p>
                                </div>
                                <div className={classes['item']}>
                                    <div className={classes['number']}>9</div>
                                    <p>The arrival of the genetic parents to the surrogate or the arrival of the surrogate to childbirth to you. Depending on which package of services you choose</p>
                                </div>
                                <div className={classes['item']}>
                                    <div className={classes['number']}>10</div>
                                    <p>Registration of the newborn and issuance of documents</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={classes['thnx-section']}>
                    <div className={classes['thnx-block']}>
                        <div className={classes['left-block']}>
                            <p className={classes['title']}>From the very beginning, we will assist you, provide comfort and support. </p>
                            <p className={classNames(classes['title'], classes['color'])}>It is important to us that you are completely satisfied!  </p>
                        </div>
                        <div className={classes['right-block']}>
                            <p>That is why we implement the most effective programs and methods to make your journey to parenthood easy and with a smile.</p>
                            <p>Using Surrogacy Platform services, you can be sure of the quality of work done. We are confident that through constant communication and professionalism we will succeed together!</p>
                        </div>
                    </div>
                </section>
            </div>
        </PageWrap>
    )
}
