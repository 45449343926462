import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import PageWrap from "../../app/components/PageWrap";
import Icon from '../../ui/Icon';
import Support from './components/Support';
import General from './components/General';
import ChangePassword from './components/ChangePassword';
import Notification from './components/Notification';
import Information from './components/Information';

import classes from './style/settingsPage.module.scss'
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const SettingsPage = () => {
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState('general');
    const [showMobileTabsList, setShowMobileTabsList] = useState(false);

    const { path } = useParams();

    const user = useSelector((state) => state.user.user);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    useEffect(() => {
        if(!userInfo || (user && !user?.isLoggedIn)){
            navigate('/login')
        }
    }, [user, userInfo]);

    const tabsList = [
        {
            name: "general",
            title: "General",
            content: <General />,
            icon: "icon-settings-user",
            link: '/settings'
        },
        {
            name: "password",
            title: "Change Password",
            content: <ChangePassword />,
            icon: "icon-settings-lock",
            link: '/settings/password'
        },
        // {
        //     name: "information",
        //     title: "Information",
        //     content: <Information />,
        //     icon: "icon-settings-info",
        //     link: '/settings/information'
        // },
        {
            name: "support",
            title: "Support",
            content: <Support />,
            icon: "icon-settings-headphones",
            link: '/settings/support'
        },
        // {
        //     name: "notifications",
        //     title: "Notifications",
        //     content:( <Notification />),
        //     icon: "icon-settings-bell",
        //     link: '/settings/notifications'
        // },
    ]
    useEffect(() => {
        if(path){
            onChangeTab(path)
        }else {
            onChangeTab('general')
        }
    }, [path]);

    const onChangeTab = (tab) => {
        setActiveTab(tab)
        if(showMobileTabsList){
            setShowMobileTabsList(!showMobileTabsList)
        }
    }

    const getTabContent = () => {
        const activeTabContent = tabsList.find(tab => tab.name === activeTab);
        return activeTabContent ? activeTabContent.content : null
    }

    const onShowMobileTabsList = () => {
        setShowMobileTabsList(!showMobileTabsList)
    }

    const getMobileSelectContent = () => {
        const mobileSelectContent = tabsList.find(tab => tab.name === activeTab);
        return mobileSelectContent ? (
            <>
                <div className={classes["image"]}>
                    <Icon id={mobileSelectContent.icon} className={mobileSelectContent.icon} />
                </div>
                <span>{mobileSelectContent.title}</span>
            </>
        ) : null
    }

    return (
      <PageWrap>
            <section className={classes["settings"]}>
                <div className="container">
                    <div className={classes["settings_block"]}>
                        <ul className={classes["settings_menu"]}>
                            {
                                tabsList.map((item, index) => (
                                    <Link to={item.link} key={index} className={classNames(activeTab === item.name && classes["selected"])} onClick={() => onChangeTab(item.name)}>
                                        <div className={classes["image"]}>
                                            <Icon className={item.icon} id={item.icon} />
                                        </div>
                                        <span>{item.title}</span>
                                    </Link>
                                ))
                            }
                        </ul>
                        <div className={classes["settings_main_block"]}>
                            <div className={classes["settings_mobile_menu"]}>
                                <div className={classes["settings_select"]} onClick={onShowMobileTabsList}>
                                    {getMobileSelectContent()}
                                    <div className={classes["image"]}>
                                        <Icon id="icon-chevron-down-small" className="icon-chevron-down-small" />
                                    </div>
                                </div>
                                <ul className={classNames(classes["settings_list"], !showMobileTabsList && 'display-none')}>
                                    {
                                        tabsList.map((item, index) => (
                                            <li key={index} onClick={() => onChangeTab(item.name)}>
                                                <div className={classes["image"]}>
                                                    <Icon className={item.icon} id={item.icon} />
                                                </div>
                                                <span>{item.title}</span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className={classes["settings_pages"]}>
                                <div className={classes["settings_page"]}>
                                    {getTabContent()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      </PageWrap>
    );
}

export default SettingsPage;
