import React from 'react';
import { Link } from 'react-router-dom';

import PageWrap from '../../../../app/components/PageWrap';

import classes from '../../style/staticPages.module.scss'

const EggDonorsTripsPage = () => {
    return (
        <PageWrap>
            <div className='container'>
                <section className={classes['static-pages-contaniner']}>
                    <h1>Journey of a Donor to the Clinic</h1>
                    <h2>How the Process Works</h2>
                    <p>When you choose an egg donor for your surrogacy, we strive to make the process as comfortable and safe as possible for all participants. Here’s how the donor’s journey to the clinic unfolds and what procedures they can expect.</p>

                    <h2>Step 1: Preparing for the Trip</h2>
                    <p>We handle all aspects of the donor’s trip, including purchasing airline tickets, arranging transportation, and securing accommodations. Our specialists take care of every detail so the donor can focus on their health and comfort.</p>

                    <h2>Step 2: Arrival at the Clinic</h2>
                    <p>Upon arrival, the donor is greeted by our coordinator, who will accompany them throughout the process. On the first day, the donor can expect:</p>

                    <h3>Consultation with a Doctor</h3>
                    <p>The doctor will conduct a thorough medical examination and discuss all the details of the procedure.</p>

                    <h3>Tests and Examinations</h3>
                    <p>Necessary blood tests and an ultrasound will be performed to confirm the donor’s health.</p>

                    <h3>Consultation with a Psychologist</h3>
                    <p>We provide psychological support to ensure the donor feels confident and at ease.</p>

                    <h2>Step 3: Preparing for the Procedure</h2>
                    <p>Over the course of several days, the donor undergoes hormonal stimulation under the close supervision of our medical staff. The doctor regularly examines the donor to monitor the process.</p>

                    <h2>Step 4: Egg Retrieval Procedure</h2>
                    <p>When the eggs are ready for retrieval, we take care of the donor at every step:</p>

                    <h3>Anesthesia</h3>
                    <p>The egg retrieval procedure is performed under mild anesthesia for maximum comfort and safety.</p>

                    <h3>Egg Retrieval</h3>
                    <p>The procedure takes about 20-30 minutes, after which the donor is monitored by the doctor for a short period.</p>

                    <h2>Step 5: Recovery and Return Home</h2>
                    <p>After the procedure, the donor is given time to rest and recover in a cozy and comfortable environment. Our specialists provide all necessary medical care and support until the donor is fully recovered. Then, we arrange for the return trip home, including compensation for services, transfer, and airline tickets.</p>

                    <h2>Support at Every Stage</h2>
                    <p>We understand the importance of the egg donor’s role and provide comprehensive support at every stage of the process. Our team is always ready to answer questions and provide the necessary assistance to ensure the donor feels confident and comfortable.</p>


                </section>
            </div>
        </PageWrap>
    );
}

export default EggDonorsTripsPage;
