import React, { useEffect } from 'react';
import PageWrap from '../../app/components/PageWrap';

import classes from './style/packagesPage.module.scss'
import Button from '../../ui/Button';
import Icon from '../../ui/Icon';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { getCoinsPackagesRequest, sendPaymentsRequest } from '../../actions/globalActions';
import { useNavigate } from 'react-router-dom';

const PackagesPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const user = useSelector((state) => state.user.user);
    const coinsPacks = useSelector((state) => state.globalState.coinsPacks);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    
    useEffect(() => {
        if(!coinsPacks){
            dispatch(getCoinsPackagesRequest())
        }
    }, [coinsPacks]);

    const createPayment = async (pack) => {
        if(!userInfo || (user && !user.isLoggedIn)){
            navigate('/login')
        }else {
            let data = {
                coinPackageId: pack.id,
                amount: pack.price,
                success_url: window.location.href
            }
            dispatch(
                sendPaymentsRequest(data, (response, error) => {
                    if (response) {
                        window.open(response, '_self');
                    } else {
                        console.error("Error order coins payment:", error);
                    }
                })
            );
        }

    };

    return (
        <PageWrap>
            <section className={classes['packages-page']}>
                <div className='container'>
                    <p className={classes['packages-title']}>Top up your balance</p>
                    <div className={classes['packages-container']}>
                        {
                            isMobile ? (
                                coinsPacks && coinsPacks.map((item, index) => (
                                    <div key={`coins-${index}`} className={classes['packages-item']}>
                                        <div className={classes['col']}>
                                            <p className={classes['name']}>{item.name}</p>
                                            <div className={classes['info']}>
                                                <p><Icon id="icon-ok-round" />{item.emails} emails</p>
                                                <p><Icon id="icon-ok-round" />{item.textChats} chats</p>
                                                <p><Icon id="icon-ok-round" />{item.videoChats} videochat mins</p>
                                            </div>
                                        </div>
                                        <div className={classes['col']}>
                                            <div>
                                                <p className={classes['price']}><sup>€</sup>{item.price}</p>
                                                <p className={classes['coins']}>{item.count} coins</p>
                                            </div>
                                            <Button addClasses={['capitalize', 'relief']} onClick={()=>createPayment(item)}>Top up balance</Button>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                coinsPacks && coinsPacks.map((item, index) => (
                                    <div key={`coins-${index}`} className={classes['packages-item']}>
                                        <p className={classes['name']}>{item.name}</p>
                                        <p className={classes['price']}><sup>€</sup>{item.price}</p>
                                        <p className={classes['coins']}>{item.count} coins</p>
                                        <div className={classes['info']}>
                                            <p><Icon id="icon-ok-round" />{item.count/10} emails</p>
                                            <p><Icon id="icon-ok-round" />{item.count/20} chats</p>
                                            <p><Icon id="icon-ok-round" />{item.count/100} video chat min</p>
                                        </div>
                                        <Button addClasses={['capitalize', 'relief']} onClick={()=>createPayment(item)}>Top up balance</Button>
                                    </div>
                                ))
                            )
                        }
                    </div>
                    <div className={classes['important-info']}>
                        <ul>
                            <li>1. Once you have selected a suitable egg donor, click on the "Order" button. </li>
                            <li>2. Select the Service Package that best suits you.</li>
                            <li>3. Click on the "Order" button and make the first payment.</li>
                            <li>4. The next step is a consultation with our doctor and a medical examination before starting the program</li>
                            <li>5. Sign the contract with our medical center in North Cyprus and make the second payment.</li>
                            <li>6. Next We will provide you with a professional surrogate and start preparing the egg donor and surrogate for the fertilization program.</li>
                            <li>7. Between 11 and 13 weeks of pregnancy, a First Trimester Screening takes place</li>
                            <li>8. Medical and documentary support for the surrogate mother. All necessary medical examinations and medications, doctor's supervision, pregnancy examinations, pregnancy medical records and documentary support.</li>
                            <li>9. The arrival of the genetic parents to the surrogate or the arrival of the surrogate to childbirth to you. Depending on which package of services you choose</li>
                            <li>10. Registration of the newborn and issuance of documents</li>
                        </ul>
                        <p>From the very beginning, we will assist you, provide comfort and support. It is important to us that you are completely satisfied! That is why we implement the most effective programs and methods to make your journey to parenthood easy and with a smile.</p>
                        <p>Using Surrogacy Platform services, you can be sure of the quality of work done. We are confident that through constant communication and professionalism we will succeed together!</p>
                    </div>
                </div>
            </section>
        </PageWrap>
    );
}

export default PackagesPage;
