import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { SocketContext } from "../../context/socket";
import Notifications from "../../components/Notifications";
import {
    fetchEmailsRequest,
    getEmailsDeletedRequest,
    getEmailsSentRequest,
    getEmailsStarredRequest,
    selectDonorForEmail,
} from "../../actions/emailActions";

import PageWrap from "../../app/components/PageWrap";
import MailboxLeftMenu from "./compoments/MailboxLeftMenu";
import MailboxLettersList from "./compoments/MailboxLettersList";
import MailboxReadLetter from "./compoments/MailboxReadLetter";
import ComposeLetter from "../../components/ComposeLetter";

import classes from "./style/mailbox.module.scss";
import EmptyChat from "../../components/EmptyChat";
import classNames from "classnames";
import { isAndroid, isIOS, isMobile, isTablet } from "react-device-detect";
import Modal from "../../components/Modal";
import { fetchPopup } from "../../actions/modalActions";

const Mailbox = () => {
    const navigate = useNavigate();
    const { box, mailId } = useParams();
    const dispatch = useDispatch();
    const emailBlockRef = useRef(null);
    const socket = useContext(SocketContext);
    const [notificationsShow, setNotificationsShow] = useState(false);
    const [notificationCount, setNotificationCount] = useState(null);
    const [showCmpose, setShowCmpose] = useState(false);
    const [activeLabel, setActiveLabel] = useState("");
    const [showMenuMobile, setShowMenuMobile] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [showReadLetter, setShowReadLetter] = useState(false);
    const selectedDonor = useSelector((state) => state.emails.selectedDonor);
    const emailsInbox = useSelector((state) => state.emails.emails);
    const emailsSent = useSelector((state) => state.emails.emailsSent);
    const deletedMessages = useSelector((state) => state.emails.emailsDeleted);
    const emailsStarred = useSelector((state) => state.emails.emailsStarred);
    const user = useSelector((state) => state.user.user);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    useEffect(() => {
        if(!userInfo || (user && !user?.isLoggedIn)){
            navigate('/login')
        }
    }, [user, userInfo]);

    useEffect(() => {
        setNotificationsShow(false)
        window.addEventListener('resize', handleResize);
        document.addEventListener('DOMContentLoaded', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('DOMContentLoaded', handleResize);
        };
    }, []);

    useEffect(() => {
        generateChatHeight();
    }, [notificationsShow]);

    const handleResize = () => {
        if (emailBlockRef.current) {
            generateChatHeight();
        }
    };

    const generateChatHeight = () => {
        const headerMenu = document.querySelector('.mobile_header_menu');
        const navBar = document.querySelector('.nav_bar');
        const notificationBlock = document.querySelector('.notification_section');
        const chatBlock = emailBlockRef.current;

        let navBarMarginTop = 0;
        let chatBlockMarginTop = 0;
        const innerHeight = window.innerHeight;
        const innerWidth = window.innerWidth;

        if (innerWidth <= 1024) {
            navBarMarginTop = 8;
        }

        if (notificationBlock && notificationBlock.classList.contains('display-none') && innerWidth > 1024) {
            chatBlockMarginTop = 30;
        }

        if (chatBlock && (headerMenu || navBar) && notificationBlock) {
            chatBlock.style.height = String(
                innerHeight - (headerMenu ? headerMenu.offsetHeight : 0) - navBar.offsetHeight - notificationBlock.offsetHeight - navBarMarginTop - chatBlockMarginTop
            ) + 'px';
        }
    };

    useEffect(() => {
        if(user){
            if(user.wallet){
                setNotificationCount(user.wallet?.amount)
            }else {
                setNotificationCount(0)
            }
            
        }
    }, [dispatch, user, socket]);

    const onClickCompose = () => {
        if ((notificationCount && notificationCount <= 10) || notificationCount  == 0) {
            onShowNotifyPopup()
        }else {
            setShowCmpose(!showCmpose);
            if(showMenuMobile){
                setShowMenuMobile(false);
            }
            if(!showCmpose){
                dispatch(selectDonorForEmail(null))
            }
        }
    };

    useEffect(() => {
        if (selectedDonor) {
            setShowCmpose(true);
        }
    }, [selectedDonor]);

    useEffect(() => {
        if ((notificationCount && notificationCount <= 10) || notificationCount  == 0) {
            setNotificationsShow(true);
        }
    }, [notificationCount]);

    const onClickLabels = (label) => {
        setActiveLabel(label);
        setShowMenuMobile(false);
    };

    const onChangeBox = (path, box) => {
        navigate(path);
        setActiveLabel("");
        setShowMenuMobile(false);
    };

    useEffect(() => {
        if (!emailsInbox) {
            dispatch(fetchEmailsRequest({page: currentPage, limit:10}));
        }
    }, [dispatch, emailsInbox]);

    useEffect(() => {
        if (!box || box == "inbox") {
            dispatch(fetchEmailsRequest({page: currentPage, limit:10}));
        } else if (box == "trash") {
            dispatch(getEmailsDeletedRequest({page: currentPage, limit:10}));
        } else if (box == "starred") {
            dispatch(getEmailsStarredRequest({page: currentPage, limit:10}));
        } else if (box == "sent") {
            dispatch(getEmailsSentRequest({page: currentPage, limit:10}));
        }
    }, [box]);

    useEffect(() => {
        if (activeLabel) {
            if (!box || box == "inbox") {
                dispatch(fetchEmailsRequest({page: currentPage, limit:10, category: activeLabel} ));
            } else if (box == "trash") {
                dispatch(getEmailsDeletedRequest({page: currentPage, limit:10, category: activeLabel} ));
            } else if (box == "starred") {
                dispatch(getEmailsStarredRequest({page: currentPage, limit:10, category: activeLabel} ));
            } else if (box == "sent") {
                dispatch(getEmailsSentRequest({page: currentPage, limit:10, category: activeLabel} ));
            }
        }
    }, [activeLabel]);

    useEffect(() => {
        if(mailId && notificationCount){
            if(notificationCount <= 10 || !notificationCount){
                onShowNotifyPopup()
            }else {
                setShowReadLetter(true)
            }
        }else {
            setShowReadLetter(false)
        }
    }, [mailId, notificationCount]);

    const onShowMenuMobile = () => {
        setShowMenuMobile(!showMenuMobile);
    };

    const onShowNotifyPopup = () => {
        dispatch(fetchPopup({
            popupType: 'CoinsModal',
            props: {
                className: "small-balance",
                text: 'Apologies, you do not have enough coins for this',
                buttonText: "Top up your balance",
                type: "small-balance",
                onTopUp: () => {
                    navigate('/coins')
                }
            },
        }))
    }

    useEffect(() => {
       if(isMobile || isTablet) {
        setShowMenuMobile(true);
       }
    }, []);

    return (
        <PageWrap>
            {notificationsShow ? <Notifications count={notificationCount} /> : null}
            <section className={classNames(classes["email"], notificationsShow && classes['emails-with-notify'])}>
                <div className="container">
                    <div className={classNames(classes["email_block"], (isMobile && isIOS) && classes['on-ios'], (isMobile && isAndroid) && classes['on-android'])} ref={emailBlockRef}>
                        {showCmpose && <ComposeLetter currentPage={currentPage} closeCompose={onClickCompose} />}
                        <MailboxLeftMenu
                            onClickLabels={onClickLabels}
                            onClickCompose={onClickCompose}
                            onChangeBox={onChangeBox}
                            activeLabel={activeLabel}
                            showMenuMobile={showMenuMobile}
                        />
                        {showReadLetter ? (
                            <MailboxReadLetter mailId={mailId} currentPage={currentPage} isDeleted={box === "trash"} />
                        ) : (
                            <MailboxLettersList
                                emailMessages={
                                    box === "trash" && deletedMessages
                                        ? deletedMessages
                                        : box === "sent" && emailsSent
                                            ? emailsSent
                                            : box === "starred" && emailsStarred
                                                ? emailsStarred
                                                : emailsInbox
                                }
                                onShowMenuMobile={onShowMenuMobile}
                                showMenuMobile={showMenuMobile}
                                isSent={box === "sent"}
                                isDeleted={box === "trash"}
                                isStarred={box === "starred"}
                                currentPage={currentPage}
                                notificationCount={notificationCount}
                                onShowNotifyPopup={onShowNotifyPopup}
                            />
                        )}
                    </div>
                </div>
            </section>
        </PageWrap>
    );
};

export default Mailbox;
