import React, { useEffect, useRef } from "react";
import { useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Icon from "../../../ui/Icon";
import Button from "../../../ui/Button";

import { ChatState } from "../../../context/chatProvider";
import { SocketContext } from "../../../context/socket";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { fetchChatsRequest, fetchMessagesRequest, fetchNotificationsRequest, fetchSupportChatsRequest, resetMessages } from "../../../actions/textChatActions";
import { hideMenu, showMenu } from "../../../actions/globalActions";

import classes from "../style/textChatRoom.module.scss";
import classNames from "classnames";

const TextChatRoom = ({chatId, isSupportChat}) => {
    const { selectedChat, setSelectedChat, setCurrentTextChat, currentTextChat } = ChatState();
    const user = useSelector((state) => state.user.user);
    const messagesById = useSelector((state) => state.textChats.messagesById);
    const textChats = useSelector((state) => state.textChats.textChatsList);
    const supportChat = useSelector((state) => state.textChats.supportChat);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const messagesListRef = useRef();
    const messagesRef = useRef();
    const messageInputRef = useRef();
    const naigate = useNavigate();
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);

    const [messagesList, setMessagesList] = useState([]);  
    const [newMessage, setNewMessage] = useState("");
    const [socketConnected, setSocketConnected] = useState(false);
    const [typing, setTyping] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [firstScroll, setFirstScroll] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [messageFile, setMessageFile] = useState(null);
    const [messageFilePreview, setMessageFilePreview] = useState(null);

    useEffect(() => {
        dispatch(hideMenu());
        return () => {
            dispatch(showMenu());
        };
    }, []);

    useEffect(() => {
        if(!supportChat) {
            dispatch(fetchSupportChatsRequest());
        }
    }, [supportChat]);

    useEffect(() => {
        if (!textChats) {
            // dispatch(fetchChatsRequest());
            dispatch(fetchNotificationsRequest());
        }
    }, [textChats]);


    const getChatById = () => {
        if(isSupportChat){
            return supportChat[0]
        }else {
            return textChats.find((chat) => chat.chatId === parseInt(chatId, 10));
        }
    };
    
    useEffect(() => {
        if (chatId && textChats && supportChat) {
            setSelectedChat(chatId);
            setCurrentTextChat(getChatById());
        } else {
            setSelectedChat(null);
            setCurrentTextChat(null);
        }
    }, [chatId, textChats, supportChat]);

    useEffect(() => {
        if (selectedChat) {
            dispatch(resetMessages(selectedChat));
            dispatch(fetchMessagesRequest(selectedChat, 1, 20));
        }
        if (currentPage > 1) {
            setCurrentPage(1);
        }
    }, [dispatch, selectedChat]);

    useEffect(() => {
        if (messagesById) {
            setMessagesList(messagesById[selectedChat]);
        }
    }, [messagesById, selectedChat]);

    useEffect(() => {
        setFirstScroll(true)
    }, [selectedChat]);

    useEffect(() => {
        if (messagesListRef && messagesListRef.current && firstScroll) {
            const messagesContainer = messagesListRef.current;
            const lastMessage = messagesContainer.lastChild;

            if (lastMessage) {
                lastMessage.scrollIntoView({ behavior: "smooth" });
                setTimeout(() => {
                    setFirstScroll(false);
                }, 500);
            }
        }
    }, [messagesList, selectedChat, firstScroll]);
    
    const backToChatList = () => {
        naigate("/textchat/");
    };
    
    const handleKeyDown = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.preventDefault();
            sendMessage();
        }
    };
    
    const sortedMessages = messagesList && messagesList.messages ? [...messagesList.messages].sort((a, b) => new Date(a.date) - new Date(b.date)) : [];
    
    const groupedMessages = sortedMessages.reduce((result, message) => {
        const date = new Date(message.date).toLocaleDateString();
        if (!result[date]) {
            result[date] = [];
        }
        result[date].push(message);
        return result;
    }, {});
    
    const messageDates = Object.keys(groupedMessages);
    
    const today = new Date().toLocaleDateString();
    const yesterday = new Date(Date.now() - 86400000).toLocaleDateString();

    const typingHandler = (e) => {
        setNewMessage(e.target.value);

        if (!socketConnected) return;

        if (!typing) {
            setTyping(true);
            socket.emit("typing", currentTextChat.chatId);
        }
    };

    const addFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            const fileUrl = URL.createObjectURL(file);
            setMessageFilePreview(fileUrl)
            setMessageFile(file)
            reader.readAsDataURL(file);
        }
    }

    const handleInviteAccepted = useCallback((mes) => {
        if (!user || !user.id) {
            console.error('User data is not available yet.');
            return;
        }
        if ((currentTextChat && (mes.id === currentTextChat.chatId)) || (mes.id == selectedChat)) {
            const newMessageData = {
                messageText: mes.latestMessage.message,
                file: mes.latestMessage.file ? mes.latestMessage.file : null, 
                filePreview: mes.latestMessage.filePreview ? mes.latestMessage.filePreview : null, 
                date: mes.latestMessage.created_at,
                sender: mes.latestMessage.sender ? mes.latestMessage.sender.id == user.id ? "client" : isSupportChat ? "admin" : "donor" : isSupportChat ? "admin" : "donor"
            };
            setMessagesList((prevMessages) => ({
                ...prevMessages,
                messages: [...prevMessages.messages, newMessageData],
            }));
            if (messagesListRef && messagesListRef.current) {
                    const messagesContainer = messagesListRef.current;
                    const lastMessage = messagesContainer.lastChild;
                    if (lastMessage) {
                        lastMessage.scrollIntoView({ behavior: "smooth" });
                        setScrollPosition(0)
                    }
                }
        }
    }, [user, currentTextChat, selectedChat]);

    useEffect(() => {
        const messageReceivedHandler = (mes) => handleInviteAccepted(mes);
        if(isSupportChat){
            socket.on("message-support-received", messageReceivedHandler);
        
            return () => {
                socket.off("message-support-received", messageReceivedHandler);
            };
        }else {
            socket.on("message received", messageReceivedHandler);
        
            return () => {
                socket.off("message received", messageReceivedHandler);
            };

        }
        // eslint-disable-next-line
    }, [socket, handleInviteAccepted]);


    const sendMessage = async (event) => {
        if (newMessage || messageFile) {
            if(messageInputRef.current) {
                messageInputRef.current.value = ''
            }

            try {
                const config = {
                    headers: {
                        "Content-type": messageFile ? "multipart/form-data" : "application/json",
                        Authorization: `Bearer ${userInfo.token}`,
                        "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
                    },
                };

                setNewMessage('');

                const { data } = await axios.post(
                    "https://dev-api.surrogacy-platform.com/api/messages",
                    {
                        message: newMessage ?  newMessage : ' ',
                        conversationId: parseInt(selectedChat),
                        senderId: userInfo.userId,
                        type: isSupportChat ? "SUPPORT" : 'MESSAGE',
                        file: messageFile ? messageFile : null
                    },
                    config
                );

                if(messageFile){
                    setMessageFile(null)
                    setMessageFilePreview(null)
                }

                if(isSupportChat){
                    socket.emit("new-message-support", data);
                    dispatch(fetchSupportChatsRequest());
                }else {
                    socket.emit("new message", data);
                    dispatch(fetchChatsRequest());
                }
                
                
            } catch (error) {
                console.error(error);
            }
        }
    };

    const loadMoreMessages = useCallback(async () => {
        try {
            setTimeout(async () => {
                await dispatch(fetchMessagesRequest(selectedChat, currentPage + 1, 20));
                setCurrentPage((prevPage) => prevPage + 1);
            }, 1000); 
        } catch (error) {
            console.error(error);
        }
    }, [currentPage, dispatch, selectedChat, setCurrentPage]);

    const handleScroll = useCallback(() => {
        const messagesContainer = messagesRef.current;
        if (messagesContainer) {
            const atTop = messagesContainer.scrollTop === 0;
    
            if (atTop) {
                setScrollPosition(messagesContainer.scrollHeight - messagesContainer.scrollTop);
                loadMoreMessages();
            }
        }
    }, [loadMoreMessages]);

    useEffect(() => {
        const handleScrollEvent = () => {
            if (!firstScroll && messagesList && (messagesList.messages.length <= messagesList.totalMessagesCount)) {
                handleScroll();
            }
        };
    
        const messagesContainer = messagesRef.current;
        if (messagesContainer) {
            messagesContainer.addEventListener("scroll", handleScrollEvent);
            messagesContainer.scrollTop = messagesContainer.scrollHeight - scrollPosition;
        }
    
        return () => {
            if (messagesContainer) {
                messagesContainer.removeEventListener("scroll", handleScrollEvent);
            }
        };
    }, [handleScroll, messagesList, firstScroll, scrollPosition]);

    return (
        <div
            className={classNames(
                classes["chat_field"],
                classes["chat_field_active"]
            )}
        >
            <div className={classes["chat_header"]}>
                <div className={classes["chat_users"]}>
                    <div
                        className={classNames(
                            classes["image"],
                            classes["chat_back_button"]
                        )}
                        onClick={backToChatList}
                    >
                        <Icon className="icon-arrow-left" id="icon-arrow-left" />
                    </div>
                    {messagesList && (
                        <>
                            <div
                                className={classNames(
                                    classes["chat_user"],
                                    currentTextChat?.status == "online" && classes["chat_status_online"]
                                )}
                            >
                                {currentTextChat?.photo ? (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        <img src={currentTextChat?.photo} alt="" />
                                    </div>
                                ) : (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        {`${currentTextChat?.userName.charAt(0)}`}
                                    </div>
                                )}
                                {!isMobile ? <span>{currentTextChat?.userName}</span> : null}
                            </div>
                            <div
                                className={classNames(
                                    classes["chat_user"],
                                    user?.isOnline && classes["chat_status_online"]
                                )}
                            >
                                {user?.userAvatar ? (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        <img src={user?.userAvatar} alt="" />
                                    </div>
                                ) : (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        {`${user?.firstName.charAt(0)}`}
                                    </div>
                                )}
                                {!isMobile ? <span>{user?.firstName}</span> : null}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={classes["scroll-container"]} ref={messagesRef} >
                <div className={classes["chat_messages"]} ref={messagesListRef}>
                    <div className={classes["user_message_filler"]} />
                    {messageDates.map((date) => (
                        <React.Fragment key={date}>
                            <div className={classes["chat_date_separator"]}>
                                <span>{date === today ? 'Today' : date === yesterday ? 'Yesterday' : date}</span>
                            </div>
                            {groupedMessages[date].map((message) => (
                                <div
                                    key={message.id}
                                    className={classNames(
                                        classes["user_message"],
                                        message.sender == "client" && classes["self_user"]
                                    )}
                                >
                                    {(message.sender == "donor" || message.sender == "admin") ? (
                                        currentTextChat?.photo ? (
                                            <div className={classNames(classes["avatar"], "avatar")}>
                                                <img src={currentTextChat?.photo} alt="" />
                                            </div>
                                        ) : (
                                            <div className={classNames(classes["avatar"], "avatar")}>
                                                {`${currentTextChat?.userName.charAt(0)}`}
                                            </div>
                                        )
                                    ) : user?.userAvatar ? (
                                        <div className={classNames(classes["avatar"], "avatar")}>
                                            <img src={user?.userAvatar} alt="" />
                                        </div>
                                    ) : (
                                        <div className={classNames(classes["avatar"], "avatar")}>
                                            {`${user?.firstName.charAt(0)}`}
                                        </div>
                                    )
                                    }
                                    <ul className={classes["user_messages"]}>
                                        <li className={classNames(message.file && classes["user_message_file"])}>
                                            {
                                                message.file && (
                                                    <a href={message.file} download target="_blanc">
                                                        <div className={classes["user_message_file_icon"]}>
                                                            <img src={message.file} alt="" />
                                                        </div>
                                                    </a>
                                                )
                                            }
                                            {message.messageText}
                                        </li>
                                    </ul>
                                </div>
                            ))}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className={classes["chat_input_block"]}>
                {
                    messageFilePreview && (
                        <img className={classes["file-preveiw"]} src={messageFilePreview} alt="file preview" />
                    )
                }
                <div className={classes["chat_input"]}>
                    <input
                        placeholder="Type your message"
                        className={classes["chat_messages_input"]}
                        onChange={typingHandler}
                        onKeyDown={handleKeyDown}
                        ref={messageInputRef}
                    />
                    {
                        isSupportChat && (
                            <label className={classes["image"]} htmlFor="image-input">
                                <input type="file" id="image-input" accept=".jpg, .jpeg, .png, image/jpg, image/jpeg, image/png" className="display-none" onChange={addFile} />
                                <Icon className="icon-gallery" id="icon-gallery" />
                            </label>
                        )
                    }
                </div>
                <Button addClasses={["button", "default"]} onClick={sendMessage}>
                    Send
                </Button>
            </div>
        </div>
    );
};

export default TextChatRoom;
