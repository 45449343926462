import React, { useState } from "react";

import classesMain from '../style/aboutUsMainStyles.module.scss'
import classes from "../style/howItWorksSectionStyle.module.scss";
import classNames from "classnames";
import Icon from "../../../../../ui/Icon";

const HowItWorksSection = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const handleTabClick = (index) => {
        setSelectedTab(index);
        if (showMobileMenu) {
            setShowMobileMenu(false);
        }
    };

    const termsArray = [
        {
            title: "Step 1. Registration",
            content: (
                <div>
                    <img className={classes["logo-img"]} src="/cdn/images/logo-big.svg" alt="Logo surrogacy platform" />
                    <h2>Signed Up!</h2>
                    <p>
                        After registration you will have access to the full catalog of egg donors, the ability to view profiles in detail, use our services and see data and more photos and videos in profile egg donor.
                    </p>
                </div>
            ),
        },
        {
            title: "Step 2: Search & Match - find an egg donor",
            content: (
                <div>
                    <p>
                        Explore our catalog, for the convenience of your search we have made filters by which you can set specific parameters of your future egg donor. Choose your favorite candidate from the list and read information and characteristics of an egg donor. You can choose few profiles in the catalog it's free of charge
                    </p>
                </div>
            ),
        },
        {
            title: "Step 3: Networking - connecting with egg donors",
            content: (
                <div>
                    <p>Communication is key, especially when it comes to such an important choice. Now You can freely communicate with egg donors, creating an atmosphere of understanding and support. Our platform is LGBTQ+ friendly, providing a welcoming space for all.</p>
                    <p>Surrogacy Platform is an international service with donors and surrogates from all over the world, so we have provided 3 communication options so that time zone differences won't be a hindrance for you:<br />
                    1. Emails - communicate in the form of email correspondence through our service, ask all the questions you are interested in without being bound by time. The candidate will answer you as soon as she logs on to the service.<br  />
                    2. Online Chat - a standard chat room where you can communicate live with the egg donor. Suitable for those who want to get an answer here and now.<br />
                    3. Video Chat - The opportunity to "Live" see the future candidate, ask questions, get to know the donor better.</p>
                    <p className="primary-color">*You do not need to guess the time when the donor or surrogate is Online, just book a time and the selected candidate will be waiting for you on the appointed date.</p>
                </div>
            ),
        },
        {
            title: "Step 4: Selecting a service package",
            content: (
                <div>
                    <p>Surrogacy Platform partners with trusted IVF clinics in Cyprus. Choose the package that matches your preferences and suits you best. We provide a diverse list of services, including assurances that nothing will interfere with your journey to parenthood and even provide full turnkey surrogacy services.</p>
                    <p>Sign you contract for particular package during your 2-3 days visit to clinic for sperm collection.</p>
                </div>
            ),
        },
        {
            title: "Step 5: Make Payment",
            content: (
                <div>
                    <p>
                        We understand that surrogacy services may not come cheap, so you can break down the payment into installments. After egg donor choice and order you make the first deposit payment to book particular egg donor and clinic coordinator will contact you to schedule your clinic appointment, you make the remaining payments as you progress through the stages of the fertilization and carrying program
                    </p>
                </div>
            ),
        },
        {
            title: "Step 6: Start fertilization program",
            content: (
                <div>
                    <p>After the first payment, the most important stage begins. You come to our clinic in Cyprus and start a medical examination and signing the contract, we prepare a surrogate mother from our database, a health and responsible person who has passed all madical and juridical examinations and starts the fertilization program.</p>
                </div>
            ),
        },
        {
            title: "Step 7: Pregnancy and childbirth",
            content: (
                <div>
                    <p>
                        We will monitor the surrogate's pregnancy, accompany her with documentation, tests and screenings. And after the birth, we process the newborn's papers and you and your baby go home togetherWe will monitor the surrogate's pregnancy, accompany her with documentation, tests and screenings. And after the birth, we process the newborn's papers and you and your baby go home together
                    </p>
                </div>
            ),
        }
    ];

    return (
        <section className={classes["how-it-works"]}>
            <div className={classNames(classesMain['about-us-container'], 'container')}>
                <h2>How it works</h2>
                <div
                    className={classes["how-it-works_mobile_select"]}
                    onClick={() => {
                        setShowMobileMenu(!showMobileMenu);
                    }}
                >
                    <span>{termsArray[selectedTab].title}</span>
                    <div
                        className={classNames(
                            classes["image"],
                            showMobileMenu ? classes["open"] : ""
                        )}
                    >
                        <Icon id="icon-chevron-down" className="icon-chevron-down" />
                    </div>
                </div>
                <div className={classes["how-it-works_description"]}>
                    <div
                        className={classNames(
                            classes["how-it-works_menu"],
                            showMobileMenu ? "" : classes["how-it-works_mobile_display_none"]
                        )}
                    >
                        {termsArray.map((tab, index) => (
                            <p
                                key={index}
                                className={classNames(
                                    classes["how-it-works-tab"],
                                    selectedTab === index ? classes["active"] : ""
                                )}
                                onClick={() => handleTabClick(index)}
                            >
                                {tab.title}
                            </p>
                        ))}
                    </div>
                    <div className={classes["how-it-works_content"]}>
                        {termsArray[selectedTab].content}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorksSection;
