import React from 'react';
import classes from "../style/futureSection.module.scss";

const FuturesSections = () => {
    return (
        <section className={classes["we_create_future"]}>
            <div className="container">
                <h2 className={classes["title"]}>
                    We create Future!
                </h2>
                <div className={classes["we_create_future_cards"]}>
                    <div className={classes["we_create_future_card"]}>
                        <img src="/cdn/images/main-page/future/verified_profiles.webp" loading="lazy" alt="" />
                        <h3>
                            Verified profiles
                        </h3>
                        <p>
                            All our Egg Donors and Surrogate Mothers are verified, interviewed and medically examined. We personally screen and interview each Egg Donor and Surrogate before adding her to our database, checking her medical documents, identity and geolocation.
                        </p>
                    </div>
                    <div className={classes["we_create_future_card"]}>
                        <img src="/cdn/images/main-page/future/18_years.webp" loading="lazy" alt="" />
                        <h3>
                            Worldwide Service
                        </h3>
                        <p>
                            We work all over the world with donors and surrogates from all corners of the world and the world's best clinics. We try to give every person a chance to become a parent, and we are very good at it.
                        </p>
                    </div>
                    <div className={classes["we_create_future_card"]}>
                        <img src="/cdn/images/main-page/future/cutting_edge.webp" loading="lazy" alt="" />
                        <h3>
                            Scam-free guarantee!
                        </h3>
                        <p>
                            You can see what egg donors look like in real life. We are the first service in the world ready to give you guarantees and confirm them. Come to our Video Chat and see for yourself
                        </p>
                    </div>
                    <div className={classes["we_create_future_card"]}>
                        <img src="/cdn/images/main-page/future/all_correspondence.webp" loading="lazy" alt="" />
                        <h3>
                            Exclusive packages
                        </h3>
                        <p>
                            In addition to the standard fertilization programs, our service also offers “Comfort” and “VIP” packages. We offer all conditions to save your time and make your path to parenthood easier.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FuturesSections;
