import React from "react";
import PageWrap from "../../../app/components/PageWrap";

import classes from "../style/singleBlogPage.module.scss";
import classNames from "classnames";
import Icon from "../../../ui/Icon";
import BlogAside from "./BlogAside";

const Post3 = () => {
    return (
        <PageWrap>
            <section className={classes["blog"]}>
                <div className={classNames(classes["container"], "container")}>
                    <div className={classes["blog_news_block"]}>
                        <div className={classes["blog_news"]}>
                            <div className={classes["blog_news_image"]}>
                                <div>
                                    <img src="/cdn/images/news_image_3.jpg" alt="" />
                                </div>
                            </div>
                            <div className={classes["blog_news_info"]}>
                                <div className={classes["blog_news_text"]}>
                                    <h1>
                                        Surrogacy and Egg Donation: How to Choose a Donor and
                                        Organize a Surrogacy Program
                                    </h1>
                                    <div className={classes["blog_news_author_info"]}>
                                        <div className={classes["blog_news_author_avatar"]}>
                                            <img src="/cdn/images/denys.jpg" alt="avatar" />
                                        </div>
                                        <span className={classes["by"]}>by</span>
                                        <span className={classes["blog_news_author_name"]}>
                                            Denys Manager
                                        </span>
                                        <div className={classes["blog_news_author_separator"]} />
                                        <span className={classes["date"]}>23.06.2024</span>
                                    </div>
                                </div>
                                <div className={classes["blog_news_categories"]}>
                                    <div
                                        className={classNames(
                                            classes["blog_card_footer_category_pill"],
                                            classes["blog_category_pill_cat_1"]
                                        )}
                                    >
                                        Egg Donor
                                    </div>
                                </div>
                                <div className={classes["blog_news_post"]}>
                                    <p>
                                        Introduction
                                        <br />
                                        Surrogacy and egg donation are becoming increasingly popular
                                        methods for couples and individuals who have difficulty
                                        conceiving a child naturally. Modern technologies and
                                        methods allow you to choose an egg donor through specialized
                                        websites, communicate with them, and then organize a
                                        surrogacy program. In this article, we will look at how to
                                        choose a donor and what steps need to be taken for the
                                        successful implementation of a surrogacy program.
                                    </p>
                                    <h2>Choosing an Egg Donor</h2>
                                    <h3>1. Finding a Donor on the Website</h3>
                                    <p>
                                        Many specialized websites offer extensive databases of egg
                                        donors. These databases contain donor profiles with detailed
                                        information, including:
                                    </p>
                                    <ul>
                                        <li>Photos and videos</li>
                                        <li>Medical history</li>
                                        <li>Education and professional achievements</li>
                                        <li>Personal interests and hobbies</li>
                                    </ul>
                                    <p>
                                        This information helps prospective parents choose a donor
                                        who best meets their expectations and requirements.
                                    </p>
                                    <h3>2. Communicating with the Donor</h3>
                                    <p>
                                        After selecting suitable candidates, prospective parents can
                                        contact the donors through the platform. This can be done
                                        via:
                                    </p>
                                    <ul>
                                        <li>Online chats</li>
                                        <li>Video calls</li>
                                        <li>Personal meetings</li>
                                    </ul>
                                    <p>
                                        Communicating with the donor allows you to get to know them
                                        better, ask any questions, and ensure that you have made the
                                        right choice. This is an important stage, as future parents
                                        want to be confident in the health and genetic material of
                                        the donor.
                                    </p>
                                    <h2>Organizing a Surrogacy Program</h2>
                                    <p>
                                        After choosing an egg donor, the process of organizing the
                                        surrogacy program begins. This process includes several key
                                        stages:
                                    </p>
                                    <h3>1. Medical Procedures</h3>
                                    <ul>
                                        <li>
                                            <span className="text-strong">Egg Retrieval</span>: The
                                            donor undergoes an ovulation stimulation procedure
                                            followed by egg retrieval.
                                        </li>
                                        <li>
                                            <span className="text-strong">Fertilization</span>: The
                                            eggs are fertilized with the father's or donor's sperm in
                                            a laboratory setting.
                                        </li>
                                        <li>
                                            <span className="text-strong">
                                                Preparation of the Surrogate Mother
                                            </span>
                                            : The surrogate mother undergoes a medical examination and
                                            preparation for embryo implantation.
                                        </li>
                                    </ul>
                                    <h3>2. Legal Aspects</h3>
                                    <p>
                                        Legal documents regulating the relationships between all
                                        participants must be drawn up. This includes:
                                    </p>
                                    <ul>
                                        <li>A contract with the egg donor</li>
                                        <li>A contract with the surrogate mother</li>
                                        <li>Establishing parental rights</li>
                                    </ul>
                                    <h3>3. Implantation and Pregnancy Management</h3>
                                    <p>
                                        After successful fertilization, the embryos are transferred
                                        to the surrogate mother's uterus. Pregnancy management is
                                        carried out under the supervision of doctors to ensure the
                                        health of the mother and the future child.
                                    </p>
                                    <h2>Conclusion</h2>
                                    <p>
                                        Surrogacy and egg donation provide unique opportunities for
                                        those who dream of having a child but face difficulties on
                                        the path to parenthood. Modern technologies not only allow
                                        you to choose a donor through specialized websites but also
                                        to communicate with them, ensuring confidence in your
                                        choice. Organizing a surrogacy program requires careful
                                        preparation, medical and legal procedures, but the end
                                        result—the birth of a long-awaited child—is worth all the
                                        effort.
                                    </p>
                                </div>
                                {/* <div className={classes["blog_news_horizontal_separator"]} />
                                <div className={classes["blog_news_footer"]}>
                                    <div>
                                        <div className={classes["image"]}>
                                            <Icon id="icon-eye" className="icon-eye" />
                                        </div>
                                        <span>
                                            19.1K
                                        </span>
                                    </div>
                                    <div className={classes["image"]}>
                                        <Icon id="icon-share" className="icon-share" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <BlogAside />
                    </div>
                </div>
            </section>
        </PageWrap>
    );
};

export default Post3;
