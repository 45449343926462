import React from "react";

import classes from '../style/generalChars.module.scss'

const GeneralChars = ({ generalCharsData1, generalCharsData2 }) => {
    return (
        <div className={classes["compare_2_general_characteristics"]}>
            <h2>General Characteristics</h2>
            <div className={classes["compare_2_general_characteristics_blocks"]}>
                <ul className={classes["additional_info"]}>
                    {generalCharsData1.map((item, index) => (
                        <li key={index}>
                            <span>
                                {item.label}
                            </span>
                            <span>{item.value}</span>
                        </li>
                    ))}
                </ul>
                <ul className={classes["additional_info"]}>
                    {generalCharsData2.map((item, index) => (
                        <li key={index}>
                            <span className="tablet-display-none">
                                {item.label}
                            </span>
                            <span>{item.value}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default GeneralChars;
