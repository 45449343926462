import React, { useState } from 'react';

import LinkButton from '../../../ui/Button/LinkButton';
import Icon from '../../../ui/Icon';

import classes from '../style/singleProgramPage.module.scss'
import classNames from 'classnames';

const VipProgram = ({ pack }) => {
    const [accordionBlock, setAccordionBlock] = useState([]);

    const showAccordionBlock = (type) => {
        if (accordionBlock.includes(type)) {
            setAccordionBlock(accordionBlock.filter(item => item !== type));
        } else {
            setAccordionBlock([...accordionBlock, type]);
        }
    }

    return (
        <div className={classes['single-program-container']}>
            <div className={classes['info-card']}>
                <div className={classes['col']}>
                    <div className={classes['icon']}>
                        <img src="/cdn/images/programs/vip.png" alt="" />
                    </div>
                    <div className={classes['info']}>
                        <p className={classes['name']}>{pack?.name}</p>
                        <p className={classes['desc']}>{pack?.shortDescription}</p>
                        <p className={classes['location']}><Icon id="icon-map-pin" /> Cyprus</p>
                    </div>
                </div>
                <div className={classes['col']}>
                    <div className={classes['pays']}>
                        <div className={classes['pays-info']}>
                            <p className={classes['payment']}>1 payment</p>
                            <p className={classes['price']}>€{pack?.price}</p>
                        </div>
                        <div className={classes['pays-info']}>
                            <p className={classes['payment']}>2 payment</p>
                            <p className={classes['price']}>€26000</p>
                        </div>
                        <div className={classes['pays-info']}>
                            <p className={classes['payment']}>3 payment</p>
                            <p className={classes['price']}>€20500</p>
                        </div>
                    </div>
                    <LinkButton to="/donors" className={classes['choose-button']} addClasses={['capitalize']}>Choose donor</LinkButton>
                </div>
            </div>
            <div className={classes['full-information']}>
                <p><b>Package “VIP” - (unlimited number of IVF and healthy embryo transfers - guaranteed birth of a child)</b></p>
                <p>Full refund in case of negative result</p>
                <p className={classes['small-title']}>List of services</p>
                <ul>
                    <li>Unlimited number of transfers of healthy embryos into the uterus of a surrogate mother - guaranteed birth of a child</li>
                    <li>The package is available for married and unmarried couples, single parents, LGBT (medical indications for surrogacy are not compulsory)</li>
                </ul>
                <div className={classes['accordion-block']}>
                    <div className={classNames(classes['title'], accordionBlock.includes('stage-1') && classes['open'])} onClick={() => showAccordionBlock('stage-1')}>
                        <p>Stage 1 - Document preparation and IVF program (after signing a contract with the clinic)</p>
                        <Icon id='icon-chevron-down' />
                    </div>
                    {
                        accordionBlock.includes('stage-1') && (
                            <div className={classes['info']}>
                                <ul>
                                    <li>Information services, support and coordination during the program</li>
                                    <li>Preparation and translation of documents for participation in the program</li>
                                    <li>Execution of the necessary documents, including visa costs, for the surrogate mother to ensure her residence in the territory of the TRNC</li>
                                    <li>Interpreter services during clinic visits</li>
                                    <li>Selection and provision of a surrogate mother candidate, complete medical examination and preparation of the surrogate mother (SM) before the IVF program</li>
                                    <li>Providing a transfer for the surrogate mother to (from) the airport, to the clinic for consultations and manipulations</li>
                                    <li>The medical part of the IVF program is carried out by the Reproductive Medicine Clinic in the TRNC</li>
                                    <li>PGD (13, 18, 21, X, Y chromosomes) preimplantation genetic diagnosis of embryos with gender selection</li>
                                    <li>The first part of payments to the surrogate mother</li>
                                    <li>Cryostorage of biological material (embryos) for one year</li>
                                    <li>Program duration is 24 months</li>
                                </ul>
                            </div>
                        )
                    }
                    <div className={classNames(classes['title'], accordionBlock.includes('stage-2') && classes['open'])} onClick={() => showAccordionBlock('stage-2')}>
                        <p>Stage 2 - Medical: monitoring pregnancy and childbirth</p>
                        <Icon id='icon-chevron-down' />
                    </div>
                    {
                        accordionBlock.includes('stage-2') && (
                            <div className={classes['info']}>
                                <p className={classes['bold']}>Paid at the 12th week of pregnancy</p>
                                <ul>
                                    <li>Execution of the necessary documents, including visa costs, for the surrogate mother to ensure her residence in the territory of the TRNC</li>
                                    <li>Interpreter services during clinic visits</li>
                                    <li>Providing a coordinator for the surrogate mother in the territory of the TRNC from the moment she enters the program until the childbirth (support at all stages of the program, consultation on general issues: supermarket, bank, transport)</li>
                                    <li>Providing the surrogate mother with a furnished apartment with a private bathroom from the beginning of the program until the childbirth in the TRNC</li>
                                    <li>Comprehensive medical control with medication support for the surrogate mother during gestation, including expenses for force majeure situations such as: premature birth, hospitalization of the surrogate mother if it is necessary to maintain pregnancy (on an outpatient basis or in a hospital setting) and any other medical unforeseen situations with the health of the surrogate mother</li>
                                    <li>Registration and pregnancy care (all necessary medical examinations and medicines, observation by doctors, pregnancy screenings and documentary support)</li>
                                    <li>Accompanying a surrogate mother during gestation till the birth of the child, monthly SM payments</li>
                                    <li>The second part of SM compensation for carrying pregnancy and childbirth</li>
                                </ul>
                            </div>
                        )
                    }
                    <div className={classNames(classes['title'], accordionBlock.includes('stage-3') && classes['open'])} onClick={() => showAccordionBlock('stage-3')}>
                        <p>Stage 3 - Childbirth and registration of documents for the child </p>
                        <Icon id='icon-chevron-down' />
                    </div>
                    {
                        accordionBlock.includes('stage-3') && (
                            <div className={classes['info']}>
                                <p className={classes['bold']}>Paid at the 30th  week of pregnancy</p>
                                <ul>
                                    <li>Childbirth in Czech Republic / in TRNC / Greece / Portugal / Moldavia or Georgia are included in the package price</li>
                                    <li>Information services and support for the surrogate mother during the program</li>
                                    <li>Providing a personal coordinator (support at all stages of the program, consultation on general issues: supermarket, bank, transport, etc.)</li>
                                    <li>Transfer and organization of timely visits to the clinic for medical consultations</li>
                                    <li>Monitoring pregnancy (consultations with doctors, examination by an obstetrician-gynecologist, screenings, ultrasound, CTG and medications)</li>
                                    <li>Accommodation of the surrogate mother in an apartment</li>
                                    <li>Coordination and medical services during childbirth (including caesarean section and hospital stay until discharge)</li>
                                    <li>Discharge from the hospital</li>
                                    <li>Pediatric services</li>
                                    <li>Legal services (obtaining a birth certificate of the child, notarization, translation, apostille and preparation of a package of documents for submission to the embassy)</li>
                                </ul>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className={classes['important-info']}>
                <p className={classes['title']}>IT'S IMPORTANT TO KNOW:</p>
                <ol>
                    <li>The IVF program is carried out until the surrogate mother becomes pregnant and until the guaranteed birth of a baby (full refund in case of a negative result)</li>
                    <li>In cases if the surrogate mother and the egg donor are unsuitable for the provision of services for any reason in the course of preparation for the IVF program, the clinic will replace the surrogate mother and the egg donor WITHOUT ADDITIONAL PAYMENT!</li>
                    <li>In case of spontaneous termination of pregnancy before 12 weeks, the clinic starts a new IVF program, or carries out the transfer of cryo-frozen embryos before pregnancy of the surrogate mother, will be performed WITHOUT ADDITIONAL PAYMENT!</li>
                    <li>If the onset of pregnancy does not occur as a result of the transfer of embryos to the surrogate mother, new attempts of transfer of cryofrozen embryos or new attempts of IVF will be carried out WITHOUT ADDITIONAL PAYMENT!</li>
                    <li>If the surrogate mother refuses to continue the program, the clinic will replace her with other candidates WITHOUT ADDITIONAL PAYMENT!</li>
                    <li>If there are not enough embryos for additional programs, the clinic provides new oocyte donors and conducts new IVF programs WITHOUT ADDITIONAL PAYMENT!</li>
                    <li>In case of termination of pregnancy of the surrogate mother at any time or in case of the birth of a stillborn child, the program will be restarted and continued until the birth of a child WITHOUT ADDITIONAL PAYMENT!</li>
                </ol>
            </div>
            <div className={classes['important-info']}>
                <p className={classes['title']}>This package does not include the following services:</p>
                <p></p>
                <ul>
                    <li>cost of expenses of your visits to the clinic</li>
                    <li>cost of your visit to the clinic (tickets, transfer and accommodation)</li>
                </ul>
            </div>
        </div>
    );
}

export default VipProgram;
