import React from 'react';
import PageWrap from '../../../../app/components/PageWrap';

import classes from '../../style/staticPages.module.scss'

const TripToChildbirthPage = () => {
    return (
        <PageWrap>
            <div className='container'>
                <section className={classes['static-pages-contaniner']}>
                <h1>Trips to childbirth</h1>

                <h2>Your Trips to childbirth</h2>

                <p>There is nothing more beautiful than holding your baby in your arms for the first few minutes of their life. That's why we'll make sure you have that opportunity. </p>
                <p>If you choose the “VIP” packages, you will receive a "Turnkey Childbirth" and we will take care of all the hassle of booking your flights, accommodation and transfer to our clinic in Cyprus. The surrogate will be monitored and delivered under our close supervision and we will make sure that everything goes smoothly and without problems.</p>
                <p>All you have to do is be happy and enjoy the fact that you will soon become parents.</p>

                <h2>Surrogate Trips to childbirth</h2>

                <p>We are sympathetic to our clients who cannot afford to travel to our clinic to see a surrogate for delivery. There can be a number of reasons for this. </p>
                <p>If you choose the "Standart" or "Comfort" package, you have the opportunity to take matters into your own hands. At the sixth month of pregnancy, the surrogate will come to live with you, in your country. You are responsible for finding temporary place to live for the surrogate mother or she can live with you. You also take care of her maintenance. You are also in charge of organising the birth, choosing a clinic, etc. </p>
                <p>You can also additionally order the services of Childbirth in other countries, more details about the services for the Standard and Comfort packages are described on the program pages with the price and a detailed description of what is included in them.</p>
                <p>We will provide you with full documentary support and all medical examinations for the surrogate mother. </p>
                <p>If you still have any questions, please feel free to ask them in the Support Chat or Email</p>

                </section>
            </div>
        </PageWrap>
    );
}

export default TripToChildbirthPage;
