import React from "react";
import { useLocation } from "react-router-dom";
import Icon from "../../../ui/Icon";
import Button from "../../../ui/Button";
import classNames from "classnames";

import classes from "../style/mailboxLeftMenu.module.scss";

const MailboxLeftMenu = ({onClickCompose, onClickLabels, activeLabel, onChangeBox, showMenuMobile}) => {
    const location = useLocation();

    const mailboxList = [
        { path: "/mailbox", icon: "inbox", label: "Inbox", count: 8, box: "inbox" },
        { path: "/mailbox/sent", icon: "sent", label: "Sent", box: "sent" },
        // {path: "/mailbox/draft",icon: "draft",label: "Draft",count: 8,box: "draft",},
        { path: "/mailbox/starred", icon: "starred", label: "Starred", box: "starred" },
        { path: "/mailbox/trash", icon: "trash", label: "Trash", box: "trash" },
    ];

    const labels = [
        { color: "green", text: "Personal", label: "Personal" },
        { color: "violet", text: "Company", label: "Company" },
        { color: "orange", text: "Important", label: "Important" },
        { color: "red", text: "Private", label: "Private" },
    ];

    return (
        <div className={classNames(classes.email_menu_block, !showMenuMobile ? classes.email_menu_block_hide : '')}>
            <Button addClasses={["button", "capitalize"]} className={classes.new_email} onClick={onClickCompose}>
                Compose
            </Button>
            <ul className={classes.email_menu}>
                {mailboxList.map(({ path, icon, label, box }, index) => (
                    <li key={index}>
                        <div
                            className={classNames({ [classes.active]: location.pathname === path }, classes.email_type_button)}
                            onClick={() => onChangeBox(path, box)}
                        >
                            <div className={classes.image}>
                                <Icon id={`icon-${icon}`} className={`icon-${icon}`} />
                            </div>
                            <span>{label}</span>
                            {/* {count && (
                                <div
                                    className={classNames(
                                        classes.messages_count,
                                        classes[`messages_count_${icon}`]
                                    )}
                                >
                                    {count}
                                </div>
                            )} */}
                        </div>
                    </li>
                ))}
            </ul>
            <div className={classes.email_labels}>
                <h4>Labels</h4>
                <ul>
                    {labels.map(({ color, text, label }, index) => (
                        <li key={index} onClick={() => onClickLabels(label)} className={classNames({[classes.active]: activeLabel === label} )}>
                            <div className={classes[`label-${color}`]} />
                            {text}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default MailboxLeftMenu;
