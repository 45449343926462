import { put, takeLatest, call } from 'redux-saga/effects';
import { fetchDonorsSuccess, 
  fetchDonorsFailure, 
  setFilterResponse, 
  fetchDonorByIdSuccess, 
  fetchDonorByIdFailure, 
  fetchNewDonorsSuccess, 
  fetchNewDonorsFailure,
  addDonorToSavedSuccess,
  addDonorToSavedFailure,
  getSavedDonorsSuccess,
  getSavedDonorsFailure,
  buyDonorPhotoSuccess,
  buyDonorPhotoFailure,
  getDonorPhotosSuccess,
  getDonorPhotosFailure,
  fetchSavedDonorsSuccess,
  fetchSavedDonorsFailure,
  fetchLikedDonorsSuccess,
  fetchLikedDonorsFailure,
  getDonorVideosSuccess,
  getDonorVideosFailure,
  setSpermDonors,
  setSurrogacyDonors,
  donorApplicationSuccess,
  donorApplicationFailure,
  donorFullRegistrationSuccess,
  donorFullRegistrationFailure,
  uploadDonorPhotosSuccess,
  uploadDonorPhotosFailure, } from '../actions/donorActions';
import { FETCH_DONORS_REQUEST, 
  FETCH_DONOR_BY_ID_REQUEST, 
  FETCH_NEW_DONORS_REQUEST, 
  ADD_DONOR_TO_SAVED_REQUEST, 
  GET_SAVED_DONORS_REQUEST,
  BUY_DONOR_PHOTO_REQUEST,
  GET_DONOR_PHOTO_REQUEST,
  FETCH_SAVED_DONORS_REQUEST,
  FETCH_LIKED_DONORS_REQUEST,
  GET_DONOR_VIDEO_REQUEST,
  DONOR_APPLICATION_REQUEST,
  DONOR_FULL_REGISTRATION_REQUEST,
  UPLOAD_DONOR_PHOTOS_REQUEST} from '../constants/actionTypes';
import api from '../api/api';

import Cookies from 'js-cookie';
import instance from '../api/api';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

const user = JSON.parse(localStorage.getItem("userInfo"));

function* fetchDonors(action) {
  try {
    const { page, limit, sortType, donorType, filterParams  } = action.payload;
    let queryParams = `?sortType=${sortType}&page=${page}&limit=${limit}`;

    if (user) {
        queryParams += `&forUser=${user.userId}`;
    }

    if (filterParams) {
        queryParams += `&${filterParams}`;
    }

    if(donorType) {
      queryParams += `&donorType=${donorType}`;
    }

    const endpoint = '/donors';
    const query = queryParams;
    const response = yield call(api.get, `${endpoint}${query}`);

    if (filterParams) {
     yield  put(fetchDonorsSuccess(response.data));
     yield  put(setFilterResponse(response.data));
    } else {
      yield put(fetchDonorsSuccess(response.data));
    }
    if(donorType == "surrogacy"){
      yield  put(setSurrogacyDonors(response.data));
    }
    if(donorType == "sperm"){
      yield  put(setSpermDonors(response.data));
    }
  } catch (error) {
    yield put(fetchDonorsFailure(error));
  }
}

function* fetchSavedDonors(action) {
  try {
    const { page, limit, sortType } = action.payload;
    let queryParams = `?sortType=${sortType}&page=${page}&limit=${limit}`;

    if (user) {
        queryParams += `&forUser=${user.userId}`;
    }

    const endpoint = '/donors';
    const query = queryParams;
    const response = yield call(api.get, `${endpoint}${query}&onlySaved=true`);

    yield put(fetchSavedDonorsSuccess(response.data.donors));
  } catch (error) {
    yield put(fetchSavedDonorsFailure(error));
  }
}

function* fetchLikedDonors(action) {
  try {
    const { page, limit, sortType } = action.payload;
    let queryParams = `?sortType=${sortType}&page=${page}&limit=${limit}`;

    if (user) {
        queryParams += `&forUser=${user.userId}`;
    }

    const endpoint = '/donors';
    const query = queryParams;
    const response = yield call(api.get, `${endpoint}${query}&onlyLiked=true`);

    yield put(fetchLikedDonorsSuccess(response.data.donors));
  } catch (error) {
    yield put(fetchLikedDonorsFailure(error));
  }
}

function* fetchDonorById(action) {
  try {
    const { id } = action.payload;
    const response = yield call(api.get, `/donors/${id}?type=user`);
    yield put(fetchDonorByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchDonorByIdFailure(error));
  }
}

function* fetchNewDonors(action) {
  try {
    const { page, limit } = action.payload;
    let queryParams = `?sortType=DESC&page=${page}&limit=${limit}`;

    if (user) {
        queryParams += `&forUser=${user.userId}`;
    }

    const endpoint = '/donors';
    const query = queryParams;
    const response = yield call(api.get, `${endpoint}${query}`);
    yield put(fetchNewDonorsSuccess(response.data.donors));
  } catch (error) {
    yield put(fetchNewDonorsFailure(error));
  }
}

function* addDonorToSaved(action) {
  try {
    const tokenCookie = Cookies.get('token');
    const headers = {
      Authorization: `Token ${tokenCookie}`,
      'Content-Type': 'application/json',
    };
    const response = yield call(api.post, '/users/saveDonor', action.payload, {
      headers,
    });
    yield put(addDonorToSavedSuccess(response.data));
  } catch (error) {
    yield put(addDonorToSavedFailure(error));
  }
}

function* getSavedDonors(action) {
  try {
    const response = yield call(instance.get, 'users/saved');
    yield put(getSavedDonorsSuccess(response.data.donors));
    if (action.callback) {
      action.callback(response.data.donors);
    }
  } catch (error) {
    yield put(getSavedDonorsFailure(error));
    if (action.callback) {
      action.callback(error.message);
    }
  }
}

function* buyDonorPhoto(action) {
  try {
    const response = yield call(instance.post, `users/buyPhoto/${action.payload}`);
    yield put(buyDonorPhotoSuccess(response));
    if (action.callback) {
      action.callback(response);
    }
  } catch (error) {
    yield put(buyDonorPhotoFailure(error));
    if (action.callback) {
      action.callback(null, error);
    }
  }
}

function* getDonorPhotos(action) {
  const { donorId } = action.payload;
  try {
    const response = yield call(instance.get, `users/donorFiles/${donorId}`);
    yield put(getDonorPhotosSuccess(donorId, response.data));
  } catch (error) {
    yield put(getDonorPhotosFailure(error));
  }
}

function* getDonorVideos(action) {
  const { donorId } = action.payload;
  try {
    const response = yield call(instance.get, `users/donorFiles/${donorId}`);
    yield put(getDonorVideosSuccess(donorId, response.data));
  } catch (error) {
    yield put(getDonorVideosFailure(error));
  }
}

function* donorApplication(action) {
  console.log(action.payload);
  try {
    const response = yield call(instance.post, `donors/application-for-registration-donor`, action.payload);
    yield put(donorApplicationSuccess(response));
    if (action.callback) {
      action.callback(response);
    }
  } catch (error) {
    yield put(donorApplicationFailure(error.response));
    if (action.callback) {
      action.callback(null, error.response);
    }
  }
}

function* donorFullRegistration(action) {
  console.log(action.payload);
  try {
    const response = yield call(instance.post, `donors/signup`, action.payload);
    yield put(donorFullRegistrationSuccess(response));
    if (action.callback) {
      action.callback(response);
    }
  } catch (error) {
    yield put(donorFullRegistrationFailure(error));
    if (action.callback) {
      action.callback(null, error);
    }
  }
}

function* uploadDonorPhotos(action) {
  try {
    const config = {
      headers: {
          'Content-Type': 'multipart/form-data',
          "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
      },
  };
    const response = yield call(api.post, `users/uploadPhoto`, action.payload, config);
    yield put(uploadDonorPhotosSuccess(response));
    if (action.callback) {
      action.callback(response);
    }
  } catch (error) {
    yield put(uploadDonorPhotosFailure(error));
    if (action.callback) {
      action.callback(null, error);
    }
  }
}

function* donorSaga() {
  yield takeLatest(FETCH_DONORS_REQUEST, fetchDonors);
  yield takeLatest(FETCH_DONOR_BY_ID_REQUEST, fetchDonorById);
  yield takeLatest(FETCH_NEW_DONORS_REQUEST, fetchNewDonors);
  yield takeLatest(ADD_DONOR_TO_SAVED_REQUEST, addDonorToSaved);
  yield takeLatest(GET_SAVED_DONORS_REQUEST, getSavedDonors);
  yield takeLatest(BUY_DONOR_PHOTO_REQUEST, buyDonorPhoto);
  yield takeLatest(GET_DONOR_PHOTO_REQUEST, getDonorPhotos);
  yield takeLatest(GET_DONOR_VIDEO_REQUEST, getDonorVideos);
  yield takeLatest(FETCH_SAVED_DONORS_REQUEST, fetchSavedDonors);
  yield takeLatest(FETCH_LIKED_DONORS_REQUEST, fetchLikedDonors);
  yield takeLatest(DONOR_APPLICATION_REQUEST, donorApplication);
  yield takeLatest(DONOR_FULL_REGISTRATION_REQUEST, donorFullRegistration);
  yield takeLatest(UPLOAD_DONOR_PHOTOS_REQUEST, uploadDonorPhotos);
}

export default donorSaga;
