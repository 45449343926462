import { useSelector } from "react-redux";

export function useGetActiveDonor(donorId) {
    const onlineDonors = useSelector(state => state.videoChat.onlineDonors);

    if (onlineDonors && donorId) {
        return onlineDonors.find((item) => item.donorId === +donorId)
    }

    return null
}