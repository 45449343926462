import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import classes from './styles/select.module.scss'
import Icon from '../Icon'

function MultiSelect({ values, placeholder, useFormSetValue, className, name, defaultValue, withError, errorText, placeholderInline  }) {
    const [multiValue, setMultiValue] = useState(defaultValue ?? []);

    let id = Math.floor(Math.random() * 900000)
    const selectValue = useRef(null)

    const ucFirst = (str) => {
        if (!str || typeof str === 'number') return str
        return str[0].toUpperCase() + str.slice(1)
    }

    const onChangeSelect = e => {
        let newValues = [
            ...multiValue
        ]

        newValues.push(e.target.value)

        setMultiValue(newValues)
        useFormSetValue(name, newValues.join(', '))
    }

    const deleteVlue = (value) => {
        let newValues = multiValue.filter(item => item !== value)

        setMultiValue(newValues)
        useFormSetValue(name, newValues.join(', '))
    }

    const setRef = (e) => {
        selectValue.current = e
    }

    return (
        <div className={classNames(
            classes['select-row'],
            className ? className : null,
            withError ? classes['error'] : null,
            !multiValue.length && !defaultValue.length ? classes['select-row--placeholder'] : null
        )}>
            {placeholder ? (
                <label htmlFor={name + '_' + id} suppressHydrationWarning={true}>
                    <span>{placeholder}</span>
                </label>
            ) : null}
            <select
                value=""
                name={name}
                id={name + '_' + id}
                onChange={onChangeSelect}
                ref={setRef}
                suppressHydrationWarning={true}
            >
                {placeholderInline ? <option value="" disabled>{placeholderInline}</option> : null}
                {
                    values
                    ?
                        values.map((item) => (
                            multiValue.includes(ucFirst(item.name))
                            ?
                                null
                            :
                            <option value={item.value ? item.value : item.name} key={item.value ? item.value : item.name}>
                                {ucFirst(item.name)}
                            </option>
                        ))
                    :
                        null
                }
            </select>
            {
                withError ?
                    <span className={classes["error-label"]}>{errorText ? errorText : null}</span>
                : null
            }
            {
                multiValue.length
                ?
                    <ul className={classes['select-list']}>
                        {
                            multiValue.map((item, index) => (
                                <li className={classes['select-item']} key={item + index}>
                                    <span>{item}</span>
                                    <button
                                        className={classes['select-btn']}
                                        onClick={deleteVlue.bind(this, item)}
                                    >
                                        <Icon id="icon-close" />
                                    </button>
                                </li>
                            ))
                        }
                        
                    </ul>
                :
                    null
            }
        </div>
    )
}

export default MultiSelect
