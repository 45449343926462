import React, { useEffect, useState } from "react";
import Button from "../../ui/Button";
import ChangePass from "./components/ChangePass";
import ForgotPass from "./components/ForgotPass";
import LogIn from "./components/LogIn";
import SignUp from "./components/SignUp";

import classes from "./style/formAuth.module.scss";
import classNames from "classnames";


const FormAuth = ({ type, onShowVerificationBlock }) => {
    const [formState, setFormState] = useState(type);
    const user = JSON.parse(localStorage.getItem("userInfo"));

    const changeFormType = (newType) => {
        setFormState(newType);
    };

    useEffect(() => {
        if(user) {
            setFormState('login')
        }
    }, []);

    const showFormType = (type) => {
        setFormState(type);
    }

    const showForm = (formState)=> {
        switch (formState) {
            case "login":
                return <LogIn showFormType={showFormType} />;
            case "signup":
                return <SignUp showFormType={showFormType} onShowVerificationBlock={onShowVerificationBlock} />;
            case "forgot":
                return <ForgotPass showFormType={showFormType} />;
            case "change":
                return <ChangePass />;
            default:
                return <></>;
        }
    };

    const showTitle = (formState)  => {
        switch (formState) {
            case "login":
                return "Log In";
            case "signup":
                return "Sign Up";
            case "forgot":
                return "Forgot your password?";
            case "change":
                return "Change";
            default:
                return "";
        }
    };

    return (
        <div className={classNames(classes.auth_form, classes[formState]) }>
            <h1 className={classes.title}>{showTitle(formState)}</h1>
            {showForm(formState)}
            <p className={classes.dont_have}>
                {formState == "login" ? "Don’t have an account?" : null}
                {formState == "signup" ? "Already have an account?" : null}
            </p>
            {formState == "login" ? (
                <Button
                    type="submit"
                    addClasses={["button"]}
                    onClick={() => changeFormType("signup")}
                >
                    sign up
                </Button>
            ) : null}
            {formState == "signup" ? (
                <Button
                    type="submit"
                    addClasses={["button"]}
                    onClick={() => changeFormType("login")}
                >
                    log in
                </Button>
            ) : null}
        </div>
    );
};

export default FormAuth;
