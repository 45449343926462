import React from 'react'
import classNames from 'classnames'

import Icon from '../Icon'

import classes from './styles/textarea.module.scss'

function Textarea({ placeHolder = '', name, defaultValue = '', withError = false, errorText = '', useFormEl, addProps = {}, staticPlaceHolder, onChange, value, className, withNewStyle }) {
    let id = Math.floor(Math.random() * 900000)

    const onChangeTextarea = e => {
        if(onChange) {
            onChange(e.target.value)
        }else {
            useFormEl.onChange(e)
        }
    }

    const defaultValueObject = typeof value !== 'undefined' ? {value: value} : {defaultValue: defaultValue ?? ''}

    return (
        <div className={classNames(
            classes['textarea-row'],
            withError ? classes['error'] : null,
            staticPlaceHolder ? classes['static-placeholder'] : '',
            className ? className : '',
            withNewStyle ? classes['new-kit-style'] : ''
        )}>
            <label htmlFor={name + '_' + id} suppressHydrationWarning={true}>
                {placeHolder}
            </label>
            <textarea
                {...defaultValueObject}
                {...useFormEl}
                name={name}
                {...addProps}
                id={name + '_' + id}
                placeholder={placeHolder}
                onChange={onChangeTextarea}
                suppressHydrationWarning={true}
            ></textarea>
            {withError ? (
                <label className={classes['error-label']} data-error={errorText}>
                    <Icon id="icon-alert" className={classNames(classes.icon, classes.error)} />
                </label>
            ) : null}
        </div>
    )
}

export default Textarea
