import React, { useState } from 'react';

import classes from '../style/support.module.scss'
import classNames from 'classnames';
import Icon from '../../../ui/Icon';

const Support = () => {
    const [accordionBlock, setAccordionBlock] = useState([]);

    const showAccordionBlock = (type) => {
        if (accordionBlock.includes(type)) {
            setAccordionBlock(accordionBlock.filter(item => item !== type));
        } else {
            setAccordionBlock([...accordionBlock, type]);
        }
    }

    return (
        <div className={classes["settings-support"]}>
            <h4>Support</h4>
            <div className={classes['faq-block']}>
                {/* <p className={classes['title']}>FAQ's</p>
                <p className={classes['small-text']}>Let us help answer the most common questions.</p>
                <div className={classes['accordion-block']}>
                    <div className={classNames(classes['accordion-title'], accordionBlock.includes('stage-1') && classes['open'])} onClick={() => showAccordionBlock('item-1')}>
                        <p>Does my subscription automatically renew?</p>
                        <Icon id='icon-chevron-down' />
                    </div>
                    {
                        accordionBlock.includes('item-1') && (
                            <div className={classes['info']}>
                                <p>Does my subscription automatically renew?</p>
                                <p>Does my subscription automatically renew?</p>
                                <p>Does my subscription automatically renew?</p>
                            </div>
                        )
                    }
                    <div className={classNames(classes['accordion-title'], accordionBlock.includes('stage-1') && classes['open'])} onClick={() => showAccordionBlock('item-2')}>
                        <p>Can I store the item on an intranet so everyone has access?</p>
                        <Icon id='icon-chevron-down' />
                    </div>
                    {
                        accordionBlock.includes('item-2') && (
                            <div className={classes['info']}>
                                <p>Can I store the item on an intranet so everyone has access?</p>
                                <p>Can I store the item on an intranet so everyone has access?</p>
                                <p>Can I store the item on an intranet so everyone has access?</p>
                            </div>
                        )
                    }
                    <div className={classNames(classes['accordion-title'], accordionBlock.includes('stage-1') && classes['open'])} onClick={() => showAccordionBlock('item-3')}>
                        <p>Am I allowed to modify the item that I purchased?</p>
                        <Icon id='icon-chevron-down' />
                    </div>
                    {
                        accordionBlock.includes('item-3') && (
                            <div className={classes['info']}>
                                <p>Am I allowed to modify the item that I purchased?</p>
                                <p>Am I allowed to modify the item that I purchased?</p>
                                <p>Am I allowed to modify the item that I purchased?</p>
                            </div>
                        )
                    }
                </div> */}
                <div className={classes['support-info']}>
                    <p className={classes['title']}>You still have a question?</p>
                    <p className={classes['small-text']}>If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!</p>
                    <div className={classes['info']}>
                        <div className={classes['phone-block']}>
                            <div className={classes['icon']}>
                                <Icon id="icon-phone" />
                            </div>
                            <a href="tel:+13024463322">+1 (302) 446-33-22</a>
                            <p>We are always happy to help!</p>
                        </div>
                        <div className={classes['email-block']}>
                            <div className={classes['icon']}>
                                <Icon id="icon-email" />
                            </div>
                            <a href="mailto:surrogacyplatformcom@gmail.com">surrogacyplatformcom@gmail.com</a>
                            <p>Best way to get answer faster!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Support;
