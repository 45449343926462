import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import classes from './style/notifications.module.scss'
import { isMobileOnly } from 'react-device-detect';
import { fetchPopup } from '../../actions/modalActions';
import { useDispatch } from 'react-redux';

const Notifications = ({count}) => {
    const dispatch = useDispatch()

    const showTopUpPopup = () => {
        dispatch(fetchPopup({
            popupType: 'TopUpModal',
            props: {
                className: "top-up"
            },
        }))
    }

    return (
        <section className="notification_section">
            <div className="container">
                <div className={classes["notification"]}>
                    <div className={classes["notification_header"]}>
                        <span className={classes["notification_header_title"]}>Your Credit Too Low!</span>
                        <span className={classes["notification_header_badge"]}>{count} credits left</span>
                        {
                            isMobileOnly ? (
                                <p onClick={showTopUpPopup} className={classes["notification_text_link"]}>
                                    Add Credit
                                </p>
                            ) : null
                        }
                    </div>
                    {
                        !isMobileOnly ? (
                            <div className={classes["notification_text"]}>
                                <span className={classes["notification_text_message"]}>
                                    Please Add Credit to have possibility to chat and other activities on our platform.
                                </span>
                                <p onClick={showTopUpPopup} className={classes["notification_text_link"]}>
                                    Add Credit Now
                                </p>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </section>

    );
}

export default Notifications;
