import React from 'react';

import classes from '../style/singleProgramPage.module.scss'

const Childbirth = () => {
    return (
        <div className={classes['important-info']}>
            <p className={classes['title']}>Childbirth in  Czech Republic / in TRNC / Greece / Portugal – 14 000 Euro</p>
            <p className={classes['title']}>Childbirth in Moldavia / Georgia - 6500 Euro</p>
            <ul>
                <li>Information services and support during the program</li>
                <li>Providing a coordinator (24/7 online on What'sApp, support at all stages, advising on common matters (supermarket, bank, transport, etc.)</li>
                <li>Transfer from/to the airport, organization of timely transfer to the hospital at any time for SM</li>
                <li>Visit to the gynecologist, examination, necessary tests (consultation with a doctor, examination by an obstetrician gynecologist, tests, ultrasound)</li>
                <li>Registration at the maternity hospital</li>
                <li>Presence of a coordinator-interpreter during visiting a gynecologist</li>
                <li>The presence of the coordinator during the birth, translation and provision of all necessary information to the mother during delivery, including the recommendations of medical personnel at all stages of delivery.</li>
                <li>Discharge from the maternity hospital</li>
                <li>Legal services (registration of a child, accompanying during establishment (recognition) of paternity, organization and providing legal interpreter upon recognizing a paternity and getting a child's birth certificate)</li>
            </ul>
            <p>The price does not include:</p>
            <ul className={classes['no-list-style']}>
                <li>- Schengen visa costs - 800 Euro;</li>
                <li>- Tickets for surrogate mother to childbirth country and back home.</li>
            </ul>
        </div>
    );
}

export default Childbirth;
