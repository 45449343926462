import React from 'react';
import PageWrap from '../../../../app/components/PageWrap';

import classes from '../../style/staticPages.module.scss'

const TermsAndConditions = () => {
    return (
        <PageWrap>
            <div className='container'>
                <section className={classes['static-pages-contaniner']}>
                    <h1>Terms and Conditions</h1>
                    <h3>1. Introduction</h3>
                    <p>Welcome to Surrogacy Platform (https://surrogacy-platform.com/). These terms and conditions outline the rules and regulations for the use of Surrogacy Platform's website.</p>
                    <h3>2. General Terms</h3>
                    <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use “Surrogacy Platform” if you do not agree to take all of the terms and conditions stated on this page.</p>
                    <h3>3. License</h3>
                    <p>Unless otherwise stated, Surrogacy Platform and/or its licensors own the intellectual property rights for all material on Surrogacy Platform. All intellectual property rights are reserved. You may view and/or print pages from https://surrogacy-platform.com/ for your own personal use subject to restrictions set in these terms and conditions.</p>
                    <h3>4. You must not:</h3>
                    <p>- Republish material from https://surrogacy-platform.com/<br />
                    - Sell, rent, or sub-license material from https://surrogacy-platform.com/<br />
                    - Reproduce, duplicate or copy material from https://surrogacy-platform.com/</p>
                    <h3>5. User Content</h3>
                    <p>In these website standard terms and conditions, "Your User Content" shall mean any audio, video text, images, or other material you choose to display on this website.</p>
                    <h3>6. Restrictions</h3>
                    <p>You are specifically restricted from all of the following:<br />
                    - Publishing any website material in any other media<br />
                    - Selling, sublicensing, and/or otherwise commercializing any website material<br />
                    - Using this website in any way that is or may be damaging to this website<br />
                    - Using this website in any way that impacts user access to this website</p>
                    <h3>7. No warranties</h3>
                    <p>This website is provided "as is," with all faults, and Surrogacy Platform makes no express or implied representations or warranties, of any kind related to this website or the materials contained on this website.</p>
                    <h3>8. Variation of Terms</h3>
                    <p>Surrogacy Platform is permitted to revise these terms at any time as it sees fit, and by using this website you are expected to review these terms on a regular basis.</p>
                    <h3>9. Governing Law & Jurisdiction</h3>
                    <p>These terms will be governed by and construed in accordance with the laws of the United States, and you submit to the non-exclusive jurisdiction of the state and federal courts located in the United States for the resolution of any disputes.</p>
                    <h3>10. Contact Information</h3>
                    <p>If you have any questions about these Terms and Conditions, you can contact us at <a href="mailto:surrogacyplatformcom@gmail.com">surrogacyplatformcom@gmail.com</a>.</p>
                    <p>By using our website, you hereby consent to our terms and conditions and agree to its terms.</p>
                </section>
            </div>
        </PageWrap>
    );
}

export default TermsAndConditions;
