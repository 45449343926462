import React from 'react';
import ReactPlayer from 'react-player';

import classes from "../style/interviewSection.module.scss";
import classNames from 'classnames';

const InterviewSection = ({primarydonorVideos}) => {
    return (
        <section className={classNames(classes["interview"])}>
            <div className={classes['interview_container']}>
                <h3>
                    Interview
                </h3>
                <div className={classNames(classes['player_wrapper'])}>
                    <ReactPlayer
                        className={classes['interview_video']}
                        url={primarydonorVideos.url}
                        width='100%'
                        height='100%'
                        controls={true}
                        muted={true}
                        playsinline={true}
                    />
                </div>
            </div>
        </section>
    );
}

export default InterviewSection;
