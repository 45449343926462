import React from 'react';

import classes from '../style/mainChars.module.scss'

const MainCharsMobile = ({mainCharsMobile}) => {
    return (
        <div className={classes["compare_2_main_characteristics_mobile"]}>
            <h2>
                Main Characteristics
            </h2>
            <ul>
                {
                    mainCharsMobile && mainCharsMobile.map(item => (
                        <li key={item.label}>
                            <div>
                            <img src={`/cdn/images/donor-profile/${item.icon}.png`} />
                                <span>
                                    {item.label}
                                </span>
                            </div>
                            <span>
                                {item.value0}
                            </span>
                            <span>
                                {item.value1}
                            </span>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

export default MainCharsMobile;
