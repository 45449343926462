import React from 'react';

import classes from '../style/comparedCard.module.scss'
// import { useNavigate } from 'react-router-dom';

const ComparedCard = (props) => {
    // const navigate = useNavigate()
    return (
        <div className={classes["compare_info_card"]}>
            <div className={classes["compare_card_image"]}>
                <div>
                    <img src={props.lady.userAvatar} alt="" />
                </div>
            </div>
            <div className={classes["compare_card_description"]}>
                <div className={classes["compare_card_name"]}>
                    <p className={classes['name']}>{props.lady.firstName}, <span className={classes['age']}>{props.lady.age}</span></p>
                </div>
                <div className={classes["compare_card_characteristics"]}>
                    <div className={classes['hair']}>
                        <p>Hair:</p>
                        <p>{props.lady.hairColor}</p>
                    </div>
                    <div className={classes['eyes']}>
                        <p>Eyes:</p>
                        <p>{props.lady.eyeColor}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ComparedCard;
