import PageWrap from "../../app/components/PageWrap";
import TopSection from "./components/TopSection";
import NewLadiesSection from "./components/NewLadiesSection";
import LadiesSection from "./components/LadiesSection";
import ServicesSection from "./components/ServicesSection";
import FuturesSections from "./components/FuturesSections";
import MapSection from "./components/MapSection";
import NewsSection from "./components/NewsSection";
import ContactSection from "./components/ContactSection";
import FaqComponent from "../../components/Faq/FaqComponent";

export default function MainPage() {
    return (
        <PageWrap>
            <main>
                <TopSection />
                <LadiesSection />
                <NewLadiesSection />
                {/* <ServicesSection /> */}
                <FuturesSections />
                <MapSection />
                <FaqComponent />
                <NewsSection />
                <ContactSection />
            </main>
        </PageWrap>
    )
}
