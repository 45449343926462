import React from 'react';

import classesMain from '../style/aboutUsMainStyles.module.scss'
import classes from '../style/advantageSectionStyle.module.scss'
import classNames from 'classnames';

const AdvantageSection = () => {
    return (
        <section className={classes["adv-section"]}>
            <div className={classNames(classesMain['about-us-container'], 'container')}>
                <h2>Why choose us?</h2>
                <div className={classes['adv-block']}>
                    <div className={classes['item']}>
                        <img src="/cdn/images/main-page/services/egg_donation.svg" alt="" />
                        <p>Life communication with egg donor</p>
                    </div>
                    <div className={classes['item']}>
                        <img src="/cdn/images/main-page/services/surrogate_motherhood.svg" alt="" />
                        <p>Professional medical and juridical support</p>
                    </div>
                    <div className={classes['item']}>
                        <img src="/cdn/images/main-page/services/legislation_services.svg" alt="" />
                        <p>Coordination during all program stages</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AdvantageSection;
