import React, { useState } from 'react';
import PageWrap from '../../../../app/components/PageWrap';

import Icon from '../../../../ui/Icon';

import classes from '../../style/staticPages.module.scss'
import classNames from 'classnames';

const LegislationPage = () => {
    const [accordionBlock, setAccordionBlock] = useState([]);

    const showAccordionBlock = (type) => {
        if (accordionBlock.includes(type)) {
            setAccordionBlock(accordionBlock.filter(item => item !== type));
        } else {
            setAccordionBlock([...accordionBlock, type]);
        }
    }
    return (
        <PageWrap>
            <div className='container'>
                <section className={classNames(classes['static-pages-contaniner'], classes['no-bg'])}>
                    <div className={classes['accordion-block']}>
                        <div className={classNames(classes['title'], accordionBlock.includes('stage-1') && classes['open'])} onClick={() => showAccordionBlock('stage-1')}>
                            <p>United Kingdom legislation on surrogacy</p>
                            <Icon id='icon-chevron-down' />
                        </div>
                        {
                            accordionBlock.includes('stage-1') && (
                                <div className={classes['info']}>
                                    <p>Important Legal Information about surrogate motherhood in UK Surrogacy is legal in the United Kingdom. There are however strict legal guidelines which must be followed. Under the terms of the Surrogacy Arrangements Act 1985, it is a criminal offence to advertise that you are willing to enter into a surrogacy arrangement. Surrogacy UK does NOT initiate, negotiate, commission or facilitate surrogacy arrangements. Most couples and surrogate do not use the services of a solicitor when they are going through a surrogacy arrangement. Some IVF clinics insist they employ a solicitor to take them through the law regarding surrogacy.</p>
                                </div>
                            )
                        }
                        <div className={classNames(classes['title'], accordionBlock.includes('stage-2') && classes['open'])} onClick={() => showAccordionBlock('stage-2')}>
                            <p>French legislation on surrogacy</p>
                            <Icon id='icon-chevron-down' />
                        </div>
                        {
                            accordionBlock.includes('stage-2') && (
                                <div className={classes['info']}>
                                    <p>Surrogacy (GPA) is the act of a woman ("surrogate mother") carrying a child on behalf of a "pair of prospective parents" to whom the child will be delivered after birth. It is a form of medical reproduction that involves implanting an embryo obtained through in vitro fertilisation (IVF) or insemination into the surrogate's uterus. Depending on the methods used, either members of the couple are the genetic parents of the child, the intended parents have only a partial genetic link to the child, or no genetic link to the child.</p>
                                    <p>No binding text on surrogacy has been adopted at the international level. Most European countries, including France, prohibit surrogacy. The civil prohibition originates from the first judgements of the Court of Cassation in 1989 and 1991. The effect of this case law is that a contract "by which a woman agrees, even for free, to become pregnant, bear and then give up a child is contrary to the principles of the inaccessibility of the human body and the inaccessibility of the state of personhood".</p>
                                    <p>The first important judgement on surrogacy in France was issued by the "Cour de Cassation" on 13/12/1989 (No. 88-15-655). In this judgement, the Court decided to completely ban surrogate mothers in France and declared all relevant contracts null and void.</p>
                                    <p>As a legal basis, this judgement referred to Article 1128 of the French Civil Code, which provides that only commercial things can be the subject of a contract. The human body, not being regarded as commercial property, is thus excluded.</p>
                                    <p>On 31.05.1991 (No. 90-20105), the Court of Cassation of the highest instance ruled that the human body is not a commercial object.</p>
                                    <p>In 1994, the French Parliament adopted the Bioethics Act (Act No. 94-653). This law added article 16-7 to the Civil Code, which prohibits the contract of surrogacy. It regards this prohibition as a public policy provision that provides that it is impossible to go beyond the contract.</p>
                                    <p>According to the criminal law point of view, if someone participates in the surrogacy process in France, he or she faces being charged with several offences:</p>
                                    <ul>
                                        <li>the offence of artificial insemination (the fact of carrying out insemination outside a regulated legal medical procedure), prohibited by article 511-12 of the French Criminal Code;</li>
                                        <li>the offence of encouraging the abandonment of a child, prohibited by article 227-12 of the French Criminal Code;</li>
                                        <li>the offence of child substitution (when a woman notes on her birth certificate that she is the mother of a child, the mother of a child from which she was not pregnant) will be punishable under article 227-13 of the French Penal Code.</li>
                                    </ul>
                                    <p>In France, criminal law can only be applied to acts committed on French territory. Consequently, an offence against French criminal law committed in another country that does not prohibit surrogacy cannot be convicted in France.</p>
                                    <p>However, there is a problem with the recognition of foreign birth certificates in France. To summarise the situation, a child born through surrogacy abroad has a birth certificate where the intended parents (mother and father, two dads, two mothers) can be listed. In the case of suspected GPA, France has long rejected these transcriptions. The Court of Cassation forced France to do so for a period of time. But as a result, the government, in its reform of the bioethics law, imposed to go through the adoption procedure or the exequatur of the adoption decision to establish the origin of the child.</p>
                                    <p>Thus, in 2008 and again in 2011, the Court opposed the recording in French civil registries of birth certificates and refused to recognise in French law the parentage established between children born to a surrogate mother in California and their intended parents, considering that this non-transcription "does not deprive the children of the maternal and paternal parentage that the law of California recognises, nor prevents them from living with their parents in France, nor violates the right to respect for the private and family life of these children within the meaning of Art.</p>
                                    <p>On the other hand, on 04/05/2011 (n°348 On 4/05/2011 (n°348778), the Council of State took the opposite position and considered that the fact that the children were conceived through a surrogacy contract, which is invalid as a matter of public policy in France, "does not affect the obligations imposed on the administration", in accordance with the provisions of article 3-1 of the Convention on the Rights of the Child. According to this Convention, "in all decisions concerning children, whether taken by public or private social welfare institutions, courts, administrative or legislative bodies, the best interests of the child shall be a primary consideration". The Council of State has ruled that consulates must issue a "pass" to a child born abroad to a surrogate mother to allow the child to reside in France with the intended parents.</p>
                                    <p>Parents of children born to a surrogate mother then found themselves in a difficult situation, placing their children in an uncertain legal situation and depriving them of certain prerogatives, especially during certain events such as the parents' divorce or the death of their child. The circular of 25 January 2013 recommended that the chief clerks of district courts, public prosecutors and public prosecutors should facilitate the issuance of certificates of French nationality to children born abroad in application of article 47 of the Civil Code, according to which "any civil status certificate of French and foreigners drawn up in a foreign country and drawn up in the forms used in that country is authentic".</p>
                                    <p>On 17.05.2013, France allowed same-sex couples to marry, allowing them access to adoption proceedings. This has had several implications for the legal perception of surrogacy in France, combined with supranational decisions.</p>
                                    <p>In a decision of 13 September 2013 (No. 1092), the Court of Cassation further strengthened its position by favouring public policy over the interests of the child: 'the refusal to enumerate a birth certificate is justified, made in a foreign country and drawn up in the forms used in that country, when the birth is the result, in violation of French law, of a common process, including an agreement to become pregnant on behalf of others, an agreement which, even if lawful abroad, is invalid as invalid public In the same judgement, the Court of Cassation endorsed the decision of the Court of Appeal for having concluded that the law on the invalidity of the recognition of a child by the father-to-be was falsified.</p>
                                    <p>26.06.2014 The European Court of Human Rights has issued two judgements on the issue of surrogate mothers in France. These are Menesson v. France and Labassé v. France. Both judgements found that France had breached Article 8 of the European Convention on Human Rights (ECHR), which recognises the right to privacy of children. Based on the best interests of the child and the right to privacy, the Court held that the refusal to transcribe a foreign birth certificate issued abroad, based on the fact that the alleged father was not the real father, constituted an obstacle to the recognition of the child's paternity and therefore a violation of Article 8 of the ECHR.</p>
                                    <p>However, the Court did not condemn France for banning surrogacy, considering that it was a matter of state sovereignty (margin of appreciation doctrine).</p>
                                    <p>On 12.12.2014, the Council of State again decided to protect the interests of the child. Indeed, a circular published by the French government asked consulates to issue certificates of French nationality to children born abroad to parents with French nationality. As defined in the French Civil Code (Article 18), any person with at least one French parent is French.</p>
                                    <p>The Association Against Surrogacy appealed the circular to the State Council. Again, the court ruled on the basis of the best interests of the child. And in particular the right to private life (Article 8 of the ECHR). We can see the connection of this judgement with the 2014 decisions of the European Court of Human Rights.</p>
                                    <p>A few months later, the Court of Cassation ruled on 03.07.2015 (judgement no. 14-21223) that surrogacy abroad is not a fraud against the law, requiring the transcription of the act of birth abroad if it complies with the provisions of the French Civil Code (Article 47). However, all these changes, which are in favour of the child's interests, have not been fully taken into account by some French courts. Because the reference to the presumed father instead of the biological mother is considered (and remains) an obstacle to full transcription.</p>
                                    <p>21.07.2016 The European Court of Human Rights again condemned France in the case of Fulone Bouvet v France. Again the judgement was based on the child's right to privacy. The Court ruled that France had not sufficiently addressed the obligation to transcribe the birth certificate. In 2015, for example, the Rennes Court of Appeal did not agree to rewrite the birth certificate if it listed the alleged father. The European Court of Human Rights condemned France again on 19.01.2017 on the same basis in the judgement in the case of Labory v. France.</p>
                                    <p>On 18.11.2016, the French legislator introduced articles 452-1 to 452-6 to the Code of Judicial Administration. These articles allow to ask the consulate to rewrite, among other things, the birth certificate, which was the subject of a refusal by France before the European Court of Human Rights.</p>
                                    <p>On 05.07.2017, the Cour de cassation (No. 16-16455) ruled in favour of simple adoption (as opposed to full adoption) of children born by surrogacy, if the surrogate mother and father consent. In addition, the court ruled on a partial copy of the birth certificate (Decision No. 16-16901). The court allowed only the recognition of the biological father.</p>
                                    <p>10.04.2019 The European Court of Human Rights issued an advisory opinion on the state of surrogacy in Europe. The Court recognises the lack of consensus. But it believes that on the basis of respect for privacy, states should allow recognition of descent from the intended mother. The Court recognises that France has the right not to recognise the birth certificate of a child born from surrogacy abroad, asking it to recognise the filial relationship by a third-party method. </p>
                                    <p>31.07.2019 The Council of State (Decision No. 411984) has sent a reminder to the Minister of Interior. Stressing that a foreign birth certificate of a child, even if it is not recognised, indicates the relationship with the said parents. But the Court of Cassation on 10.04.2019 recognised the full transcript of such certificate.</p>
                                    <p>In a judgment of 7 April 2022, the ECtHR held that the refusal of the French domestic courts to lawfully establish the applicant's paternity in respect of his biological son born by surrogacy in France, after the surrogate mother had entrusted the child to the couple. compatible with the applicant's right to respect for his private life. compatible with the applicant's right to respect for his private life. The Court held that "establishing the applicant's biological paternity would have respected the best interests of the child", but the domestic courts had failed to fulfil their duty of exceptional diligence as regards the length of the procedure. At the end of the procedure On 2 August 2021, the French Parliament finally adopted the Bioethics Law, with its main provision on the right to in vitro fertilisation (IVF) for all women, including single women.</p>
                                    <p>On 2 August 2021, the French Parliament finally adopted the Bioethics Act, with its main provision on the right to in vitro fertilisation (IVF) for all women, including single women and women in same-sex marriages. Previously, only heterosexual couples had this right in case of medical problems related to infertility. Under the new law, all women up to the age of 43 are eligible for IVF. The procedure will also be covered by the compulsory health insurance system (Sécurité sociale).</p>
                                    <p>This law also regulates complex issues related to stem cell research, organ donation, gamete preservation, and cancels the previous anonymity of sperm and gametes. Now newborns will be able to find out who their biological parents are.</p>
                                    <p>The law touched upon the rules for establishing the parentage of children for civil registration and the adoption of children born outside the country through surrogacy on behalf of French citizens. Couples will have to adopt children within three years of birth. Recognition of paternity abroad is assessed according to French law. The transcription of a foreign civil status document for a child born via surrogacy is thus limited to the biological father only (the presumed father must undergo an adoption procedure). The law is therefore more restrictive than the practice of the Court of Cassation.</p>
                                    <p>However, surrogacy is still prohibited in France. Moreover, Thibaut Bazin, a member of the National Assembly, has introduced a bill to enshrine the prohibition of surrogate mothers in the French Constitution. The text includes a single article concluding Section VIII of the Constitution: "No one may resort to surrogacy".</p>
                                    <p>Thus, due to the lack of uniformity on surrogacy issues between the French judiciary, legislature and executive, France's negative position on the legalisation of surrogacy will not change in the short term.</p>
                                </div>
                            )
                        }
                        <div className={classNames(classes['title'], accordionBlock.includes('stage-3') && classes['open'])} onClick={() => showAccordionBlock('stage-3')}>
                            <p>Legislation on surrogacy in Italy</p>
                            <Icon id='icon-chevron-down' />
                        </div>
                        {
                            accordionBlock.includes('stage-3') && (
                                <div className={classes['info']}>
                                    <p>Italian legislation in the field of assisted reproductive technologies is rather conservative. The Italian Law of 19 February 2004, No. 40 "On the Norms of Assisted Reproductive Technologies", not only completely prohibits surrogacy, but also severely restricts other reproductive technologies (art. 12, 6th paragraph, Law No. 40/2004, containing the rules on medical insemination techniques). This law prohibits third party reproductive programs, i.e. surrogacy and donation, as well as embryo cryopreservation.</p>
                                    <p>Any advertising of surrogacy is also prohibited. For organising and assisting in the implementation of such a method of childbirth, Italian law provides for liability - imprisonment for a term of three months to two years and a fine, which can reach up to one million euros.</p>
                                    <p>However, in accordance with the same Italian Law No. 40 of 19 February 2004 "On the norms of assisted reproductive technologies" , the use of in vitro fertilisation in Italy is absolutely legal, although it has a number of conditions and restrictions. Thus, single people are not treated for infertility - the law allows artificial insemination only for couples who are legally married. For the in vitro fertilisation procedure, an infertile couple must provide a certificate of infertility, after which the spouses can count on in vitro fertilisation, which is carried out at the expense of the state. However, only the genetic material of the husband and wife can be used for fertilisation - it is illegal for egg and sperm donors to participate in the procedure. If, despite the ban, donor cells are still used, the biological parents will not be able to register parental rights to the child.</p>
                                    <p>Given the difficulties Italians face with fertility treatment, it is not surprising that infertile couples are forced to travel to other countries where legislation allows surrogacy and germ cell donation. Previously, the Italian Government did not prohibit its citizens from utilising surrogacy services outside the country. However, Italian government initiatives now aim to make surrogacy a "universal offence" and cannot even be practised abroad. In addition to secular legislation, surrogacy within Italy is also opposed by the Roman Catholic Church, which has a great influence on public opinion. Thus, since surrogacy is legal in the USA, Canada, the UK, Denmark, Portugal and elsewhere, an Italian couple can resort to it in these countries, but they will have difficulties when they return to Italy.The official registration of a child born abroad through surrogacy with same-sex couples in Italy's registries is particularly challenging due to government initiatives. Same-sex civil unions were legalised in Italy in 2016, overcoming opposition from Catholics and conservative groups. However, the court did not give these couples the right to medically support childbearing and adopt children, fearing it would encourage surrogate pregnancies, which remain illegal in the country.</p>
                                    <p>Previously, it was largely up to local authorities in Italy to decide whether specialised bodies would register births to same-sex partners. In October 2021, a Milan court handed down a judgement requiring the city to register children of same-sex fathers born to a surrogate mother, arguing that the child had no control over the circumstances of his or her birth.</p>
                                    <p>However, in March 2023, Italian authorities banned the practice and since then the names of some parents have been struck from the city's registers. To date, 33 birth certificates of children born to lesbian couples back in 2017 have been challenged by Italian prosecutors. A way out of the situation may be for single persons to apply for adoption in special cases, such as:</p>
                                    <p>a) a special case where the application is from persons who are related by blood up to the sixth degree or have a pre-existing stable and enduring relationship where the child is orphaned by both father and mother; <br />
                                    b) a special case where the application is made by either spouse if the child is their own child, including adopted children or children of the other spouse;<br />
                                    c) a special case where the child is disabled and orphaned by both parents;<br />
                                    d) a special case where it is impossible to provide for a long period of time and the child has been orphaned from both parents. In all "special case" adoption proceedings, the competent court must be satisfied that the adoption is in the best interests of the child. Same-sex couples who are married or simply living together are only allowed to adopt their biological children.
                                    </p>
                                    <p>There are numerous judicial decisions of the European Court of Human Rights and Italian courts concerning the status of children born abroad through surrogacy contracts concluded by Italian citizens.</p>
                                    <p>The decision of the Bari Court of Appeal of 13 February 2009 (App. Bari, 13 February 2009) dealt for the first time with the recognition in Italy of the status of parentage created abroad through the surrogacy process. It ruled on the recognition of the parental relationship of two minor children born in the United Kingdom in favour of the Italian putative mother, specifying that, for the purposes of the declaration in Italy of "parental orders" issued in the United Kingdom, the current statutory prohibition of surrogacy and the principle of favouring "biological" over "social" motherhood are not in themselves indicators of opposition to public order. Furthermore, the best interests of the minor must be prioritised (Article 3 of the Convention on the Rights of Children and Adolescents).</p>
                                    <p>By judgement of the Court of Cassation n. 24001/2014, the Supreme Court confirmed the status of an adopted child who was born in Ukraine from a surrogate mother and was not genetically related to either parent (married heterosexual couple, Italian citizenship) and refused to recognise the status in Italy of filiationis.</p>
                                    <p>The Strasbourg Court in the double judgments of Mennesson and Labassee v. in 2014 established the obligation of contracting states to recognise the status of a child born lawfully abroad after surrogacy on the basis of the right to respect for private life enshrined in Article 8 ECHR, given that this prerogative includes the right of every person to establish the details of his or her identity as a human being, including kinship relations. While recognising the broad limits of discretion for certain States in the matter of pregnancy for others, the Court acknowledged that the above limit had been exceeded by the lack of legal recognition of a kinship relationship between the child born and the putative father, where the latter was also the biological father.</p>
                                    <p>The European Court of Human Rights judgment of 24.01.2017 "Case of Paradiso and Campanelli v. Italy" (complaint No. 25358/12) on appeal against the applicants' complaint that the measures taken by the Italian authorities, which led to the final removal from their family of a child conceived using assisted reproductive technologies and born by a surrogate mother on the territory of the Russian Federation, violated their rights to respect for private and family life. There was no violation of the requirements of article 8 of the Convention for the Protection of Human Rights and Fundamental Freedoms in the case.</p>
                                    <p>It should be noted that Paradiso and Campanelli v. Italy (2015), in which the Italian authorities objected to the registration of a child born in Russia through "heterologous double" surrogacy, i.e. not genetically related to either of the two intended parents (a heterosexual and married Italian couple), did not resolve the issue of the compatibility of this refusal with the Convention on procedural grounds alone (non-exhaustion of national remedies).</p>
                                    <p>Following some previous decisions on the recognition in Italy of foreign parental relationships resulting from surrogacy, Italian jurisprudence has developed two different legal trends. On the one hand, due to the duties of protection established by Art. 8 ECHR, derived from the principle of Mennesson c. France and Labassee v. France, the Italian legal system recognises parentage only in relation to the genetic father (i.e. the male partner of a heterosexual or homosexual couple).</p>
                                    <p>On the other hand, the negative attitude towards the recognition of parental relationship in relation to an intentional non-biological father persists for public policy reasons related to the underestimation of the institution of surrogacy, imposing, alternatively, on intentional paternity the possibility of restoring parental ties in Italy by applying to the Italian judicial authority for "adoption in individual cases" in accordance with art. 44, lit. d, of Law no. 184/1983 ( Cass, single section, judgement of 8 May 2019, para. 12193).</p>
                                    <p>The Italian Constitutional Court issued judgement no. 33/2021, where it emphasised that the practice of surrogacy "unbearably offends the dignity of women and deeply undermines human relations". But at the same time authorised the Italian legislator to intervene in this matter as soon as possible in order to achieve at the normative level the balance required by the various interests.</p>
                                    <p>Current trends are such that the Italian government wants to outlaw surrogacy carried out abroad and limit the rights of same-sex parents, who are very concerned about such intentions. The Italian parliament is debating a bill that aims to criminalise surrogacy performed abroad. In addition, the government is also tightening its stance towards same-sex families. Municipalities that have so far issued birth certificates recognising same-sex parents have been advised not to do so. If the bill is passed, couples who have used surrogacy services abroad could face up to 2 years in prison.</p>
                                    <p>The initiative is part of a wider problem in Italy, where there is no law recognising children of same-sex couples. Because only one parent is recorded on a child's birth certificate, the legal status of the other remains uncertain until the long and costly adoption process is finalised.</p>
                                    <p>Critics of the bill said extending the ban to people who use surrogate mothers abroad is impractical and unconstitutional.</p>
                                    <p>We are closely monitoring all changes to the legislation and will provide timely updates on any developments. We understand the importance of this issue to our clients and ensure that we provide current and reliable information so that you can make informed decisions.</p>
                                </div>
                            )
                        }
                        <div className={classNames(classes['title'], accordionBlock.includes('stage-4') && classes['open'])} onClick={() => showAccordionBlock('stage-4')}>
                            <p>Surrogacy in Germany - legislation</p>
                            <Icon id='icon-chevron-down' />
                        </div>
                        {
                            accordionBlock.includes('stage-4') && (
                                <div className={classes['info']}>
                                    <p>In Germany, surrogacy is regulated by the Embryo Protection Act (EschG), passed in 1991. This law restricts the use of reproductive technologies for purposes that could lead to surrogacy by making it illegal to implant an embryo in a woman who has no intention of keeping the child with her after birth (surrogate mother).</p>
                                    <p>According to section 1, paragraph 1, No. 7 of the Act, it is a criminal offense to carry out surrogacy operations, with penalties ranging from three to five years' imprisonment for the medical practitioner who carries it out. This severity is primarily due to the fact that German law considers surrogacy to be a violation of ethical and moral principles.</p>
                                    <p>For the prospective parents and the prospective surrogate mother, the personal disposition in section 1, paragraph 3, paras. 1 and 2 ESchG. The surrogate mother and the intended parents do not commit a criminal offense under the Embryo Protection Act (section 1 paragraph 3 no. 2 ESchG and section 14 b paragraph 3 AdVermiG).</p>
                                    <p>Thus, the prohibition is primarily aimed at doctors and scientists because of the requirement to meet certain professional qualifications. Doctors can also sometimes be penalized for complicity (section 25, paragraph 2, section 27, paragraph 1 of the Penal Code) as well as for incitement (section 26 of the Penal Code).</p>
                                    <p>Taking the side of the surrogate mother, the law prohibits any kind of commercial activity in this field. Any contract between the genetic parents and the surrogate mother is contrary to the morals of society and is not legally enforceable in Germany according to Section 138 of the German Civil Code (BGB).</p>
                                    <p>Section 13 of the Adoption Act (AdVermiG) prohibits the use of surrogate mothers. Pursuant to Section 14b, paragraphs 1 and 2 of the AdVermiG, any person who implants an embryo of a surrogate or receives (or accepts) a promise of financial gain from the use of a surrogate is liable to prosecution.</p>
                                    <p>In addition, the provisions of §13a-13d of the "Surrogacy Intermediary Prohibition Act" define surrogacy and describe the relationship between the surrogate mother and the child's prospective parents, and establish a prohibition on the submission of advertisements to the media.</p>
                                    <p>Violation of the legal provisions prohibiting the promotion of surrogacy is criminalized by imprisonment of up to one year or a fine (§ 14b AdVermiG).</p>
                                    <p>However, Section 236 of the Criminal Code does not apply to the foreign offenses listed in Section 5 and 6 of the German Criminal Code. Thus, the provision does not apply to surrogacy that has been carried out abroad in Germany.</p>
                                    <p>In vitro fertilization (IVF), on the other hand, is permitted in Germany and is regulated by the Embryo Protection Act (Embryonenschutzgesetz - EschG), which imposes strict restrictions on the handling of embryos in the IVF process. The Embryo Protection Act prohibits the creation of embryos for any purpose other than direct insertion into the uterus of the woman from whom the eggs were taken, thus excluding the use of surrogate mothers. In addition, the law prohibits any manipulation of embryos that is not intended to produce a pregnancy in the woman who provided the eggs.</p>
                                    <p>IVF is authorized only for the treatment of infertility in married couples or couples in stable partnerships. The law also prohibits the creation of more embryos than can be used in one treatment cycle and places restrictions on egg and sperm donation.</p>
                                    <p>According to the Sperm Donation Act in Germany, as of July 1, 2018, all information about the sperm donor is included in a special registry, where, upon reaching the age of 16, any child born through ART technology will be able to obtain information about its genetic father (sperm donor). However, this does not give any parental rights to the sperm donor, it simply entitles the person born with the help of ART to know about his or her ancestry.</p>
                                    <p>According to German law, the following options are available to establish paternity status and German citizenship for a child born as a result of surrogacy in a country where it is permitted:</p>
                                    <h3>1) A court decision abroad and its subsequent recognition in Germany</h3>
                                    <p>In countries where surrogacy is legal, the prospective parents - confirmed by official or court decisions - acquire the status of mother and father of the child born by the surrogate mother. In decisions of December 10, 2014, XII ZB 463/13, and decision of September 5, 2018, XII ZB 224/17, the Federal Court of Justice decided that a foreign decision confirming parental status is generally required to recognize biological parents as parents, even under German law. This involves a comprehensive examination of the best interests of the child.</p>
                                    <p>It is not sufficient to simply provide a birth certificate or an entry from the Birth Registration Book.</p>
                                    <p>As far as the mother is concerned, the legal assessment of surrogacy under foreign law differs from German law because paragraph 1591 of the Civil Code is summarized as follows: "The mother of the child is the woman who gave birth to it". The law allows no deviation or interpretation. Thus, the woman who provided her ovum is not legally related to the child born.</p>
                                    <p>According to §108 of the Family Proceedings and Voluntary Jurisdiction Act (FamFG), foreign court decisions on the establishment of parental rights are recognized in Germany. The German Supreme Court has ruled that the German authorities must respect the decisions of foreign authorities regarding the parental rights of German expectant parents, even if surrogacy is not legal in Germany.</p>
                                    <p>However, there is an exception to this rule - the public policy clause (ordre public Vorbehalt) contained in §109 I 4 FamFG makes the recognition of a foreign court decision inadmissible if "the decision would be manifestly incompatible with the basic principles of German law, in particular would be contrary to fundamental rights".</p>
                                    <p>Thus, the German law enforcer needs to determine whether the foreign judgment conflicts with the German legal concept of fairness. It should be borne in mind that the application of the public policy clause to the recognition of foreign judgments should be particularly restrained, since judgments have a certain "guarantee of trustworthiness" such that their disregard has a significant impact on relations between States within the meaning of private international law.</p>
                                    <h3>2) Recognition or determination of paternity</h3>
                                    <p>According to German law, the genetic father of the desired child can publicly acknowledge his paternity with the consent of the surrogate mother before the birth of the child. If the surrogate is unmarried, it will be possible to recognize the paternity of the biological (genetic, putative) father at the embassy (consulate) according to a specific procedure. There is a special declaration that can be made at the embassy in two different ways: before or after the birth of the child.</p>
                                    <p>In the first case, the alleged father who has a genetic link to the child must legally recognize the child at the German Embassy (Consulate), during the pregnancy of the surrogate mother with the consent of the surrogate mother. This recognition must be publicly confirmed (usually through information sheets on German Embassy/Consulate websites). Once the child is legally recognized by the putative father, who is a German citizen, then the (unborn) child automatically acquires German citizenship after birth.</p>
                                    <p>In the second case, the putative father does the same, but after the birth of the child and also with the personal presence of the surrogate mother at the German Consulate (to obtain her consent). In both cases, it must be clear that the surrogate mother has voluntarily decided to bear the child and transfer custody and all parental rights to the putative father. A hearing is not necessary to prove this, a statement is sufficient.</p>
                                    <p>However, if the surrogate is married, the surrogate's husband is usually named as the father of the child (section 1592 no. 1 BGB). Despite this, the genetic father may try to challenge the existing legal paternity in order to obtain paternity himself through a court order.</p>
                                    <p>Once paternity is recognized and the child's citizenship is established, the future parents will need to obtain the child's passport in order to travel to Germany.</p>
                                    <h3>3) Adoption after surrogacy</h3>
                                    <p>From a legal point of view, parenthood under German law is divided into paternity and maternity. While paternity can be disputed, recognized or established, for maternity the principle "mater semper certa est" applies. According to §1591 BGB, a woman who has given birth to a child is recognized as the mother. Unlike paternity, maternal status cannot be disputed or recognized. It follows that a woman who decides to use the services of a surrogate mother will not be able to become the mother of the child in the legal sense. This is based on the decision of the legislature on the issue of surrogacy in Germany.</p>
                                    <p>In this case, the option of adopting the child applies. However, if the intended parents do not have a genetic link to the child, adoption is complicated. The intendedparents may adopt the child together to preserve the legal position of parents. However, the contract between the intended parents and the surrogate mother, which regulates the granting of appropriate consent for adoption, is invalid under German civil law.</p>
                                    <p>It is for the family court to decide on the adoption. If, as is often the case, the genetic father has also become the legal father of the child, his wife can subsequently adopt "his" child. As a result, the child is considered to be the joint child of the intended parents.</p>
                                    <p>Adoption is allowed only if it is in the best interest of the child. Sections 1741-1772 BGB and sections 186-199 of the Family and Voluntary Proceedings Act (FamFG) have additional rules and requirements regarding adoption and its consequences, as well as regarding the possibility of annulment of an adoption.</p>
                                    <p>This position is reflected in the ECtHR Judgment and Decision of the Federal Supreme Court of Germany (Bundesgerichtshof, BGH) of 20.04.2019, rendered in the case of a child born from a surrogate mother in Ukraine and handed over to a German couple who brought the child to Germany. The origin of the child was established in accordance with German law, defined as applicable law. According to this applicable law, the father of the child was recognized as the man to whom the child was transferred and with whom he is biologically related. The legal mother of the child is the woman who gave birth to the child. She is therefore the one who should be entered in the civil registry. In order to obtain the status of mother, the wife of the child's father had to undergo the adoption procedure provided for by local legislation.</p>
                                    <h3>4) Recognition of a foreign birth certificate</h3>
                                    <p>According to German paternity law, if a child returns to Germany with his (presumed) parents immediately after birth, then the newborn child does not receive a "habitual residence" in the country of his birth (BGH, decision of March 20, 2019, XII ZB 530/17, NJW 2019, 1605). Thus, the paternity of the child is governed by German law according to his or her "residence statute" (Art. 19 para. 1 sentence 1 EGBGB).</p>
                                    <p>In such a case, paternity is not based on the decision of the administrative authorities of the state in which the child was born by the surrogate mother, but is based on the "Paternity Act" and on German family law. In such a case, the Birth Certificate in which the genetic mother is listed as the mother of the child is not recognized in Germany, but only the surrogate mother is legally the mother of the child (§ 1591 BGB).</p>
                                    <p>The legal situation of the genetic father is somewhat better, as he has the possibility, with the consent of the surrogate mother, to recognize his paternity. And the intended mother, who will thus not become a mother in Germany, only has the possibility of adoption. But there are exceptions if the foreign law is to be applied and taken into account in Germany.</p>
                                    <p>This is possible if the paternity of the child is determined according to the country of birth of the child (Art. 19 para. 1 sentence 2 EGBGB). The child's parents can then be identified from the birth certificate issued in the child's country of birth. Either both intended parents or only one of them is indicated as the child's parents.</p>
                                    <p>Therefore, in our opinion, it is advisable to either immediately adjudicate in the country of birth of the surrogate mother and have it recognized in Germany or, if this is not possible, not to return with the child to Germany after the birth of the child, but to live in the country of birth for a few months in order to obtain "ordinary residence" there. In these cases, the official proof of birth (Birth Certificate) will be sufficient for the recognition of maternity.</p>
                                    <p>In the case of surrogacy, this means that the intended mother becomes the legal mother despite the (different) legal situation in Germany.</p>
                                    <p>If one or both of the named parents are listed on the child's birth certificate as the child's parents and if at least one of the parents has German citizenship, they can apply to the civil registration office under section 36 PStG to enter the child's birth into the German birth register. The content of the entry is the result of section 21 PStG. Pursuant to section 21, paragraph 1, no. 4 of the PStG, the names of the parents must also be entered.</p>
                                    <p>The civil registration authority in whose area of responsibility the foreign-born person resides or has recently resided or has his or her habitual residence is responsible for the certification. If the civil registration authority refuses to register, the parents may apply to the family court to instruct the authority to carry out the registration. In case of doubt, the civil registration authority can also obtain a court order that an official act must be carried out (section 49 PStG).</p>
                                    <p>It is important to note that Germany has strict regulatory and ethical standards regarding reproductive technologies and these are regularly reviewed and updated.</p>
                                    <p>Recently, however, the German legal system has outlined positive developments in favor of legally permitting the use of altruistic surrogacy</p>
                                    <p>The European Court of Human Rights confirmed this in the judgment in Dixon v. the United Kingdom concerning Article 8 (1) ECHR, that the use of "artificial insemination techniques" is part of "private and family life". This means that it is not inherent in the Basic Law to limit this right to natural modes of reproduction only. On the contrary, fundamental rights must be in line with new social and technical developments and therefore remain open and capable of growth, rather than being shaped by a "state image of the ideal family".</p>
                                    <p>In the coalition agreement for 2021-2025, the SPD, the Greens and the FDP agreed to study the legalization of altruistic surrogacy, i.e. those surrogacies that are not carried out for commercial reasons. The Commission on Reproductive Self-Determination and Reproductive Medicine is currently working on possible solutions to this situation.<br />
                                    Head of judicial department «Success» surrogacy centre</p>
                                </div>
                            )
                        }
                        <div className={classNames(classes['title'], accordionBlock.includes('stage-5') && classes['open'])} onClick={() => showAccordionBlock('stage-5')}>
                            <p>Surrogacy in Spain. Summary of legislation in the field of surrogacy</p>
                            <Icon id='icon-chevron-down' />
                        </div>
                        {
                            accordionBlock.includes('stage-5') && (
                                <div className={classes['info']}>
                                    <p>The legal regulation of surrogacy in Spain is considered from two aspects. On the one hand, a number of laws and regulations explicitly point out the illegality of this procedure in Spain. On the other hand, there is an integration of Spanish regulations and court decisions in the direction of recognizing children born through surrogacy in Spain, but through a legally defined legal procedure.</p>
                                    <p>Let us consider these approaches. Surrogacy in Spain is not legally permitted and is recognized as illegal because, as the European Parliament determined in 2015, surrogacy "undermines the human dignity of the woman because her body and its reproductive functions are used as a commodity."</p>
                                    <p>This position of the European Parliament is reflected in a number of Spanish laws and regulations. The first mention of surrogacy is contained in the Law of November 22, 1988 "On Artificial Insemination Methods" and confirmed by Law No. 14 of May 26, 2006. "On Assisted Human Reproduction Methods", which establishes the prohibition of surrogacy.</p>
                                    <p>In particular, Article 10 of Law 14/2006 of May 26, 2006 states that.</p>
                                    <p>"1. The contract by which consent to conception, with or without payment, is given by a woman who renounces maternal parentage in favor of the contracting party or a third person is null and void.<br />
                                    2. The parentage of children born as a result of surrogacy shall be determined by birth.<br />
                                    3. In accordance with the general rules, actions to establish paternity may be brought by the biological father."</p>
                                    <p>Under this provision, surrogacy contracts are null and void, regardless of whether a woman receives remuneration for providing her body to carry a child and for abandoning motherhood. The above does not mean, of course, that the child has no de jure parents. According to Spanish law, the mother will be the woman who gives birth to the child, and the father will be the biological father, who will be identified as such in a court decision. The second parent will have to resort to the adoption procedure.</p>
                                    <p>Article 221 of the Spanish Penal Code also establishes that persons who, through economic compensation, transfer a child to another person without a parental relationship, avoiding the legal procedures of guardianship, placement or adoption, in order to establish a similar link to paternity, will be punished by imprisonment of one to five years and the inability to exercise parental rights, guardianship or custody for a period of four to ten years (part 1), the same sanction being applied to the person who receives the child as a parent. It is noteworthy that no criminal cases have been filed to date.</p>
                                    <p>In addition, the situation of surrogacy in Spain is regulated by relevant decrees (Decree of November 14, 1958, approving the Regulations of the Civil Registry Act), Supreme Court decisions and two instructions of the Directorate General of Registries and Notaries (today Legal Security and Public Faith):  Instruction of the General Directorate of Registries and Notaries of October 5, 2010 "On the Procedure for the Registration of the Kinship of Persons Born as a Result of Surrogacy", Instruction of the General Directorate of Registries and Notaries of February 18, 2019 "On Updating the Regime for the Registration of the Kinship of Persons Born as a Result of Surrogacy".</p>
                                    <p>Currently, there are many inconsistencies and provisions in Spanish law that do not meet the practical needs of surrogacy. Therefore, if a Spanish couple wishes to perform surrogacy, they are forced to seek the service in another country's jurisdiction. We emphasize that the surrogate mother is never an egg donor, i.e. she does not give her genetic material to the child. This factor subsequently allows for the legal transfer of the child to the genetic parents. However, after the birth of the child, legally the kinship of the child and the biological parents is not recognized in Spain. This creates additional difficulties with the procedure of legitimization of the rights of the parents, since the child is usually registered in the place of birth and not in Spain.</p>
                                    <p>In Spain it is currently only possible to register the child with the father, who is usually also the biological parent, and the mother must apply for adoption in Spain after the surrogate mother has relinquished the child.</p>
                                    <p>According to the Instruction of the Directorate General of Registries and Notaries of October 5, 2010, "On the procedure for registering the parentage of persons born as a result of surrogacy", the birth and paternity of a minor born in a foreign country through surrogacy may be registered in the Spanish Civil Registry under the following conditions:</p>
                                    <p>- Together with the application for registration of the minor's birth, a court decision of the competent court determining the paternity of the child must be submitted.<br />
                                    - In addition, the requirements set forth in this Instruction on foreign judgments must be complied with. In this case, unless an international convention is applicable, the foreign judgment must be subject to exequatur.</p>
                                    <p>Exequatur is a set of rules according to which the legal system of a State verifies the compliance of a judgment rendered by a court of another State with the requirements allowing its recognition and homologation.</p>
                                    <p>Thus, in order to proceed with birth registration, the application for registration and the court order terminating the aforementioned exequatur procedure must be submitted to the Spanish Civil Registry.</p>
                                    <p>It is possible to register in the Spanish Civil Registry without submitting the exequatur of a foreign judgment, provided that the foreign judgment arose in a procedure similar to the Spanish voluntary jurisdiction procedure.</p>
                                    <p>In these cases, the person in charge of the civil registry will check, as a preliminary requirement for registration, whether the judgment can be recognized in Spain.</p>
                                    <p>The person in charge of the Spanish civil registry will carry out an additional check of the foreign judgment and verify:</p>
                                    <ul>
                                        <li>the correctness and formal validity of the foreign judgment and other documents submitted;</li>
                                        <li>whether the court of the country of birth bases its international judicial jurisdiction on criteria equivalent to those provided for in Spanish law;</li>
                                        <li>whether the procedural rights of the parties, in particular the surrogate mother, are guaranteed;</li>
                                        <li>whether the interests of the minor and the rights of the surrogate mother have not been violated. In particular, it must be ascertained that the latter's consent was freely and voluntarily obtained without error, fraud or violence, and that she has sufficient natural capacity;</li>
                                        <li>that the judgment is final and that the consents in question are irrevocable or, if they are subject to a revocability period under applicable foreign law, that period has expired and the person who recognized the right of revocation has not exercised it.</li>
                                    </ul>
                                    <p>It is not possible to register the birth and descent of a minor by certifying a foreign registration or a mere declaration of registration of the child or of the affiliation of a future child in favor of a contracting party or a third party.</p>
                                    <p>In connection with the above, it is evident that the criteria of the Directorate General of Legal Security and Public Faith (part of the Ministry of Justice)and the Spanish Supreme Court are different.</p>
                                    <p>In the first case, if the establishment of kinship in favor of the principal is based on a judicial decision issued by a competent foreign court, such kinship may be recognized and registered in Spain (Instruction of October 5, 2010, ratified by the Instruction of February 18, 2019).</p>
                                    <p>On the other hand, according to the Spanish Supreme Court, surrogacy is contrary to public order because it constitutes an attack on the dignity of the child (who is objectified and turned into a business object, being deprived of his or her family status) and the gestational mother (whose reproductive functions are commercialized and whose status, often out of economic necessity, is exploited by third parties), resulting in a filiation in favor of the person who entered into the surrogacy contract that cannot be registered</p>
                                    <p>This conflict will lead to the application of article 10 of Law 14/2006, referred to earlier: the woman who has given birth to a child will be considered a mother even if she does not wish to be one.</p>
                                    <p>The same principle applies when, at the end of the surrogacy process (after the delivery), the parents are faced with the problem that their child does not have the nationality and passport of any country. Consequently, the parents and the child cannot travel to Spain until this problem is resolved. On the other hand, the Spanish Embassy asks for:</p>
                                    <ul className={classes['num']}>
                                        <li>1) a document recognizing parental rights from the genetic (legal) father;</li>
                                        <li>2) documents of relinquishment of the child from the surrogate mother although this has already been done in the country of "surrogacy" before signing the surrogacy contract at the notary or after the birth of the child;</li>
                                        <li>3) total disregard of the child's genetic mother, whether it was a program with egg donation or not, due to the fact that she did not give birth to the child.</li>
                                    </ul>
                                    <p>The Spanish Embassy will only accept legal birth certificates that record the surrogate mother as the legal mother. the genetic father is recorded as the legal father and the genetic mother is not listed anywhere. After the family returns home to Spain, the genetic mother will have to adopt her own child.</p>
                                    <p>This provision comes from the "protection of the rights of the child" provided for in Article 7 of the Convention on the Rights of the Child of November 20, 1989, which states that a child must be registered immediately after birth and has the right from birth to a name, the right to acquire a nationality and, as far as possible, the right to know his or her parents. The problem is that this child will surely know its real (genetic) parents, since they are already family and this child is already registered in another country. But at the same time Spain, contrary to the Convention Abolishing Requirements for the Legalization of Foreign Public Documents, concluded on October 5, 1961 in The Hague, of which it is a member, does not recognize the other country's legal documents on surrogacy, recognizing all other documents. This creates a number of legal difficulties for the biological parents of a child born through surrogacy.</p>
                                    <p>The use of other reproductive methods of infertility treatment in Spain is carried out on quite legal grounds. For example, anonymous egg and sperm donation is allowed, including for monetary reward. Royal Decree 9/2014 of July 4 allows "to receive compensation payments from the institution responsible for the collection of eggs, covering the costs and inconveniences associated with the collection, in the form of benefits, compensation for lost economic income").</p>
                                    <p>Thus, single women, opposite-sex spouses or couples, and same-sex couples can benefit from assisted reproduction. And more importantly, can resort to the methods not only because of fertility problems, but also for life planning, such as choosing one of the parents or delaying maternity with the patient's own gametes using the ovocyte freezing technique.</p>
                                    <p>This issue is further explored in same-sex couples, as the type of relationship of the couple determines the legal protection granted and the options available for conception. For example, if there is a same-sex couple, both women, Spanish law allows for a process of shared biological maternity. This method is called ROPA (receiving oocytes from the partner) or Reciprocal IVF - provided for in Law 14/2006. During this process, one of the women, the genetic mother, provides the oocyte (genetic material) and the other, the biological mother, carries the pregnancy (through her uterus) - which is essentially the same process as conventional surrogacy.</p>
                                    <p>Thus, same-sex female couples prove that surrogacy in a certain way is in fact regulated and legal, which is contrary to Article 10, paragraph 1 of Law 14/2006.</p>
                                    <p>In addition, unlike female partners, same-sex couples (both men) are not protected by the Law on Assisted Human Reproduction Techniques. Consequently, in order to perform surrogacy, men will have to transfer their surrogacy to another country and their only route to legal parenthood will be adoption, as provided for in Law 13/2005, which legalized same-sex marriage and gave same-sex couples (both men) access to virtually all marital rights that heterosexual couples have.</p>
                                    <p>However, this creates further uncertainty for the child as, the adoption application is not guaranteed and may be rejected if the prospective couple's home is deemed 'unsuitable'. Thus, although Article 14 of the Spanish Constitution and Article 21 of the EU Charter of Fundamental Rights apply to combat discrimination, adoption agencies can be implicitly biased and reject an application on alternative grounds, putting both child and parents at a severe disadvantage.</p>
                                    <p>We believe that it is necessary to amend the surrogacy legislation in Spain, as in its current form the prohibition of surrogacy is detrimental to the majority of families and discriminates against the different types of relationships of those who wish to benefit from surrogacy. We would like to emphasize that, although the Spanish Supreme Court considers surrogacy to be an exploitative practice, it believes that the welfare of minors comes first, even in cases where there is no genetic link. This is also the case law of the European Court of Human Rights, which asks to guarantee some recognition by alternative legal means for children born through surrogacy, so as not to leave minors in a legal limbo. Therefore, there is hope for positive changes in Spanish legislation on surrogacy.</p>
                                </div>
                            )
                        }
                        <div className={classNames(classes['title'], accordionBlock.includes('stage-6') && classes['open'])} onClick={() => showAccordionBlock('stage-6')}>
                            <p>Surrogacy in Cyprus. Legislation of the Turkish Republic of Northern Cyprus in the field of surrogacy</p>
                            <Icon id='icon-chevron-down' />
                        </div>
                        {
                            accordionBlock.includes('stage-6') && (
                                <div className={classes['info']}>
                                    <p>Surrogacy in the Turkish Republic of Northern Cyprus is more flexible and liberal in the legal field compared to many other countries.</p>
                                    <p>The law that allows surrogacy in the Turkish Republic of Northern Cyprus is Law No. 57/2014 "On the Regulation of Transplantation of Human Cells, Tissues and Organs", which was published in the Official Gazette of the TRNC dated September 27, 2014.  The Law introduces general provisions regarding the donation, supply, testing, processing, protection, storage, distribution, transportation and, if necessary, destruction of reproductive cells and organs and tissues.</p>
                                    <p>In accordance with this law, the "Regulation on Assisted Reproductive Therapy Centers and Assisted Reproductive Therapy Methods", which was published in the Official Gazette of the TRNC No. 80 dated 21.06.2016, came into force and legalizes surrogacy in the Turkish Republic of Northern Cyprus.</p>
                                    <p>Thus, the legislation authorizing surrogacy in the Turkish Republic of Northern Cyprus was fully formulated in 2016 and allowed under the relevant conditions.</p>
                                    <p>According to the aforementioned documents, the TRNC legislation regulates the procedure of surrogate embryo transfer in cases where the woman who wishes to become a mother has a medical indication to do so. This makes it easier for the clinic to transfer embryos from the prospective parents to the surrogate, minimizing legal complexities.</p>
                                    <p>The medical portion of the surrogacy program is permitted for same-sex couples, heterosexual couples, and individuals. There are no restrictions for foreign nationals in this regard.</p>
                                    <p>Before the process begins, a contract is signed with the "surrogate mother". This contract prevents the surrogate mother from claiming rights to the child she will give birth to in the future.</p>
                                    <p>A study of the practical legalization of parental rights and the status of the child has shown that the legal parents of a child born by a surrogate mother are the presumed father and the surrogate mother. The name of the genetic mother (spouse) may be written on the child's Birth Certificate by decision of the court or registration authorities.</p>
                                    <p>Most nationals of most countries do not require a visa to visit Northern Cyprus, which is a significant advantage for people who wish to use the services of in vitro fertilization and surrogacy.  Citizens of the European Union entering the territory of the Turkish Republic of Northern Cyprus from the territory of Southern Cyprus only need to present a valid passport or, in some cases, the national identity card of the EU country, and then stay in the territory of the TRNC without obtaining an additional visa for up to 30 days with the possibility of extending up to 90 days or obtaining a residence permit. This allows a citizen of almost any country to benefit from a wide range of surrogacy programs without the need for additional visa formalities.</p>
                                    <p>According to a regulation published by the Ministry of Health of the TRNC in July 2016, TRNC clinics may also utilize preimplantation genetic diagnosis (PGD) when performing gestational surrogacy if there is a risk of sex-linked diseases in order to detect them in a timely manner. This confirms that TRNC clinics offer quality in vitro fertilization procedures and modern medical equipment.</p>
                                    <p>Thus, according to the " Regulation on Assisted Reproductive Therapy Centers and Methods of Assisted Reproductive Therapy",  the aim of the legislation on reproductive activities is precisely the quality of assisted reproductive therapy. Therefore, the purpose of the aforementioned Regulation is to license the assisted reproductive therapy centers that will donate, supply, store, transport and, if necessary, destroy human cells or embryos for therapeutic purposes. It defines the procedures and principles of operation and the principles of assisted reproductive therapies to which they must conform.</p>
                                    <p>This confirms that the TRNC legislation sets high medical standards for the centers, clinics, medical staff that perform assisted reproductive activities in the territory of Northern Cyprus, including surrogacy.</p>
                                    <p>We would also like to emphasize that the issue of assisted reproduction in Northern Cyprus has a history of almost 30 years, which gave the opportunity to improve the practical and legal basis for its implementation. The first legal regulation of assisted reproductive techniques in the TRNC legislation is the Law on Private Hospitals, Private Clinics, Private Dispensaries and Private Practice (Audit) (Law No. 34/1998).  This law was later abolished, but it initiated the legal doctrine on the use of reproductive activities.</p>
                                    <p>Thus, according to the analysis of legal provisions, it can be concluded that gestational surrogacy is allowed in the Turkish Republic of Northern Cyprus. We emphasize that there are no laws and regulations that would contradict the conclusion of a surrogacy agreement or the departure of biological parents with children outside the TRNC. In this regard, the TRNC is an ideal place to realize the dream of a child through assisted reproductive technologies, surrogate mother and the help of our organization. Today Northern Cyprus is becoming an attractive center for medical tourism, the authorities of the country support this direction and more and more foreigners arrive every year to receive quality medical care with the use of modern technologies and recovery.</p>
                                    <p>Thus, according to the analysis of legal norms, we can conclude that gestational surrogacy is allowed in the Turkish Republic of Northern Cyprus. We emphasize that there are no laws and regulations that would contradict the conclusion of a surrogacy agreement or the departure of biological parents with children outside the TRNC. In this regard, the TRNC is an ideal place to realize the dream of a child through assisted reproductive technologies, surrogate mother and the help of our organization. Today, Northern Cyprus is becoming an attractive center for medical tourism, the authorities of the country support this direction and more and more.</p>
                                </div>
                            )
                        }
                        <div className={classNames(classes['title'], accordionBlock.includes('stage-7') && classes['open'])} onClick={() => showAccordionBlock('stage-7')}>
                            <p>Surrogacy in Greece. Summary of legislation on surrogacy</p>
                            <Icon id='icon-chevron-down' />
                        </div>
                        {
                            accordionBlock.includes('stage-7') && (
                                <div className={classes['info']}>
                                    <p>In Greece, surrogacy is provided for and authorized by law and regulated by the following regulations:</p>
                                    <ul>
                                        <li>Art. 8 of the Greek Law 3089/2002 "Medical assistance in assisted reproduction" ("Medical assistance in human reproduction"; hereinafter Law 3089/2002), which was adopted on 19.12.2002 by the Greek Parliament and legalized surrogacy in Greece;</li>
                                        <li>Art. 13 of the Greek Law 3305/2005 "Application of the methods of Medically Assisted Reproduction" ("Application of the methods of Medically Assisted Reproduction"; hereinafter Law 3305/2005), which regulates the conditions for the establishment and operation of Medically Assisted Reproduction Units and Cryopreservation Banks. The law also provides for the establishment of an independent national authority, as well as criminal and administrative sanctions in case of violation of the law;</li>
                                        <li>Greek Law 4272/2014, which brought about an innovation in the field of surrogacy in Greece. Article 17 of this law abolished the mandatory condition for permanent residence in Greece as an eligibility criterion, allowing non-Greek prospective parents to legally apply for surrogacy.</li>
                                    </ul>
                                    <p>Under the old legal regime (Article 8 of Law 3089/2002), one of the preconditions for the issuance of a judicial authorization for surrogacy was the fact that both the surrogate and the prospective parents had to be obligatory citizens or residents of Greece.</p>
                                    <p>Article 17 of Law 4272/2014 modified the old regime by stating that: "Articles 1458 and 1464 of the Civil Code shall apply only if the applicant or the woman who will give birth to the child is a permanent or temporary resident of Greece."</p>
                                    <p>- The Code of Conduct on Assisted Medical Reproduction has also established additional provisions (Decision No. 73/24.01.01.2017, published in the Official Government Gazette No. 293/02.07.2107), which establish specific requirements and conditions for surrogate mothers;<br />
                                    - Art. 1455-1460 of the Greek Civil Code (hereinafter referred to as the Civil Code).</p>
                                    <p>These documents stipulate a number of conditions, the fulfillment of which is necessary for the realization of this type of assisted reproductive technologies (ART).</p>
                                    <p>One of the main conditions is that the use of ART is allowed only with a court order and on the basis of a surrogacy contract (Art. 1458 of the Greek Civil Code).</p>
                                    <p>According to Article 1456 of the Greek Civil Code, only married couples, heterosexual couples in civil partnership and single women have the right to seek medical assistance for the use of ART. ART is not available to same-sex couples and single men. Prospective parents can be either Greek citizens or temporary residents.</p>
                                    <p>Only the actual mother has the right to apply to the court. She must provide a medical report which confirms that she is infertile or unable to conceive, bear and give birth to a child and/or there is a risk of transmitting a serious hereditary disease (e.g. sickle cell anemia; art. 1455 of the Civil Code) to the child. The age of the de facto mother must not exceed 54 years (Art. 1455 CC, § 1, Art. 4, Law 3305/2005 § 1).</p>
                                    <p>The surrogate mother must alsoprovide a medical report confirming that she is physically and mentally healthy and obtain doctors' consent to participate in the program. Special requirements for a surrogate mother (age, having her own child, being married) in Greek law are set forth in the Code of Conduct for Assisted Medical Reproduction (Decision No. 73/24.01.01.2017, published in the Official Government Gazette No. 293/02.07.2107 ).</p>
                                    <p>Decision 73/24.01.01.2017 concerns the surrogacy procedure and establishes specific requirements and conditions for surrogate mothers. According to this decision, a surrogate mother must be between 25 and 45 years of age, already have at least one child born to her and not have had more than two caesarean sections.</p>
                                    <p>An essential condition is that the surrogate mother must reside in Greece. She is forbidden to advertise her services, so independently searching for a surrogate is extremely difficult. Therefore, the selection of surrogate mothers in Greece is mainly through specialized organizations.</p>
                                    <p>After collecting all the necessary documents, the actual mother is obliged to provide the court with a surrogacy contract, which prescribes the rights and obligations of the parties not only throughout the pregnancy, but also after the birth of the child. This contract is in writing and signed by the actual and surrogate mothers, as well as their spouses/civil partners (if any).</p>
                                    <p>Thus, all persons who have signed the surrogacy contract confirm their consent to this type of ART, as well as the transfer of parental rights to the actual parents immediately after the birth of the child. According to Article 1475 of the Greek Civil Code, the consent of the spouses/civil partners must be notarized.</p>
                                    <p>A mandatory condition for the conclusion of the contract is the absence of financial relations between the surrogate mother and the biological parents, but it is allowed to pay the necessary expenses during pregnancy and after childbirth, as well as to compensate for the temporary disability of the woman - such compensation is, on average, 10,000 euros on average and is not considered as payment for surrogacy.</p>
                                    <p>Furthermore, according to article 8 of Law 3089/2002, the actual parents and the surrogate mother are obliged to have residence in Greece.</p>
                                    <p>Thus, if all the above conditions are met, the Court, after examining the provisions of the surrogacy contract and ascertaining that the parties have not changed their minds, approves the surrogacy contract.</p>
                                    <p>In Greece, the parental rights of the actual parents arise immediately after the birth of the child by the surrogate mother (they are also recorded as parents on the child's birth certificate). Only the biological parents have parental rights over the newborn. In case of non-compliance with the legal requirements, Article 26 § 8 of Law 3305/2005 provides for criminal liability for all participants in legal relations related to the implementation of surrogacy.</p>
                                    <p>The situation with the citizenship of the newborn is very convenient: it is determined by the citizenship of the parents. If the native country of the parents does not recognize the child as their citizen - he or she will receive Greek citizenship. In any case, the documents for departure will be issued, they will be recognized by the European Union.</p>
                                    <p>As for the choice of the sex of the child in Greece - it is allowed only on medical grounds. If in your case there is a risk of genetic disease linked to the sex of the child - you will be allowed to choose the sex in Greece.</p>
                                    <p>Along with the question of whether surrogacy is legal in Greece, there are also questions about the legality of egg and sperm donation and embryo donation. All these procedures are also legal. In the case of embryos, the law does not require a mandatory genetic relationship with at least one of the parents. Greek law does not prohibit the use of other reproductive technologies. For example, egg and sperm donation. However, only doctors are authorized to select biological material, who will take into account the compatibility of blood groups and external data of the patient. Sperm and egg donation provides for monetary remuneration and is completely anonymous. If necessary, genetic diagnosis of the embryo before transferring it into the uterus is allowed.</p>
                                    <p>There are, of course, also some prohibitions and restrictions when applying IVF or using surrogacy. Relatives or friends of the infertile patient have no right to be donors of sex cells. It is also forbidden to choose the sex of the future childand destroy unused frozen embryos - they are stored for the duration of the patient's reproductive life and, later, can be used for research purposes or given to other patients.</p>
                                    <p>Thus, surrogacy in Greece is becoming increasingly popular. It is a fact that Greece is one of the few jurisdictions in the European Union that regulates surrogacy, which has adopted a favorable and lenient legal framework allowing surrogacy for heterosexual couples and single women, in order to increase the number of medical cases coming from abroad to Greek reproductive medicine clinics.</p>
                                </div>
                            )
                        }
                        <div className={classNames(classes['title'], accordionBlock.includes('stage-8') && classes['open'])} onClick={() => showAccordionBlock('stage-8')}>
                            <p>Georgian legislation on surrogacy</p>
                            <Icon id='icon-chevron-down' />
                        </div>
                        {
                            accordionBlock.includes('stage-8') && (
                                <div className={classes['info']}>
                                    <p>Surrogacy procedure has been authorized in Georgia for more than 25 years (since 1997). The legislative regulation of surrogacy and IVF in Georgia is based on several normative acts, including the Law "On Health Care" (Articles 141, 143, 144), the Law "On Civil Acts " (Article 30), the Law "On Legal Status of Aliens and Stateless Persons" (Article 49 1), Order of the Minister of Justice "On Registration of Civil Acts" (Articles 16 and 19), Law "On Patients' Rights" (Article 22), Joint Order of the Ministers of Justice and Internal Affairs on the departure from Georgia of children born as a result of IVF (surrogacy), adopted on April 4, 2016.</p>
                                    <p>Each of the mentioned acts regulates certain aspects of surrogacy and IVF in Georgia:<br />
                                    І. The Law "On Health Care" No. 1139 of 12.10.1997 (Articles 141, 143, 144) establishes general principles of IVF and surrogacy procedures, including the use of donor gametes and embryos, as well as the rights and obligations of the participants of the process.</p>
                                    <p><span className='underline'>Article 141</span><br />
                                    Insemination with donor sperm is authorized:<br />
                                    (a) Because of childlessness, when there is a risk of transmission of a genetic disease from the husband, or for the fertilization of a single woman, if the written consent of the childless couple or single woman is obtained. When the child is born, the childless couple or single woman is considered a parent, hence the responsibility and authority. The donor has no right to be recognized as the father (mother) of the child born as a result of fertilization;</p>
                                    <p><span className='underline'>Article 143</span><br />
                                    1- In vitro fertilization is authorized:<br />
                                    a) for the purpose of infertility treatment, as well as if there is a risk of transmission of a genetic disease from the wife or husband, using gametes or embryos of the spouses or a donor, if the written consent of the spouses is obtained;<br />
                                    b) if the woman does not have a uterus, by transferring and growing the embryo resulting from fertilization into the uterus of another woman ("surrogate mother"). Written consent of the couple is required.<br />
                                    2. If a child is born, the couple are considered parents, therefore have responsibility and authority. Neither the donor nor the surrogate has the right to be recognized as the child's parents.</p>
                                    <p><span className='underline'>Article 144</span><br />
                                    For the purpose of artificial insemination, the use of male and female germ cells or embryos preserved by freezing is permissible. The time of preservation shall be determined at the request of the couple, in accordance with the established procedure.<br />
                                    II. The Law on Civil Acts No. 5562 of 20.12.2011 (Article 30) regulates the birth registration of children born as a result of IVF and surrogacy, stipulating that the child is recognized as the child of the intended parents.<br />
                                    III. The Law on Legal Status of Aliens and Stateless Persons No. 2045-II of 03.05.2014 (Article 491) contains provisions regarding the rights of aliens to use reproductive technologies in Georgia.</p>
                                    <p><span className='underline'>Article 49</span> Departure of a child born in Georgia through in vitro fertilization (surrogacy) from Georgia<br />
                                    1. A child born in Georgia through in vitro fertilization (surrogacy) may leave Georgia only if both parents of the child are listed in the act record of birth concluded by the Agency for Development of Public Services, a legal entity of public law acting in the sphere of administration of the Ministry of Justice of Georgia.<br />
                                    2. The procedure for leaving Georgia of a child born by in vitro fertilization (surrogacy) shall be determined by a joint order of the Minister of Justice of Georgia and the Minister of Internal Affairs of Georgia.<br />
                                    IV. The Order of the Minister of Justice "On Approval of the Procedure of Civil Status Registration" No. 18 of 31.01.2012 (Articles 16 and 19) details the procedures of birth registration, including children from surrogacy.<br /></p>
                                    <p><span className='underline'>Article 16</span><br />
                                    1. The data required for birth registration shall be indicated in the birth record on the basis of a medical birth certificate issued by a joint order of the Minister of Labor, Health and Social Protection of Georgia and the Minister of Justice of Georgia.<br />
                                    2. If the medical certificate of birth states that the child was born as a result of in vitro fertilization, the civil status registration authority shall register the birth after submission of necessary documents, but not later than 7 calendar days after submission of the medical certificate of birth. If the documents are not submitted within the specified period, the birth registration will be carried out according to the general rules.</p>
                                    <p><span className='underline'>Article 19</span> Registration of birth of a child born as a result of in vitro fertilization<br />
                                    1. In order to register the birth of a child born as a result of in vitro fertilization, together with the documents stipulated by the legislation of Georgia, the civil status registration body shall be provided with:<br /><br />
                                    a) a certificate of in vitro fertilization issued by a medical institution immediately after implantation of the embryo;<br />
                                    b) a notarized contract signed before in vitro fertilization:<br />
                                    a) between the woman who gave birth and the genetic parents, or;<br />
                                    b) between the woman who gave birth to the child, the genetic father, the person to be recorded as the child's father on the birth certificate (who is not the child's genetic father) and the donor, or;<br />
                                    (c) between the woman who gives birth to the child, spouses and donors.<br /><br />
                                    2. The parents of a child born as a result of in vitro fertilization are:<br /><br />
                                    a) genetic parents;<br />
                                    b) the genetic father (mother) and the person who, on the basis of a contract, is entered as the father in the record of the act record<br />
                                    b) the genetic father and the person who, on the basis of a contract, is entered as the father in the birth record;<br />
                                    c) a married couple.<br /><br />
                                    3) The donor or "surrogate mother" may not be listed as the father (mother) of the child in the civil status record of the child's birth.<br /><br />
                                    4. When registering the birth of a child born as a result of in vitro fertilization, in case of failure to submit a surrogacy contract, the child's parents or a single woman shall not be indicated in the birth record. In this case, the child's surname and name are indicated in the birth record on the basis of an application by the guardians and the guardianship and trusteeship authority.<br /><br />
                                    V. The Law on Patients' Rights No. 283 of 05.05.2000 (Article 22) protects the rights of IVF and surrogacy participants, including the right to informed consent.</p>
                                    <p><span className='underline'>Article 22</span> <br />
                                    1. A prerequisite for the provision of medical services is the informed consent of the patient, and in case it is impossible for minors to make informed decisions, of the patient's relative or legal representative. Obtaining informed consent shall precede the provision of medical services.<br />
                                    2. Written informed consent is required when providing such medical services ... as in vitro fertilization. <br />
                                    VI. The Joint Order of the Ministers of Justice and Interior on Exit from Georgia No. 4846, dated March 4, 2016, establishes rules for the exit from Georgia of children born as a result of IVF (surrogacy), ensuring legal protection of the rights of the child and the intended parents. </p>
                                    <p>Surrogate mothers in Georgia must meet certain requirements, including being between 21 and 35 years of age, having at least one successful delivery without complications and fetal anomalies, as well as physical and psychological health, no criminal record for a serious or especially serious crime, no precedent of deprivation or restriction of parental rights in the woman's past, no alcohol or drug addiction, legal capacity, and meeting medical requirements for surrogate mothers.</p>
                                    <p>In Georgia, surrogacy is available to married heterosexual couples who can provide medical evidence of inability to have children naturally and legal proof of their marital status, as well as to single women for medical reasons. The legislation requires that the intended parents be married or provide evidence of cohabitation for a certain period (common-law marriage of more than a year).</p>
                                    <p>A married couple or single woman who have used the services of a surrogate mother are recognized as the legal parents of the child immediately after birth. The law does not require subsequent adopting of the child by the intended parents, and the donor or surrogate mother has no right to be recognized as the child's parents. The surrogate mother has no claim to the child because she is not genetically related to the child and voluntarily agrees to participate in the surrogacy program. Even if an embryo is transferred into the surrogate's uterus from a donor's egg or sperm, rather than from an infertile couple, the potential parents are considered the parents of the child. This simplifies the process of recognizing parental rights for the prospective parents (Articles 141, 143 and 144 of the Law of Georgia "On Health Care").</p>
                                    <p>According to Georgian legislation, the Birth Certificate is issued immediately, within one day after the birth of the child. In the birth certificate, the parents of the child are indicated as a couple or a single woman. Thus, the Birth Certificate of a child from a surrogate mother is no different from the birth certificate issued at the birth of any other child. The consent of the surrogate mother is not required to register an infertile couple or single woman as parents.</p>
                                    <h3>REGISTERING A COUPLE AS PARENTS WILL REQUIRE:</h3>
                                    <p>1. A surrogacy agreement signed by the parents or single woman, a certificate of embryo transfer into the uterus of the surrogate mother issued by the IVF clinic and a certificate confirming the fact of the child's birth issued by the maternity hospital. Foreign parents (or a single woman) may take the child back to their country at any time after the birth certificate is issued.</p>
                                    <p>(2) The father and mother of a child born as a result of surrogacy shall be recognized as the biological parents - a married couple or a single woman who, in accordance with the agreement, provided their own/donor genetic material.</p>
                                    <p>Thus, according to the legislation, gestational surrogacy is legal in Georgia. According to the normative acts it is allowed for heterosexual couples who are legally married, who have medical indications for this method and a single woman. Surrogacy is prohibited for single men as well as same-sex couples.</p>
                                    <p>The woman carrying the child has no biological connection to the child. Fertilization takes place under laboratory conditions. For this purpose, an egg is taken from the biological mother/donor and sperm from the biological father/donor. The surrogate mother cannot be an oocyte donor at the same time. In this way, the requirement that there is no genetic link between the surrogate mother and the child to be carried is violated.</p>
                                    <p>Surrogacy is carried out on the basis of a voluntary agreement between the parties, which must be notarized. The surrogacy agreement must contain all the terms of cooperation, rights, obligations of the parties, compensation and expenses, as well as the legal status of the child.</p>
                                    <p>The surrogate mother has no rights to the baby she carries and gives birth to for other people. She cannot claim to remain in contact with him or to have him returned to her. Nor does she have any responsibility for the baby once it is born. Biological parents or a single woman are the only legal parents and have all rights, responsibilities for his upbringing, education.</p>
                                    <p>To date, the Government of Georgia has submitted an initiative to the Parliament to tighten the rules of surrogacy and in vitro fertilization. Restrictions on surrogacy and IVF are planned to be introduced in 2024.</p>
                                    <p>According to this initiative, surrogacy and IVF on the territory of Georgia will be allowed only for citizens of the country and prohibited for foreign citizens. It is also planned to prohibit advertising of surrogacy services - information about it will be disseminated only by the Ministry of Health of Georgia. In addition, surrogate mothers will be prohibited from receiving monetary remuneration: only direct costs of pregnancy and childbirth can be reimbursed. Clinics providing assisted reproductive services will have to obtain a special license.</p>
                                    <p>This decision was taken to more strictly regulate the surrogacy industry, taking into account concerns expressed about the welfare of surrogate mothers and children, as well as issues related to human trafficking and the placement of children with same-sex couples, which are contrary to Georgian law. The ban is part of a broader effort by the Georgian government to address these concerns, while surrogacy will still be permitted for Georgian citizens on the principles of altruism, without a commercial component.</p>
                                    <p>Currently, the draft law on banning the use of surrogacy in Georgia for foreign citizens is under consideration in the Parliament. We are closely following all changes in the legislation and will inform about any innovations in a timely manner. We understand the importance of this issue for our clients and guarantee to provide up-to-date and reliable information so that you can make informed decisions.</p>
                                </div>
                            )
                        }
                    </div>
                </section>
            </div>
        </PageWrap>
    );
}

export default LegislationPage;
