import React from 'react';
import PageWrap from '../../../../app/components/PageWrap';
import TopSection from './components/TopSection';
import HowItWorksSection from './components/HowItWorksSection';
import SupportSection from './components/SupportSection';
import AdvantageSection from './components/AdvantageSection';
import JoinUsSection from './components/JoinUsSection';

const AboutUsPage = () => {
    return (
        <PageWrap>
            <TopSection />
            <HowItWorksSection />
            <SupportSection />
            <AdvantageSection />
            <JoinUsSection />
        </PageWrap>
    );
}

export default AboutUsPage;
