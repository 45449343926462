import React, { useEffect, useRef } from "react";
import { useState, useContext, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Icon from "../../../ui/Icon";
import Button from "../../../ui/Button";

import { ChatState } from "../../../context/chatProvider";
import { SocketContext } from "../../../context/socket";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { fetchChatsRequest, fetchMessagesRequest, fetchNotificationsRequest, resetMessages } from "../../../actions/textChatActions";

import classes from "../style/videoChatRoom.module.scss";
import classNames from "classnames";

import VideochatTime from "./VideochatTime";
import VideochatButton from "../../../components/VideochatButton";
import VideoComponent from "./VideoComponent";
import { hideMenu, showMenu } from "../../../actions/globalActions";
import ACTIONS from "../../../utils/actions";
import { fetchPopup } from "../../../actions/modalActions";
import { setAvailableSeconds } from "../../../actions/videoChatActions";
import { fetchUserRequestAcction } from "../../../actions/userActions";

const VideoChatRoom = ({chatId}) => {
    const {roomId} = useParams();
    const messagesListRef = useRef();
    const messagesRef = useRef();
    const messageInputRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);
    
    const { selectedChat, setSelectedChat, setCurrentTextChat, currentTextChat } = ChatState();
    const user = useSelector((state) => state.user.user);
    const messagesById = useSelector((state) => state.textChats.messagesById);
    const onlineDonors = useSelector((state) => state.videoChat.onlineDonors);
    const chatConnection = useSelector((state) => state.videoChat.chatConnection);
    const availableSeconds = useSelector((state) => state.videoChat.availableSeconds);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const [messagesList, setMessagesList] = useState([]);  
    const [newMessage, setNewMessage] = useState("");
    const [socketConnected, setSocketConnected] = useState(false);
    const [typing, setTyping] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [firstScroll, setFirstScroll] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [donorLeaveChat, setDonorLeaveChat] = useState(null);

    useEffect(() => {
        function handleLinkClick(event) {
            const url = event.target.getAttribute('href');
            if (roomId) {
                event.preventDefault();
                socket.emit(ACTIONS.LEAVE)
            }
        }

        document.querySelectorAll('a').forEach(link => {
            link.addEventListener('click', handleLinkClick);
        });

        return () => {
            document.querySelectorAll('a').forEach(link => {
                link.removeEventListener('click', handleLinkClick);
            });
        };
    }, [socket, roomId]);

    useEffect(() => {
        dispatch(hideMenu());
        return () => {
            dispatch(showMenu());
        };
      }, []);

    const handleTimeOff = () => {
        
        dispatch(fetchPopup({
            popupType: 'CoinsModal',
            props: {
                className: "small-balance",
                text: 'Oh, you ran out of Video Chat',
                buttonText: "Top up your balance",
                type: "small-balance",
                onClose: () => {
                    socket.emit(ACTIONS.LEAVE, {userId: user.id, donorId: currentTextChat?.donorId});
                    window.open('/videochat', '_self')
                    dispatch(setAvailableSeconds(0));
                    dispatch(fetchUserRequestAcction(user.id))
                },
                onTopUp: () => {
                    socket.emit(ACTIONS.LEAVE, {userId: user.id, donorId: currentTextChat?.donorId});
                    window.open('/coins', '_self')
                    dispatch(setAvailableSeconds(0));
                    dispatch(fetchUserRequestAcction(user.id))
                }
            },
        }))
    };

    useEffect(() => {
        if (!onlineDonors) {
            dispatch(fetchNotificationsRequest());
        }
    }, [onlineDonors]);


    const getChatById = () => {
        return onlineDonors.find((chat) => chat.chatId === parseInt(chatId, 10));
    };
    
    useEffect(() => {
        if (chatId && onlineDonors) {
            setSelectedChat(chatId);
            setCurrentTextChat(getChatById());
        } else {
            setSelectedChat(null);
            setCurrentTextChat(null);
        }
    }, [chatId, onlineDonors]);

    useEffect(() => {
        if (selectedChat) {
            dispatch(resetMessages(selectedChat));
            dispatch(fetchMessagesRequest(selectedChat, 1, 20));
        }
        if (currentPage > 1) {
            setCurrentPage(1);
        }
    }, [dispatch, selectedChat]);

    useEffect(() => {
        if (messagesById) {
            setMessagesList(messagesById[selectedChat]);
        }
    }, [messagesById, selectedChat]);

    useEffect(() => {
        setFirstScroll(true)
    }, [selectedChat]);

    useEffect(() => {
        if (messagesListRef && messagesListRef.current && firstScroll) {
            const messagesContainer = messagesListRef.current;
            const lastMessage = messagesContainer.lastChild;

            if (lastMessage) {
                lastMessage.scrollIntoView({ behavior: "smooth" });
                setTimeout(() => {
                    setFirstScroll(false);
                }, 500);
            }
        }
    }, [messagesList, selectedChat, firstScroll]);
    
    const backToChatList = () => {
        navigate("/videochat");
    };
    
    const handleKeyDown = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.preventDefault();
            sendMessage();
        }
    };
    
    const sortedMessages = messagesList && messagesList.messages ? [...messagesList.messages].sort((a, b) => new Date(a.date) - new Date(b.date)) : [];
    
    const groupedMessages = sortedMessages.reduce((result, message) => {
        const date = new Date(message.date).toLocaleDateString();
        if (!result[date]) {
            result[date] = [];
        }
        result[date].push(message);
        return result;
    }, {});
    
    const messageDates = Object.keys(groupedMessages);
    
    const today = new Date().toLocaleDateString();
    const yesterday = new Date(Date.now() - 86400000).toLocaleDateString();

    const typingHandler = (e) => {
        setNewMessage(e.target.value);

        if (!socketConnected) return;

        if (!typing) {
            setTyping(true);
            socket.emit("typing", currentTextChat.chatId);
        }
    };

    useEffect(() => {
        if(donorLeaveChat){
            dispatch(fetchPopup({
                popupType: 'DonorLeaveChat',
                hideClose: true, 
                props: {
                    className: "leave-chat",
                    donorName: currentTextChat?.userName
                },
            }))
            dispatch(setAvailableSeconds(0))
        }
    }, [donorLeaveChat]);

    const handleDonorCloseVideo = useCallback((res) => {
        if(currentTextChat && currentTextChat.donorId == res.donorId){
            setDonorLeaveChat(res)
        }
    }, [currentTextChat])

    const handleInviteAccepted = useCallback((mes) => {
        if (!user || !user.id) {
            console.error('User data is not available yet.');
            return;
        }
        if (currentTextChat && (mes.id === currentTextChat.chatId)) {
            const newMessageData = {
                messageText: mes.latestMessage.message,
                date: mes.latestMessage.created_at,
                sender: mes.latestMessage.sender ? mes.latestMessage.sender.id == user.id ? "client" : "donor" : "donor"
            };
            setMessagesList((prevMessages) => ({
                ...prevMessages,
                messages: [...prevMessages.messages, newMessageData],
            }));
            if (messagesListRef && messagesListRef.current) {
                    const messagesContainer = messagesListRef.current;
                    const lastMessage = messagesContainer.lastChild;
                    if (lastMessage) {
                        lastMessage.scrollIntoView({ behavior: "smooth" });
                        setScrollPosition(0)
                    }
                }
        }
    }, [user, currentTextChat]);

    const userWantToLeave = useCallback(() => {
        dispatch(fetchPopup({
            popupType: 'LeaveChat',
            hideClose: true, 
            props: {
                className: "leave-chat"
            },
        }))
    }, [user]);

    useEffect(() => {
        const messageReceivedHandler = (mes) => handleInviteAccepted(mes);
    
        socket.on("message received", messageReceivedHandler);
        socket.on("user-request-to-leave-videochat", (res) => userWantToLeave(res));
        socket.on("donor-close-video", (res) => handleDonorCloseVideo(res));
        
        return () => {
            socket.off("message received", messageReceivedHandler);
            socket.off("user-request-to-leave-videochat");
            socket.off("donor-close-video");
        };

    }, [socket, handleInviteAccepted]);

    const sendMessage = async (event) => {

        if (newMessage) {
            if(messageInputRef.current) {
                messageInputRef.current.value = ''
            }

            socket.emit("stop typing", currentTextChat.chatId);
            
            try {
                const config = {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${userInfo.token}`,
                        "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
                    },
                };
                setNewMessage(newMessage);
                const { data } = await axios.post(
                    "https://dev-api.surrogacy-platform.com/api/messages",
                    {
                        message: newMessage,
                        conversationId: parseInt(selectedChat),
                        senderId: userInfo.userId,
                        type: 'MESSAGE'
                    },
                    config
                );
                socket.emit("new message", data);
                dispatch(fetchChatsRequest());
            } catch (error) {
                console.log(error);
            }
        }
    };

    const loadMoreMessages = useCallback(async () => {
        try {
            setTimeout(async () => {
                dispatch(fetchMessagesRequest(selectedChat, currentPage + 1, 20));
                setCurrentPage((prevPage) => prevPage + 1);
            }, 1000); 
        } catch (error) {
            console.log(error);
        }
    }, [currentPage, dispatch, selectedChat, setCurrentPage]);

    const handleScroll = useCallback(() => {
        const messagesContainer = messagesRef.current;
        if (messagesContainer) {
            const atTop = messagesContainer.scrollTop === 0;
    
            if (atTop) {
                setScrollPosition(messagesContainer.scrollHeight - messagesContainer.scrollTop);
                loadMoreMessages();
            }
        }
    }, [loadMoreMessages]);

    useEffect(() => {
        const handleScrollEvent = () => {
            if (!firstScroll && messagesList && (messagesList.messages.length <= messagesList.totalMessagesCount)) {
                handleScroll();
            }
        };
    
        const messagesContainer = messagesRef.current;
        if (messagesContainer) {
            messagesContainer.addEventListener("scroll", handleScrollEvent);
            messagesContainer.scrollTop = messagesContainer.scrollHeight - scrollPosition;
        }
    
        return () => {
            if (messagesContainer) {
                messagesContainer.removeEventListener("scroll", handleScrollEvent);
            }
        };
    }, [handleScroll, messagesList, firstScroll, scrollPosition]);

    if(!currentTextChat){
        return null
    }

    return (
        <div className={classNames(classes["chat_field"], classes["chat_field_active"])}>
            <div className={classes["chat_header"]}>
                <div className={classes["chat_users"]}>
                    <div
                        className={classNames(
                            classes["image"],
                            classes["chat_back_button"]
                        )}
                        onClick={backToChatList}
                    >
                        <Icon className="icon-arrow-left" id="icon-arrow-left" />
                    </div>
                    {messagesList && (
                        <>
                            <div
                                className={classNames(
                                    classes["chat_user"],
                                    classes["chat_status_online"]
                                )}
                            >
                                {currentTextChat?.photo ? (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        <img src={currentTextChat?.photo} alt="" />
                                    </div>
                                ) : (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        {`${currentTextChat?.userName.charAt(0)}`}
                                    </div>
                                )}
                                {!isMobile ? <span>{currentTextChat?.userName}</span> : null}
                            </div>
                            <div
                                className={classNames(
                                    classes["chat_user"],
                                    classes["chat_status_online"]
                                )}
                            >
                                {user?.userAvatar ? (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        <img src={user?.userAvatar} alt="" />
                                    </div>
                                ) : (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        {`${user?.firstName.charAt(0)}`}
                                    </div>
                                )}
                                {!isMobile ? <span>{user?.firstName}</span> : null}
                            </div>
                        </>
                    )}
                </div>
                <VideochatButton type="chat" donorId={currentTextChat?.donorId} />
            </div>
            {
                availableSeconds > 0 && (
                    <VideochatTime handleTimeOff={handleTimeOff} />
                )
            }
            <div className={classes["video_chat_main_area"]}>
                <div className={classes["video_chat_cards"]}>
                    {
                        chatConnection && (
                            <div className='loading'><span className="loader-1"> </span></div>
                        )
                    }
                    <div>
                        {
                            roomId ? (
                                <VideoComponent />
                            ) : (
                                <img src={currentTextChat?.photo} alt="" className={classes["video"]} />
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={classes["scroll-container"]} ref={messagesRef} >
                <div className={classes["chat_messages"]} ref={messagesListRef}>
                    <div className={classes["user_message_filler"]} />
                    {messageDates.map((date) => (
                        <React.Fragment key={date}>
                            <div className={classes["chat_date_separator"]}>
                                <span>{date === today ? 'Today' : date === yesterday ? 'Yesterday' : date}</span>
                            </div>
                            {groupedMessages[date].map((message) => (
                                <div
                                    key={message.id}
                                    className={classNames(
                                        classes["user_message"],
                                        message.sender == "client" && classes["self_user"]
                                    )}
                                >
                                    {message.sender == "donor" ? (
                                        currentTextChat?.photo ? (
                                            <div className={classNames(classes["avatar"], "avatar")}>
                                                <img src={currentTextChat?.photo} alt="" />
                                            </div>
                                        ) : (
                                            <div className={classNames(classes["avatar"], "avatar")}>
                                                {`${currentTextChat?.userName.charAt(0)}`}
                                            </div>
                                        )
                                    ) : user?.userAvatar ? (
                                        <div className={classNames(classes["avatar"], "avatar")}>
                                            <img src={user?.userAvatar} alt="" />
                                        </div>
                                    ) : (
                                        <div className={classNames(classes["avatar"], "avatar")}>
                                            {`${user?.firstName.charAt(0)}`}
                                        </div>
                                    )
                                    }
                                    <ul className={classes["user_messages"]}>
                                        <li>{message.messageText}</li>
                                    </ul>
                                </div>
                            ))}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className={classes["chat_input_block"]}>
                <div className={classes["chat_input"]}>
                    <input
                        placeholder="Type your message"
                        className={classes["chat_messages_input"]}
                        onChange={typingHandler}
                        onKeyDown={handleKeyDown}
                        ref={messageInputRef}
                    />
                </div>
                <Button addClasses={["button", "default"]} onClick={sendMessage}>
                    Send
                </Button>
            </div>
        </div>
    );
};

export default VideoChatRoom;