import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import classes from './style/input.module.scss'
import Icon from '../Icon'

function Input({ type, placeHolder = '', name, withError = false, errorText = '', useFormEl, addProps = {}, placeholderNormal = '', withSuccess, successText, className, value, onChange, setInput = true }) {
    let id = Math.floor(Math.random() * 900000)
    const [showPass, updateShowPass] = useState(false)
    const [inputValue, setInputValue] = useState(value)
    const inputValueRef = useRef(null)

    const showPassword = () => {
        updateShowPass(!showPass)
        inputValueRef.current.type = showPass ? 'password' : 'text'
    }
    
    const onChangeInput = (e) => {
        if(onChange) {
            onChange(e.target.value)
            if(setInput) {
                setInputValue(value)
            }
        }else {
            useFormEl.onChange(e)
        }
    }

    const setRef = (e) => {
        if (useFormEl) {
            useFormEl.ref(e)
        }
        inputValueRef.current = e
    }

    return (
        <div
            className={classNames(
                classes['input-row'],
                withError ? classes['error'] : null,
                withSuccess ? classes['success'] : null,
                'input-row',
                className,
                placeholderNormal ? classes['with-placeholder'] : null,
            )}
        >
            <label htmlFor={name + '_' + id} suppressHydrationWarning={true}>
                <span>{placeHolder}</span>
            </label>
            <input
                {...useFormEl}
                type={type}
                name={name}
                value={value ? value : inputValue}
                onChange={onChangeInput}
                {...addProps}
                id={name + '_' + id}
                placeholder={placeholderNormal || `Enter ${placeHolder}`}
                ref={setRef}
                autoComplete={type=="password" ? "off" : "on"}
                suppressHydrationWarning={true}
            />

            {withError ? (
                <label className={classNames(classes['info-label'], classes['error-label'])} data-error={errorText}>
                    <Icon id="icon-alert" className={classNames(classes.icon, classes.info)} />
                </label>
            ) : null}

            {withSuccess ? (
                <label className={classNames(classes['info-label'], classes['success-label'])} data-error={successText}>
                    <Icon id="uadicon-check" className={classNames(classes.icon, classes.info)} />
                </label>
            ): null}

            {type === 'password' ? (
                <button type="button" className={classes['input-show-pass-btn']} onClick={showPassword}>
                    {showPass ? (
                        <Icon id="icon-eye" className={classNames(classes['eye-open'])} />
                    ) : (
                        <Icon id="icon-eye-off" className={classNames(classes.eye)} />
                    )}
                </button>
            ) : null}
        </div>
    )
}

export default Input
