import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDonorsRequest } from "../../../actions/donorActions";

// import Icon from "../../../ui/Icon";
// import Filter from "../../../components/Filter";
import GalleryFilterHeader from "../../../components/GalleryFilterHeader/GalleryFilterHeader";
// import LadiesSearch from "../../../components/LadiesSearch/LadiesSearch";
import LadyCard from "../../../components/LadyCard/LadyCard";

import classes from "../style/ladiesSection.module.scss";
import classNames from "classnames";
import { isMobile } from "react-device-detect";

const LadiesSection = () => {
    const dispatch = useDispatch();
    const [mobFilterShowing, setMobFilterShowing] = useState(false);
    const donors = useSelector(state => state.donors.eggsList);

    useEffect(() => {
        dispatch(fetchDonorsRequest(1, 6, 'DESC'));
    }, [dispatch]);

    const showMobileFilter = () => {
        setMobFilterShowing(!mobFilterShowing)
    }

    // const onLadiesSearch = (searchString) => {
    //     dispatch(fetchDonorsRequest(1, 9, 'DESC', searchString));
    // }

    return (
        <section className={classes["search"]}>
            <div className={classNames("container")}>
                {/* <div
                    className={classNames(
                        classes["search_filters"],
                        mobFilterShowing ? "" : classes["filter-display-none"]
                    )}
                >
                    <div className={classes["search_filter_header"]}>
                        <span>
                            Filters
                            <i>16285 results</i>
                        </span>
                        <Button addClasses={['link-button']} className={classes["search_filter_clear_button"]} onClick={clearFilter}>Clear</Button>
                        <div className={classes['close-filter']} onClick={showMobileFilter}>
                            <Icon
                                id="icon-x"
                                className={classNames(classes["close_filters"], "icon-x")}
                            />
                        </div>
                    </div>
                    <Filter onLadiesSearch={onLadiesSearch} />
                </div> */}
                <div className={classes["search_main"]}>
                    {
                        !isMobile && (
                            <GalleryFilterHeader type="main" showMobileFilter={showMobileFilter} />
                        )
                    }
                    {/* <LadiesSearch /> */}
                    <div className={(classes["search_main_cards"])}>
                        {donors ? donors.donors.map(item => (
                            <LadyCard key={item.id} lady={item} className="main-page" />
                        )) : null}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LadiesSection;
