import React, { useState, useEffect } from 'react';
import classes from './style/pagination.module.scss';
import Icon from '../../ui/Icon';
import { Link } from 'react-router-dom';

const Pagination = ({ curPage, onPageChange, total, pageLink }) => {
    const [currentPage, setCurrentPage] = useState(parseInt(curPage) || 1); // Используйте 1, если curPage не определен
    const [totalPages, setTotalPages] = useState(0); 

    useEffect(() => {
        if (total) {
            setTotalPages(Math.ceil(total / 9));
        }
    }, [total]);

    const handlePageClick = (page) => {
        setCurrentPage(page);
        onPageChange(page);
    };

    const handlePrevClick = () => {
        if (currentPage > 1) {
            handlePageClick(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            handlePageClick(currentPage + 1);
        }
    };

    if(!total || total <= 9) {
        return null
    }    

    return (
        <div className={classes["search_main_pagination"]}>
          <div className={classes["buttons_block"]}>
            <Link to={currentPage === 1 ? `${pageLink}` : `${pageLink}/page/${currentPage - 1}`} className={classes["arrow"]} onClick={handlePrevClick}>
              <div className={classes["image"]}>
                <Icon id="icon-pagination-arrow-left" className="icon-pagination-arrow-left" />
              </div>
            </Link>
            <ul>
              {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                <li key={page} className={page === currentPage ? classes['center'] : ''}>
                  <Link to={page === 1 ? `${pageLink}` : `${pageLink}/page/${page}`} onClick={() => handlePageClick(page)}>
                    {page}
                  </Link>
                </li>
              ))}
            </ul>
            <Link to={`${pageLink}/page/${currentPage + 1}`} className={classes["arrow"]} onClick={handleNextClick}>
              <div className={classes["image"]}>
                <Icon id="icon-pagination-arrow-right" className="icon-pagination-arrow-right" />
              </div>
            </Link>
          </div>
        </div>
      );
      
};

export default Pagination;
