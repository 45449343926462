import React, { useEffect } from 'react';
import PageWrap from '../../app/components/PageWrap';

import classes from './style/donorProgramsPage.module.scss'
import ButtonLink from '../../ui/Button/LinkButton';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDonorPackagesRequest } from '../../actions/globalActions';


const DonorProgramsPage = () => {
    const dispatch = useDispatch();

    const donorPacks = useSelector((state) => state.globalState.donorPacks);

    useEffect(() => {
        if(!donorPacks){
            dispatch(getDonorPackagesRequest())
        }
    }, [donorPacks]);

    return (
        <PageWrap>
            <section className={classes['programs']}>
                <div className='container'>
                    <p className={classes['programs-title']}>Packages</p>
                    <div className={classes['programs-container']}>
                        {
                            donorPacks && donorPacks.map(item => (
                                <Link key={item.name} to={`/packages/${item.name}`} className={classes['programs-item']}>
                                    <div className={classes['col']}>
                                        <div className={classes['icon']}>
                                            <img src={`/cdn/images/programs/${item.name}.png`} alt="" />
                                        </div>
                                        <div className={classes['info']}>
                                            <p className={classes['name']}>{item.name}</p>
                                            <p className={classes['short-info']}>{item.shortDescription}</p>
                                        </div>
                                    </div>
                                    <div className={classes['order-info']}>
                                        <div>
                                            <p className={classes['price']}><span>€</span>{item.price}</p>
                                            <p className={classes['pays']}>/first payment</p>
                                        </div>
                                        <ButtonLink to={`/packages/${item.name}`} addClasses={['capitalize']}>More</ButtonLink>
                                    </div>
                                </Link>
                            ))
                        }
                    </div>
                </div>
            </section>
        </PageWrap>
    );
}

export default DonorProgramsPage;
