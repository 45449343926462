import React from 'react'
import PageWrap from '../../../../app/components/PageWrap'
import Icon from '../../../../ui/Icon'

import classes from './style/contactUsPage.module.scss'
import classNames from 'classnames'

export default function ContactUsPage() {
    return (
        <PageWrap>
            <div className='container'>
                <div className={classes['contact-us-top']}>
                    <img className={classes["logo-img"]} src="/cdn/images/logo-big.svg" alt="Logo surrogacy platform" />
                    <h2>Contact our friendly team</h2>
                    <p>Let us know how we can help you!</p>
                </div>
                <div className={classes['contact-block']}>
                    <div className={classes['item']}>
                        <div className={classes['icon-block']}>
                            <Icon id="icon-map" />
                        </div>
                        <p className={classes['title']}>Address</p>
                        <p className={classes['info']}>Main office in Cyprus:<br />Şht. Erdoğan Mustafa Sokak no:19 Yenişehir / Nicosia, Cyprus;</p>
                        <p className={classes['info']}>Back office in USA:<br />1968 S. Coast Hwy #2737<br />Laguna Beach, CA 92651</p>
                    </div>
                    <div className={classes['item']}>
                        <div className={classes['icon-block']}>
                            <Icon id="icon-phone-contact" />
                        </div>
                        <p className={classes['title']}>Phone number</p>
                        <p className={classes['info']}><a href="https://wa.me/34610769556">+34610769556</a></p>
                    </div>
                    <div className={classes['item']}>
                        <div className={classes['icon-block']}>
                            <Icon id="icon-mail-open" />
                        </div>
                        <p className={classes['title']}>Email</p>
                        <p className={classes['info']}><a href="mailto:surrogacyplatformcom@gmail.com">surrogacyplatformcom@gmail.com</a></p>
                    </div>
                    <div className={classNames(classes['item'], classes['chats'])}>
                        <div className={classes['icon-block']}>
                            <Icon id="icon-chat" />
                        </div>
                        <p className={classes['title']}>Chats</p>
                        <p className={classes['info']}><a className={classes['social']} target="_blank" rel="noopener noreferrer" href="https://wa.me/34610769556" aria-label="Whatsapp"><img src="/cdn/images/contacts/wa.png" alt="wa" />WhatsApp</a><br />
                        <a className={classes['social']} target="_blank" rel="noopener noreferrer" href="viber://chat?number=%2B34610769556" aria-label="Viber"><img src="/cdn/images/contacts/viber.png" alt="viber" />Viber</a><br />
                        <a className={classes['social']} target="_blank" rel="noopener noreferrer" href="https://t.me/surrogacyplatform" aria-label="Telegram"><img src="/cdn/images/contacts/tg.png" alt="tg" />Telegram</a>
                        </p>
                    </div>
                </div>
            </div>
        </PageWrap>
    )
}
