import React, { useRef, useState } from 'react';

import classes from '../style/donorRegistrationPage.module.scss'
import Button from '../../../ui/Button';
import Icon from '../../../ui/Icon';
import { isMobile } from 'react-device-detect';
import { uploadDonorPhotosRequest } from '../../../actions/donorActions';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

const UploadPhoto = ({handlePrev}) => {
    const dispatch = useDispatch();

    const [mainPhoto, setMainPhoto] = useState(null);
    const [springPhoto, setSpringPhoto] = useState(null);
    const [winterPhoto, setWinterPhoto] = useState(null);
    const [childhoodPhoto, setChildhoodPhoto] = useState(null);
    const [differentAgesPhoto, setDifferentAgesPhoto] = useState(null);
    const [favoritePhoto, setFavoritePhoto] = useState(null);
    const [allPhotos, setAllPhotos] = useState([]);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploadError, setUploadError] = useState("");

    const mainPhotoInputRef = useRef(null);
    const springPhotoInputRef = useRef(null);
    const winterPhotoInputRef = useRef(null);
    const childhoodPhotoInputRef = useRef(null);
    const differentAgesPhotoInputRef = useRef(null);
    const favoritePhotoInputRef = useRef(null);

    const donorId = JSON.parse(localStorage.getItem("donorId"));

    const handlePhotoChange = (event, setPhoto, photoType) => {
        const file = event.target.files[0];
        setPhoto(file);
        setAllPhotos(prevPhotos => {
            const updatedPhotos = prevPhotos.filter(photo => photo.type !== photoType);
            return [...updatedPhotos, { type: photoType, file }];
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('files', mainPhoto);
        formData.append('files', springPhoto);
        formData.append('files', winterPhoto);
        formData.append('files', childhoodPhoto);
        formData.append('files', differentAgesPhoto);
        formData.append('files', favoritePhoto);
        formData.append('price', "0");
        formData.append('isHidden', false);
        formData.append('isAvatar', false);
        formData.append('album', "public");
        formData.append('userId', donorId);

        
        if(allPhotos.length === 0) {
            setUploadError("Please upload at least one photo");
        }else {
            setLoading(true)
            setUploadError("");
            try {
                dispatch(
                    uploadDonorPhotosRequest(formData, (response, error) => {
                    if (response) {
                        setUploadSuccess(true)
                    } else {
                        console.log("Error", error);
                    }
                }))
            } catch (error) {
                console.error('Error uploading avatar', error);
            }
        }
    };

    const removePhoto = (photo) => {
        setAllPhotos(allPhotos.filter(item => item.file !== photo));

        if (photo === mainPhoto) {
            setMainPhoto(null);
            mainPhotoInputRef.current.value = null;
        }
        if (photo === springPhoto) {
            setSpringPhoto(null);
            springPhotoInputRef.current.value = null;
        }
        if (photo === winterPhoto) {
            setWinterPhoto(null);
            winterPhotoInputRef.current.value = null;
        }
        if (photo === childhoodPhoto) {
            setChildhoodPhoto(null);
            childhoodPhotoInputRef.current.value = null;
        }
        if (photo === differentAgesPhoto) {
            setDifferentAgesPhoto(null);
            differentAgesPhotoInputRef.current.value = null;
        }
        if (photo === favoritePhoto) {
            setFavoritePhoto(null);
            favoritePhotoInputRef.current.value = null;
        }
    }

    const getFileType = (type) => {
        switch (type) {
            case "mainPhoto":
                return 'Main photo'

            case "springPhoto":
                return "Spring photo"

            case "winterPhoto":
                return "Winter photo"

            case "childhoodPhoto":
                return "Photo from childhood"

            case "differentAgesPhoto":
                return "Different ages"

            case "favoritePhoto":
                return "Favorite photo"
        
            default:
                return null
        }
    }

    return (
        <>
            {
                uploadSuccess ? (
                    <div className={classes['success-photo-upload']}>
                        <img src="/cdn/images/verified-amico.svg" alt="" />
                        <h2>Registration Successful!</h2>
                        <p>Thank you for registering. We have received your details and our team will contact you shortly.</p>
                    </div>
                ) : (
                    <form className={classes['upload-photo-form']} onSubmit={handleSubmit}>
                        {
                            uploadError && (
                                <p className={classes['error-message']}>{uploadError}</p>
                            )
                        }
                        <div className={classes['input-upload']}>
                            <label htmlFor="mainPhoto" className={classNames(classes["input-file"], loading && classes['disabled'])}>
                                <p className={classes['input-file-label']}>Add your main photo to the page:</p>
                                <span className={classes["input-file-text"]} type="text">{mainPhoto?.name ? mainPhoto.name : 'Main photo'}</span>
                                <input
                                    type="file"
                                    id="mainPhoto"
                                    accept="image/*"
                                    ref={mainPhotoInputRef}
                                    onChange={(event) => handlePhotoChange(event, setMainPhoto, 'mainPhoto')}
                                />
                                <span className={classes["input-file-btn"]}>Browse</span>
                            </label>
                        </div>
                        <div className={classes['input-upload']}>
                            <label htmlFor="springPhoto" className={classNames(classes["input-file"], loading && classes['disabled'])}>
                                <p className={classes['input-file-label']}>Add your photo in Spring:</p>
                                <span className={classes["input-file-text"]} type="text">{springPhoto?.name ? springPhoto.name : 'Spring photo'}</span>
                                <input
                                    type="file"
                                    id="springPhoto"
                                    accept="image/*"
                                    ref={springPhotoInputRef}
                                    onChange={(event) => handlePhotoChange(event, setSpringPhoto, 'springPhoto')}
                                />
                                <span className={classes["input-file-btn"]}>Browse</span>
                            </label>
                        </div>
                        <div className={classes['input-upload']}>
                            <label htmlFor="winterPhoto" className={classNames(classes["input-file"], loading && classes['disabled'])}>
                                <p className={classes['input-file-label']}>Add your photo in Winter:</p>
                                <span className={classes["input-file-text"]} type="text">{winterPhoto?.name ? winterPhoto.name : 'Winter photo'}</span>
                                <input
                                    type="file"
                                    id="winterPhoto"
                                    accept="image/*"
                                    ref={winterPhotoInputRef}
                                    onChange={(event) => handlePhotoChange(event, setWinterPhoto, 'winterPhoto')}
                                />
                                <span className={classes["input-file-btn"]}>Browse</span>
                            </label>
                        </div>
                        <div className={classes['input-upload']}>
                            <label htmlFor="childhoodPhoto" className={classNames(classes["input-file"], loading && classes['disabled'])}>
                                <p className={classes['input-file-label']}>Add your photo from childhood:</p>
                                <span className={classes["input-file-text"]} type="text">{childhoodPhoto?.name ? childhoodPhoto.name : 'Photo from childhood'}</span>
                                <input
                                    type="file"
                                    id="childhoodPhoto"
                                    accept="image/*"
                                    ref={childhoodPhotoInputRef}
                                    onChange={(event) => handlePhotoChange(event, setChildhoodPhoto, 'childhoodPhoto')}
                                />
                                <span className={classes["input-file-btn"]}>Browse</span>
                            </label>
                        </div>
                        <div className={classes['input-upload']}>
                            <label htmlFor="differentAgesPhoto" className={classNames(classes["input-file"], loading && classes['disabled'])}>
                                <p className={classes['input-file-label']}>Add your photo in different ages:</p>
                                <span className={classes["input-file-text"]} type="text">{differentAgesPhoto?.name ? differentAgesPhoto.name : 'Photo in different ages'}</span>
                                <input
                                    type="file"
                                    id="differentAgesPhoto"
                                    accept="image/*"
                                    ref={differentAgesPhotoInputRef}
                                    onChange={(event) => handlePhotoChange(event, setDifferentAgesPhoto, 'differentAgesPhoto')}
                                />
                                <span className={classes["input-file-btn"]}>Browse</span>
                            </label>
                        </div>
                        <div className={classes['input-upload']}>
                            <label htmlFor="favoritePhoto" className={classNames(classes["input-file"], loading && classes['disabled'])}>
                                <p className={classes['input-file-label']}>Add your favorite photo:</p>
                                <span className={classes["input-file-text"]} type="text">{favoritePhoto?.name ? favoritePhoto.name : 'Favorite photo'}</span>
                                <input
                                    type="file"
                                    id="favoritePhoto"
                                    accept="image/*"
                                    ref={favoritePhotoInputRef}
                                    onChange={(event) => handlePhotoChange(event, setFavoritePhoto, 'favoritePhoto')}
                                />
                                <span className={classes["input-file-btn"]}>Browse</span>
                            </label>
                        </div>
                        {
                            !isMobile && (
                                <div className={classNames(classes['photos-block'], loading && classes['disabled'])}>
                                    {
                                        allPhotos && allPhotos.length > 0 && allPhotos.map(item => (
                                            <div className={classes["input-file-list"]}>
                                                <div className={classes['title']}>{getFileType(item.type)}</div>
                                                <span className={classes['remove']} onClick={() => removePhoto(item.file)}>
                                                    <Icon id="icon-close" className="icon-close" />
                                                </span>
                                                <img src={URL.createObjectURL(item.file)} alt="" />
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                        <Button type="submit" addClasses={["button"]} loading={loading}>
                            Complete
                        </Button>
                    </form>
                )
            }
        </>
    );
};

export default UploadPhoto;