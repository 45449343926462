import React from 'react';
import PageWrap from '../../../app/components/PageWrap';

import classes from '../style/singleBlogPage.module.scss'
import classNames from 'classnames';
import Icon from '../../../ui/Icon';
import BlogAside from './BlogAside';

const Post2 = () => {
    return (
        <PageWrap>
            <section className={classes["blog"]}>
                <div className={classNames(classes["container"], "container")}>
                    <div className={classes["blog_news_block"]}>
                        <div className={classes["blog_news"]}>
                            <div className={classes["blog_news_image"]}>
                                <div>
                                    <img src="/cdn/images/news_image_2.jpg" alt="" />
                                </div>
                            </div>
                            <div className={classes["blog_news_info"]}>
                                <div className={classes["blog_news_text"]}>
                                    <h1>
                                        What Will the Baby Look Like When Using Donor Oocytes?
                                    </h1>
                                    <div className={classes["blog_news_author_info"]}>
                                        <div className={classes["blog_news_author_avatar"]}>
                                            <img src="/cdn/images/denys.jpg" alt="avatar" />
                                        </div>
                                        <span className={classes["by"]}>
                                            by
                                        </span>
                                        <span className={classes["blog_news_author_name"]}>
                                            Denys Manager
                                        </span>
                                        <div className={classes["blog_news_author_separator"]} />
                                        <span className={classes["date"]}>
                                            15.05.2024
                                        </span>
                                    </div>
                                </div>
                                <div className={classes["blog_news_categories"]}>
                                    <div className={classNames(classes["blog_card_footer_category_pill"], classes["blog_category_pill_cat_1"])}>Egg Donor</div>
                                </div>
                                <div className={classes["blog_news_post"]}>
                                    <p>
                                        Any baby gets half of its genes from its mother and half from its father. Therefore, if you use donor eggs, your baby will probably look very much like his or her father.
                                    </p>
                                    <h2>The Basics of Genetic Contribution</h2>
                                    <p>
                                        When a baby is conceived naturally, it receives half of its genetic material from the mother’s egg and half from the father’s sperm. These combined genes determine the baby's physical appearance, health, and even aspects of their personality.
                                    </p>
                                    <h2>Using Donor Oocytes</h2>
                                    <p>When using donor oocytes (eggs), the genetic contribution from the mother is replaced by that of the egg donor. As a result, the baby will inherit half of its genes from the egg donor and the other half from the father.</p>
                                    <h2>Appearance and Genetics</h2>
                                    <p>The physical appearance of the baby will be influenced by the combination of genes from the donor and the father. Since half of the baby’s genetic material comes from the donor, the baby may exhibit physical traits of the donor. This can include aspects like eye color, hair color, skin tone, and facial features. The other half, inherited from the father, will also contribute to these traits.</p>
                                    <h2>Choosing an Egg Donor</h2>
                                    <p>When choosing an egg donor, prospective parents often look for someone who has physical characteristics similar to the intended mother. This can help ensure that the child might share some physical resemblance with the mother, even though the genetic material is not hers.</p>
                                    <p>
                                        In addition to physical traits, genetic factors also influence aspects of personality and health. Therefore, the health history and genetic background of the donor are carefully considered to increase the chances of a healthy child with desirable characteristics.
                                    </p>
                                    <h2>Epigenetic Influences</h2>
                                    <p>While the egg donor provides half of the baby’s genetic material, the environment in the womb also plays a role in the baby’s development. This is where epigenetics comes into play. Epigenetics involves changes in gene expression caused by factors other than changes in the DNA sequence itself. The uterine environment provided by the gestational mother can influence which genes are turned on or off in the developing baby. Factors such as the gestational mother’s diet, health, stress levels, and overall environment can have lasting effects on the child's development.</p>
                                    <h2>Conclusion</h2>
                                    <p>In summary, a baby conceived using donor oocytes will inherit half of its genetic material from the egg donor and the other half from the father. The baby's physical appearance will reflect this combination of genes. By choosing a donor with physical and genetic traits similar to the intended mother, parents can increase the chances of the child resembling both parents. Additionally, the gestational environment and epigenetic factors provided by the mother carrying the baby can also influence the child's development.</p>
                                    <p>Understanding the genetic and epigenetic influences on a baby's appearance and development can help prospective parents make informed decisions when considering the use of donor oocytes.</p>
                                </div>
                                {/* <div className={classes["blog_news_horizontal_separator"]} />
                                <div className={classes["blog_news_footer"]}>
                                    <div>
                                        <div className={classes["image"]}>
                                            <Icon id="icon-eye" className="icon-eye" />
                                        </div>
                                        <span>
                                            19.1K
                                        </span>
                                    </div>
                                    <div className={classes["image"]}>
                                        <Icon id="icon-share" className="icon-share" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <BlogAside />
                    </div>
                </div>
            </section>
        </PageWrap>
    );
}

export default Post2;
