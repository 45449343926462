import React, { useEffect, useState } from "react";
import Fancybox from "../../../components/Fancybox/Fancybox";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import classes from "../style/photosSection.module.scss";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getDonorPhotosRequest } from "../../../actions/donorActions";
import { fetchPopup } from "../../../actions/modalActions";

const PhotosSection = ({donor}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const user = useSelector((state) => state.user.user);
    const donorPhotos = useSelector((state) => state.donors.donorPhotos);

    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [isLowBalance, setIsLowBalance] = useState(false);

    useEffect(() => {
        if(user && selectedPhoto) {
            setIsLowBalance(user.wallet.amount < selectedPhoto.price)
        }
    }, [user, selectedPhoto]);

    const gotToLogin = () => {
        navigate('/login')
    }

    const buyPhoto = (photo) => {
        if(!userInfo || (user && !user.isLoggedIn)){
            gotToLogin()
        }else {
            setSelectedPhoto(photo);
            dispatch(fetchPopup({
                popupType: 'CoinsModal',
                props: {
                    onBuyPrivatePhoto: onBuyPrivatePhoto,
                    className: "buy-photo",
                    text: isLowBalance
                        ? `This photo is private!<br />You have to top up your balance!<br /><span>Note: The price of this photo (${photo.price} coins) exceeds your balance.</span>`
                        : `This photo is private!<br />The cost is ${photo.price} coins`,
                    buttonText: isLowBalance ? "Top up your balance" : "Buy",
                    price: photo.id,
                    type: "buy-photo"
                },
            }))
        }
    };

    const closeModal = () => {
        setSelectedPhoto(null);
    };

    const onBuyPrivatePhoto = () => {
        closeModal()
        dispatch(getDonorPhotosRequest(donor.id))
    };

    function generateSrc(photo) {
        if (!photo.isBought || (photo.isHidden && (user && !user?.isLoggedIn)) || (photo.isHidden && !userInfo)) {
            return `https://storage.surrogacy-platform.com/images/donors/${photo.donorId}/${photo.id}_hidden.${photo.ext}`;
        } else {
            return `https://storage.surrogacy-platform.com/images/donors/${photo.donorId}/${photo.fileName}`;
        }
    }

    return (
        <section className={classes["photos"]}>
            <Fancybox className={classes["images"]}>
                {donorPhotos && donorPhotos[donor.id] &&
                    donorPhotos[donor.id].map((photo, index) => (
                        <div key={index} className={classes["image"]}>
                            <div className={classes["image_wrapper"]}>
                                <a
                                    data-fancybox="gallery"
                                    href={generateSrc(photo)}
                                    className={classes["image"]}
                                >
                                    <img src={generateSrc(photo)} alt="" />
                                </a>
                                {
                                    photo.isHidden && !userInfo ? (
                                        <div className={classes["photos_cover_login"]} onClick={gotToLogin}>
                                            <span>Login</span>
                                        </div>
                                    ) : (
                                        <>
                                            {photo.price > 0 && photo.album == "private" && !photo.isBought && (
                                                <div
                                                    className={classes["photos_cover_privat"]}
                                                    onClick={() => buyPhoto(photo)}
                                                >
                                                    <span>Private</span>
                                                </div>
                                            )}
                                            {photo.isHidden && !user.isLoggedIn && (
                                                <div className={classes["photos_cover_login"]} onClick={gotToLogin}>
                                                    <span>Login</span>
                                                </div>
                                            )}
                                        </>
                                        
                                    )
                                }
                            </div>
                        </div>
                    ))}
            </Fancybox>
        </section>
    );
};

export default PhotosSection;
