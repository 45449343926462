import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNewDonorsRequest } from "../../../actions/donorActions";

import LadyCard from "../../../components/LadyCard/LadyCard";

import classes from "../style/newLadiesSection.module.scss";

const NewLadiesSection = () => {
    const dispatch = useDispatch();
    const donors = useSelector((state) => state.donors.newDonorsList?.donors);

    useEffect(() => {
        dispatch(fetchNewDonorsRequest(1, 4));
    }, [dispatch]);

    if(!donors) {
        return null
    }

    return (
        <section className={classes["new_ladies"]}>
            <div className="container">
                <h2>New Egg Donors</h2>
                <div className={classes["new_ladies_cards"]}>
                    {donors
                        ? donors.map((item) =>
                            item.userInfo &&
                                item.userInfo.label &&
                                item.userInfo.label.includes("new") ? (
                                <LadyCard
                                    key={item.id}
                                    lady={item}
                                    className="new_ladies_section"
                                />
                            ) : null
                        )
                        : null}
                </div>
            </div>
        </section>
    );
};

export default NewLadiesSection;
