import React from 'react';
import { Link } from 'react-router-dom';
import classes from "./style/footer.module.scss";

const index = () => {
    return (
        <footer>
            <div className="container">
                <Link to="/" className="logo">
                    <img src="/cdn/images/logo-big.svg" alt="" />
                </Link>
                <div className={classes["links"]}>
                    <div className={classes["column"]}>
                        <h5>Company Information</h5>
                        <ul>
                            <li>
                                <Link to="/about-us">About Us</Link>
                            </li>
                            <li>
                                <Link to="/contact-us">Contacts</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="/terms-and-conditions">Terms and Conditions</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={classes["column"]}>
                        <h5>List of</h5>
                        <ul>
                            <li>
                                <Link to="/donors">Egg Donors DataBase</Link>
                            </li>
                            <li>
                                <Link to="/donors">Surrogate mothers DataBase</Link>
                            </li>
                            <li>
                                <Link to="/favourite-donors">My Favorite</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={classes["column"]}>
                        <h5>Services</h5>
                        <ul>
                            <li>
                                <Link to="/coins">Add Coins</Link>
                            </li>
                            <li>
                                <Link to="/legislation">Service Legislation</Link>
                            </li>
                            <li>
                                <Link to="/program-stages">Program Stages</Link>
                            </li>
                            <li>
                                <Link to="/packages">All programs</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={classes["column"]}>
                        <h5>Support</h5>
                        <ul>
                            <li>
                                <Link to="/quick-start">Quick Start</Link>
                            </li>
                            <li>
                                <Link to="/surrogate-mother-request">Become a Surrogate Mother</Link>
                            </li>
                            <li>
                                <Link to="/egg-donor-request">Become an Egg Donor</Link>
                            </li>
                            <li>
                                <Link to="/sperm-donor-request">Become a Sperm Donor</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={classes["column"]}>
                        <h5>Trips</h5>
                        <ul>
                            <li>
                                <Link to="/trips-to-clinic">Trip to the clinic</Link>
                            </li>
                            <li>
                                <Link to="/egg-donor-trips">Egg Donor Trip to clinic</Link>
                            </li>
                            <li>
                                <Link to="/surrogate-trips">Surrogate Trip to clinic</Link>
                            </li>
                            <li>
                                <Link to="/trip-to-childbirth">Trip to childbirth</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes["copyright"]}>
                    <span>
                        COPYRIGHT © 2024
                    </span>
                    <span>
                        Surrogacy Platform
                    </span>
                </div>
            </div>
        </footer>
    );
}

export default index;
