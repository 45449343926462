import React from 'react';
import { Link } from 'react-router-dom';

import classes from '../style/blogItem.module.scss'
import classNames from 'classnames';

const BlogItem = ({blogItem}) => {
    return (
        <Link to={blogItem.link} className={classes["blog_card"]}>
            <div className={classes["blog_card_image"]}>
                <img src={blogItem.imageUrl} alt="" />
            </div>
            <div className={classes["blog_card_description"]}>
                <span className={classes['date']}>
                    {blogItem.date}
                </span>
                <h3>
                    {blogItem.title}
                </h3>
                <p>
                    {blogItem.shortDeescription}
                </p>
                <div className={classes["blog_card_footer"]}>
                    <div className={classes["blog_card_footer_categories"]}>
                    {blogItem.categories.map((category, catIndex) => (
                        <div
                        className={classNames(classes['blog_card_footer_category_pill'], classes[`blog_category_pill_cat_1`])}
                        key={catIndex} >
                            {category}
                        </div>
                    ))}
                    </div>
                    <span className={classes['read-more']}>
                        Read About
                    </span>
                </div>
            </div>
        </Link>
    );
}

export default BlogItem;
