import React from 'react';

import classes from '../style/blogAside.module.scss'
import Icon from '../../../ui/Icon';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const BlogAside = () => {
    const recentPostsData = [
        {
            imageSrc: '/cdn/images/news_image_1.jpg',
            description: 'Are Genes Passed on from the Surrogate Mother to the Child?',
            date: '12.04.2024',
            link: '/blog/are-genes-passed-on-from-the-surrogate-mother-to-the-child'
        },
        {
            imageSrc: '/cdn/images/news_image_2.jpg',
            description: 'What Will the Baby Look Like When Using Donor Oocytes?',
            date: '15.05.2024',
            link: '/blog/what-will-the-baby-look-like-when-using-donor-oocytes'
        },
        {
            imageSrc: '/cdn/images/news_image_3.jpg',
            description: 'Surrogacy and Egg Donation: How to Choose a Donor and Organize a Surrogacy Program',
            date: '23.06.2024',
            link: '/blog/surrogacy-and-egg-donation'
        },
    ];
    return (
        <div className={classes["blog_news_aside"]}>
            {/* <div className={classes["blog_news_search"]}>
                <Icon id="icon-search" className="icon-search" />
                <input type="text" placeholder="Search" />
            </div> */}
            <div className={classes["recent_posts"]}>
                <h4>
                    Recent Posts
                </h4>
                {recentPostsData && recentPostsData.map((post, index) => (
                    <Link to={post.link} className={classes["recent_post"]} key={index}>
                        <div className={classes["recent_post_image"]}>
                            <img src={post.imageSrc} alt="recent_post_image" />
                        </div>
                        <div className={classes["recent_post_description"]}>
                            <p>{post.description}</p>
                            <span>{post.date}</span>
                        </div>
                    </Link>
                ))}
            </div>
            <div className={classes["blog_news_categories_aside"]}>
                <h4>
                    Categories
                </h4>
                <div className={classes["blog_news_categories_pills"]}>
                    <div className={classNames(classes["blog_category_pill"], classes["blog_category_pill_cat_1"])}>
                        Egg Donor
                    </div>
                    {/* <div className={classNames(classes["blog_category_pill"], classes["blog_category_pill_cat_2"])}>
                        Category 2
                    </div>
                    <div className={classNames(classes["blog_category_pill"], classes["blog_category_pill_cat_3"])}>
                        Category 3
                    </div>
                    <div className={classNames(classes["blog_category_pill"], classes["blog_category_pill_cat_4"])}>
                        Category 4
                    </div>
                    <div className={classNames(classes["blog_category_pill"], classes["blog_category_pill_cat_5"])}>
                        Category 5
                    </div>
                    <div className={classNames(classes["blog_category_pill"], classes["blog_category_pill_cat_6"])}>
                        Category 6
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default BlogAside;
