import React from 'react';

import classesMain from '../style/aboutUsMainStyles.module.scss'
import classes from '../style/topSectionStyle.module.scss'
import classNames from 'classnames';
import Icon from '../../../../../ui/Icon';

const TopSection = () => {
    return (
        <section className={classes['about-top-section']}>
            <div className={classNames(classesMain['about-us-container'], 'container')}>
                <div className={classes['welcome-block']}>
                    <div className={classes['welcome-text']}>
                        <h1>Welcome to the Surrogacy Platform!</h1>
                        <h2>Who we are</h2>
                        <p>Surrogacy Platform is Egg Donor search and matching service and implementation of full Surrogacy Programs in Cyprus</p>
                        <p>Surrogacy Platform prides itself on being an innovative service in the field of egg donation and surrogacy.</p>
                    </div>
                    <div className={classes['welcome-banner']}>
                        <img src="/cdn/images/about-us/about-us-banner.png" alt="" />
                    </div>
                </div>
                <div className={classes['created-block']}>
                    <div className={classes['title-block']}>
                        <h2>Who is we created<br />the Surrogacy Platform for?</h2>
                        <p>For client want choose the best egg donor and opportunity be able to communicate before the surrogacy program.</p>
                    </div>
                    <div className={classes['created-info']}>
                        <div className={classes['item']}>
                            <Icon id="icon-ok-round" />
                            <h4>Surrogacy Platform - a unique service for finding egg donors and surrogacy services</h4>
                            <p>Our uniqueness lies in our Open Database: We are the first to provide Full Free Access to the catalog of egg donors! Browse Open profiles with full characteristics and real photos. Now you have the opportunity to find out Everything about an egg donor. And we also provide a turnkey surrogacy service.</p>
                        </div>
                        <div className={classes['item']}>
                            <Icon id="icon-ok-round" />
                            <h4>Surrogacy Platform protects your confidentiality</h4>
                            <p>We respect and value your anonymity. If you wish, you can use our service completely anonymously. You do not have to fill in your real data when registering. You can email donors or chat under your Nickname, and you don't have to turn on your camera in video chat.<br />
                            Surrogacy Platform is primarily designed to make you comfortable finding donors and surrogate mums.</p>
                        </div>
                        <div className={classes['item']}>
                            <Icon id="icon-ok-round" />
                            <h4>Connecting with egg donors and surrogates</h4>
                            <p>Surrogacy Platform is more than a service, it is a platform for connection. Direct communication with egg donors and surrogates is the key to a quality match. Communicate via email or online chat, and if you want a donor or surrogate to smile and wave at you, just go video chat right on our platform.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TopSection;
