import React, { useEffect } from 'react'
import useWebRTC from '../../../hooks/useWebRTC';
import { useParams } from 'react-router-dom';

export default function VideoComponent() {
    const { roomId } = useParams();
    const { donorVideoRef } = useWebRTC(roomId);

    return (
        <video
            width="100%"
            height="100%"
            ref={donorVideoRef}
            autoPlay={true} playsInline={true} muted={true}
        />
    )
}
