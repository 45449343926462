import React from 'react';

import classesMain from '../style/aboutUsMainStyles.module.scss'
import classes from '../style/supportSectionStyle.module.scss'
import classNames from 'classnames';

const SupportSection = () => {
    return (
        <section className={classes['support-section']}>
            <div className={classNames(classesMain['about-us-container'], 'container')}>
                <div className={classes['support-block']}>
                    <div className={classes['text-block']}>
                        <h2>Professional interpreters - multilingual support</h2>
                        <p>Communication should never be hampered by language barriers. Surrogacy Platform offers multilingual support with professional interpreters. Ensure clear communication and understanding throughout your journey.</p>
                    </div>
                    <img src="/cdn/images/about-us/about-us-support-2.png" alt="Support" />
                </div>
            </div>
        </section>
    );
}

export default SupportSection;
