import React from 'react';
import classes from './style/notFoundPage.module.scss';
import LinkButton from '../../ui/Button/LinkButton';

const NotFoundPage = () => {
    return (
        <section className={classes["not-found"]}>
            <div className={classes['content']}>
                <img src="/cdn/images/404/not-found.png" alt="404 image" />
                <p>Opps... We can not find this page </p>
                <LinkButton className={classes['not-found-btn']} to="/" addClasses={['button', 'capitalize']}>Back to Home</LinkButton>
            </div>
        </section>
    );
}

export default NotFoundPage;
