import React, { useState } from 'react';
import classNames from 'classnames';
import classes from './style/rangeSlider.module.scss';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';

const RangeSlider = ({onRangeChange}) => {
    const [range, setRange] = useState([18, 35]);

    const handleRangeChange = (newRange) => {
        setRange(newRange);
        onRangeChange(newRange);
    };
    
  
    return (
      <div className={classNames(classes["search_filter_card_block"], classes["range"])}>
        <span>Age</span>
        <div className={classes["value-display"]} style={{ left: `${(range[0] - 18) / (35 - 18) * 100}%` }}>
            <p>{range[0]}</p>
        </div>
        <Slider
            className="range-slider"
            min={18}
            max={35}
            range
            value={range}
            onChange={handleRangeChange}
        />
        <div className={classes["value-display"]} style={{ left: `${(range[1] - 18) / (35 - 18) * 100}%` }}>
            <p>{range[1]}</p>
        </div>
      </div>
    );
  };
  
  export default RangeSlider;