import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest } from '../../../actions/authActions';
import Button from '../../../ui/Button';
import { useForm } from "react-hook-form";
import classes from "../style/formAuth.module.scss";
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Icon from '../../../ui/Icon';
import Input from '../../../ui/Input';

const LogIn = ({showFormType}) => {
    const dispatch = useDispatch();
    const errorMessage = useSelector(state => state.auth.error);

    const handleClick = (type) => {
        showFormType(type)
    }

    const {
		register,
		handleSubmit,
		formState: { errors }
    } = useForm();
    
    const onSubmit = async (data) => {
        try {
            const loginData = {
                ...data,
                type: 'user'
            }
            await dispatch(loginRequest({
                ...loginData,
                onSuccessRedirect: (url) => window.location.href = url,
            }));
        } catch (error){
            console.log('error', error);
        }
    };
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Input
                type="email"
                name="email"
                useFormEl={register('email')}
                placeholderNormal="E-mail"
                withError={errors.email && true}
                errorText={errors?.email?.type == 'email' ? 'Invalid Email format' : 'Please, enter your Email'}
                placeHolder="Your Email"
            />
            <Input
                type="password"
                name="password"
                useFormEl={register('password')}
                placeholderNormal="Password"
                addProps={{ autoComplete: 'off' }}
                withError={errors.password && true}
                errorText="Please, enter password"
                placeHolder="Password"
            />
            {
                errorMessage && (
                    <div className={classes["error-block"]}>
                        <Icon id="icon-alert" />
                        <p>{errorMessage.message}</p>
                    </div>

                )
            }
            <div className={classes["forgot_password"]} onClick={() => handleClick('forgot')}>
                Forgot password?
            </div>
            <Button type="submit" addClasses={["button"]} onClick={handleSubmit(onSubmit)}>
                log in
            </Button>
        </form>
    );
}

export default LogIn;
