import React from 'react';

import classes from '../style/generalChars.module.scss'

const GeneralCharsMobile = ({generalCharsMobile}) => {
    return (
        <div className={classes["compare_2_general_characteristics_mobile"]}>
            <h2>
                General Characteristics
            </h2>
            <ul>
                {
                    generalCharsMobile && generalCharsMobile.map(item => (
                        <li key={item.label}>
                            <span>
                                {item.label}
                            </span>
                            <span>
                                {item.value0}
                            </span>
                            <span>
                                {item.value1}
                            </span>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

export default GeneralCharsMobile;
