import React from 'react';
import PageWrap from '../../../app/components/PageWrap';

import classes from '../style/singleBlogPage.module.scss'
import classNames from 'classnames';
import Icon from '../../../ui/Icon';
import BlogAside from './BlogAside';

const Post1 = () => {
    return (
        <PageWrap>
            <section className={classes["blog"]}>
                <div className={classNames(classes["container"], "container")}>
                    <div className={classes["blog_news_block"]}>
                        <div className={classes["blog_news"]}>
                            <div className={classes["blog_news_image"]}>
                                <div>
                                    <img src="/cdn/images/news_image_1.jpg" alt="" />
                                </div>
                            </div>
                            <div className={classes["blog_news_info"]}>
                                <div className={classes["blog_news_text"]}>
                                    <h1>
                                        Are Genes Passed on from the Surrogate Mother to the Child?
                                    </h1>
                                    <div className={classes["blog_news_author_info"]}>
                                        <div className={classes["blog_news_author_avatar"]}>
                                            <img src="/cdn/images/denys.jpg" alt="avatar" />
                                        </div>
                                        <span className={classes["by"]}>
                                            by
                                        </span>
                                        <span className={classes["blog_news_author_name"]}>
                                            Denys Manager
                                        </span>
                                        <div className={classes["blog_news_author_separator"]} />
                                        <span className={classes["date"]}>
                                            12.04.2024
                                        </span>
                                    </div>
                                </div>
                                <div className={classes["blog_news_categories"]}>
                                    <div className={classNames(classes["blog_card_footer_category_pill"], classes["blog_category_pill_cat_1"])}>Egg Donor</div>
                                </div>
                                <div className={classes["blog_news_post"]}>
                                    <p>
                                        Wisdom says: "It is not the mother who gave birth, but the mother who brought up." It seems that humanity has decided to follow this folk wisdom, leading to the invention of surrogacy.
                                    </p>
                                    <h2>Understanding Surrogacy</h2>
                                    <p>
                                        Surrogacy is a method of assisted reproduction where a woman, known as a surrogate mother, agrees to carry and give birth to a child for another person or couple. There are two main types of surrogacy:
                                    </p>
                                    <ol>
                                        <li><span className='text-strong'>Traditional Surrogacy</span>: In this case, the surrogate mother is also the biological mother of the child. Her own egg is fertilized by the intended father's sperm or a donor's sperm.</li>
                                        <li><span className='text-strong'>Gestational Surrogacy</span>: Here, the surrogate mother has no genetic link to the child. An embryo created using the egg and sperm of the intended parents or donors is implanted in her uterus.</li>
                                    </ol>
                                    <h2>Genetic Contribution in Surrogacy</h2>
                                    <p>The question of whether genes are passed on from the surrogate mother to the child depends on the type of surrogacy.</p>
                                    <h3>Traditional Surrogacy</h3>
                                    <p>In traditional surrogacy, the surrogate mother's genetic material is involved. Since her egg is used, she contributes half of the child's genetic makeup. Therefore, genes are indeed passed from the surrogate mother to the child in this scenario.</p>
                                    <h3>Gestational Surrogacy</h3>
                                    <p>In gestational surrogacy, the surrogate mother does not contribute any genetic material to the child. The embryo implanted in her uterus is created from the egg and sperm of the intended parents or donors. Consequently, the surrogate mother does not pass on any genes to the child.</p>
                                    <h2>The Role of Epigenetics</h2>
                                    <p>While the surrogate mother in gestational surrogacy does not contribute genetically, there is still a biological connection through the uterine environment. This connection can have an impact on the child's development through epigenetic factors.</p>
                                    <p>Epigenetics refers to changes in gene expression that do not involve alterations to the underlying DNA sequence. The uterine environment, including factors like the surrogate's diet, health, stress levels, and overall well-being, can influence how certain genes in the embryo are expressed. Although these changes do not alter the genetic code, they can have lasting effects on the child's development and health.</p>
                                    <h2>Conclusion</h2>
                                    <p>In summary, whether genes are passed on from the surrogate mother to the child depends on the type of surrogacy. In traditional surrogacy, the surrogate mother is the biological mother and does pass on her genes. In gestational surrogacy, the surrogate mother does not pass on her genes, as she is not genetically related to the child. However, the surrogate's uterine environment can influence the child's development through epigenetic mechanisms.</p>
                                    <p>Surrogacy, therefore, intertwines biological, genetic, and epigenetic factors, creating a unique and complex pathway to parenthood. It underscores the profound wisdom that being a mother is not solely about genetics, but also about the nurturing and love provided during and after pregnancy.</p>
                                </div>
                                {/* <div className={classes["blog_news_horizontal_separator"]} />
                                <div className={classes["blog_news_footer"]}>
                                    <div>
                                        <div className={classes["image"]}>
                                            <Icon id="icon-eye" className="icon-eye" />
                                        </div>
                                        <span>
                                            19.1K
                                        </span>
                                    </div>
                                    <div className={classes["image"]}>
                                        <Icon id="icon-share" className="icon-share" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <BlogAside />
                    </div>
                </div>
            </section>
        </PageWrap>
    );
}

export default Post1;
